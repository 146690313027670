import { Route, Routes } from "react-router-dom";
import Header from "../components/Header/Header";
import Profile from "../pages/SharedComponents/Profile";
import Users from "../pages/SharedComponents/Users";
import Clients from "../pages/SuperAdmin/Clients";
import Controllers from "../pages/SuperAdmin/Controllers";
import DeviceType from "../pages/SuperAdmin/DeviceType";
import LightControl from "../pages/SuperAdmin/LightControl";
import RecoveryCheck from "../pages/SuperAdmin/RecoveryCheck";
import Sensors from "../pages/SuperAdmin/Sensors";
import SuperAdminDashboard from "../pages/SuperAdmin/SuperAdminDashboard";

function SuperAdminRoutes() {
  return (
    <>
      <Header />
      <Routes>
        <Route index path="/" element={<SuperAdminDashboard />} />
        <Route path="/superadmin">
          <Route index element={<SuperAdminDashboard />} />
          <Route index path="clients" element={<Clients />} />
          <Route index path="controllers" element={<Controllers />} />
          <Route index path="device-type" element={<DeviceType />} />
          <Route index path="light-control" element={<LightControl />} />
          <Route index path="manage-users" element={<Users />} />
          <Route
            index
            path="manage-users/:role/:clientID/:adminID?/:workingAs?"
            element={<Users />}
          />
          <Route index path="profile" element={<Profile />} />
          <Route index path="recovery-check" element={<RecoveryCheck />} />
          <Route index path="sensors" element={<Sensors />} />
        </Route>
      </Routes>
    </>
  );
}

export default SuperAdminRoutes;
