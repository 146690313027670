import apiClient from "./api";

export const loginUser = async (body: any) => {
  return apiClient("post", `/login`, null, body, "json", true);
};

export const forgotPasswordSendEmail = async (body: any) => {
  return apiClient("post", `/send_reset_email`, null, body, "json", true);
};

export const forgotPasswordVerifyOtp = async (body: any) => {
  return apiClient("post", `/verifyotp`, null, body, "json", true);
};

export const forgotPasswordResetPassword = async (body: any) => {
  return apiClient("post", `/resetpassword`, null, body, "json", true);
};
