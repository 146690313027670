import { Tag } from "antd";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import commonStyles from "../../assets/styles/commonStyles.module.scss";
import styles from "./FormFields.module.scss";

interface TagInputProps {
  disabled?: boolean;
  infoText?: string;
  label: React.ReactNode;
  max?: number;
  placeholder?: string;
  tagValidation?: { [key: string]: { pattern?: RegExp; message: string } };
  values?: string[];
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onChangeTags?: (tags: string[]) => void;
  onError?: (error: string | null) => void;
}

const TagInput: React.FC<TagInputProps> = ({
  disabled,
  infoText,
  label,
  max,
  placeholder,
  tagValidation,
  values,
  onChange,
  onChangeTags,
  onError,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [tags, setTags] = useState<string[]>(values || []);
  const [alreadyExists, setAlreadyExists] = useState(false);
  const validateTags = (value: string) => {
    if (tagValidation?.["regExp"] && tagValidation["regExp"].pattern) {
      const regularExpression = new RegExp(tagValidation["regExp"].pattern);
      if (value?.trim() && !regularExpression.test(value.trim())) {
        return tagValidation["regExp"].message;
      }
    }
    return null;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    onChange?.(e);
    if (onError && tagValidation) {
      onError(validateTags(e.target.value));
    }
  };

  const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      addTag();
    }
  };

  const addTag = (onblur = false) => {
    if (inputValue.trim() !== "") {
      const validateResponse = validateTags(inputValue);
      if (tags.includes(inputValue)) {
        setAlreadyExists(true);
      } else if (validateResponse) {
        if (!onblur && onError && tagValidation) onError(validateResponse);
      } else {
        setTags([...tags, inputValue.trim()]);
        setAlreadyExists(false);
        onChangeTags?.([...tags, inputValue.trim()]);
      }
    }
    setInputValue("");
  };

  const removeTag = (tag: string) => {
    const updatedTags = tags.filter((t) => t !== tag);
    setTags(updatedTags);
    onChangeTags?.([...updatedTags]);
  };

  useEffect(() => {
    if (alreadyExists) {
      setTimeout(() => setAlreadyExists(false), 3000);
    }
  }, [alreadyExists]);

  useEffect(() => {
    setTags(values || []);
  }, [values]);

  return (
    <>
      <div
        className={classNames(
          commonStyles.display_flex,
          commonStyles.display_a_center,
          commonStyles.display_flex_wrap,
          commonStyles.gap_2,
          styles.tag_input_wrap
        )}
      >
        {tags?.map((tag, index) => (
          <Tag
            key={`tags-${tag}-${index}`}
            bordered={false}
            color="processing"
            closeIcon={!disabled}
            onClose={() => removeTag(tag)}
          >
            {tag}
          </Tag>
        ))}
        {tags.length !== max && (
          <input
            value={inputValue}
            onBlur={() => {
              onError?.(null);
              addTag(true);
            }}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyPress}
            placeholder={placeholder}
            className="input-field"
            disabled={disabled}
          />
        )}
      </div>
      {infoText && <i className={styles.infoText}>{infoText}</i>}
      {alreadyExists && (
        <p className={styles.existsText}>{`Entered ${label} already added`}</p>
      )}
    </>
  );
};

export default TagInput;
