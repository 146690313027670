import { useState, useEffect } from "react";
import { Card, Spin, Table } from "antd";
import classNames from "classnames";
import { SUPER_ADMIN_RECOVERY_CHECK_COLUMN } from "../../../utils/webTableConfig";
import {
  recoveryEnableDisable,
  fetchAllResourcesList,
} from "../../../api/authorizeApi";
import useApiResponse from "../../../customHooks/useApiResponse";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import styles from "./RecoveryCheck.module.scss";
import { useSnackBar } from "../../../context/SnackBarProvider";

const RecoveryCheck = () => {
  const { apiResponse } = useApiResponse();
  const { snackbar } = useSnackBar();
  const [loading, setLoading] = useState(false);
  const [recoveryList, setrecoveryList] = useState([]);

  const getRecoveryData = async () => {
    setLoading(true);
    const recoveryData = await fetchAllResourcesList("recovery");
    const response = await apiResponse(recoveryData);
    setrecoveryList(response?.result || []);
    setLoading(false);
  };

  const handleTableActions = async (rowData: any, checked?: boolean) => {
    setLoading(true);
    let createUpdateResponse = await recoveryEnableDisable(
      checked ? "enable" : "disable",
      { id: rowData.id }
    );

    const response = await apiResponse(createUpdateResponse);
    setLoading(false);
    if (response) {
      snackbar("success", response.message);
    }
    getRecoveryData();
    setLoading(false);
  };

  useEffect(() => {
    getRecoveryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <Card
        title="Recovery Check"
        className={classNames(commonStyles.card_wrapper, styles.sensors_card)}
      >
        <Spin spinning={loading}>
          <Table
            dataSource={recoveryList}
            columns={SUPER_ADMIN_RECOVERY_CHECK_COLUMN(handleTableActions)}
            pagination={false}
            className={classNames(commonStyles.table_wrap, styles.sensor_table)}
          />
        </Spin>
      </Card>
    </main>
  );
};
export default RecoveryCheck;
