import { Col, Form, Modal, Row } from "antd";
import { Rule } from "antd/es/form";
import classNames from "classnames";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  fetchAllUserBasedData,
  userBasedResourceActions,
} from "../../../api/authorizeApi";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import FormFields from "../../../components/FormFields/FormFields";
import Loader from "../../../components/Loader/Loader";
import StyledButton from "../../../components/StyledButton/StyledButton";
import { useAuthCheck } from "../../../context/AuthCheckProvider";
import { useSnackBar } from "../../../context/SnackBarProvider";
import useApiResponse from "../../../customHooks/useApiResponse";
import {
  arrayToCommaSeperatedString,
  formatDate,
} from "../../../utils/helperFunctions";
import {
  CONDITIONS,
  DAYS,
  DURATION_TIME_LIST,
  REPEAT_TIME_LIST,
} from "../../../utils/webStaticData";
import styles from "./Alerts.module.scss";

interface AddEditAlertProps {
  open: boolean;
  title: string;
  type: string;
  alertDetails: Record<string, any>;
  onCancel: (refresh?: boolean) => void;
}
const AddEditAlert: React.FC<AddEditAlertProps> = ({
  open,
  title,
  type,
  alertDetails,
  onCancel,
}) => {
  const userAuth = useAuthCheck();
  const userID = userAuth?.userDetails?.id;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [locationList, setLocationList] = useState<Record<string, any>[]>([]);
  const [parametersList, setParametersList] = useState<Record<string, any>[]>(
    []
  );
  const { apiResponse } = useApiResponse();
  const { snackbar } = useSnackBar();
  const [emailIds, setEmailIds] = useState(
    alertDetails?.email_ids?.split(",") || []
  );
  const [mobileNumbers, setMobileNumbers] = useState(
    alertDetails?.mobile_numbers?.split(",") || []
  );
  const validation: Record<string, Rule[]> = {
    email_ids: [{ required: true, message: "Please enter the email ID" }],
    event_condition: [{ required: true, message: "Please select condition" }],
    event_name: [{ required: true, message: "Please enter event name" }],
    event_value: [
      { required: true, message: "Please enter value" },
      {
        message: "Please enter number only",
        pattern: new RegExp(/^[0-9]+$/),
      },
    ],
    days: [{ required: true, message: "Please select day" }],
    duration: [{ required: true, message: "Please select duration" }],
    location_id: [{ required: true, message: "Please select location" }],
    time_from: [{ required: true, message: "Please select start time" }],
    time_to: [{ required: true, message: "Please select end time" }],
    parameter_id: [{ required: true, message: "Please select parameter" }],
    repeat_every: [
      { required: true, message: "Please select repeat on duration" },
    ],
    mobile: [
      {
        required: true,
        message: "Please enter mobile number",
      },
    ],
  };

  const getFielsData = async () => {
    const locationsResp = await apiResponse(
      await fetchAllUserBasedData("get_location", null, { user_id: userID })
    );
    const parametersResp = await apiResponse(
      await fetchAllUserBasedData("getAllParameters", null, { userid: userID })
    );
    setLocationList(locationsResp.locations);
    setParametersList(parametersResp.Parameters);
  };

  const handleReset = () => {
    if (alertDetails && Object.keys(alertDetails).length) {
      form.setFieldsValue(setFieldsValues());
    } else {
      form.resetFields();
    }
  };

  const sendAddEditAlert = async () => {
    setLoading(true);
    const values = form.getFieldsValue();
    let payload: Record<string, any> = {
      email_ids: arrayToCommaSeperatedString(emailIds, false),
      event_condition: values.event_condition,
      event_name: values.event_name,
      event_value: values.event_value,
      days: arrayToCommaSeperatedString(values.days, false),
      duration: values.duration,
      location_id: +values.location_id,
      mobile_numbers: arrayToCommaSeperatedString(mobileNumbers, false),
      parameter_id: +values.parameter_id,
      repeat_every: values.repeat_every,
      time_from: formatDate(values.time_from?.$d, "HH:mm"),
      time_to: formatDate(values.time_to?.$d, "HH:mm"),
      user_id: userID,
    };
    if (type === "Edit") {
      payload["id"] = alertDetails.id;
    }

    let createUpdateResponse = await userBasedResourceActions(
      type === "Edit" ? "update_alert" : "add_alert",
      type === "Edit" ? "patch" : "post",
      null,
      payload
    );
    const response = await apiResponse(createUpdateResponse);
    setLoading(false);
    if (response) {
      handleCancel(true);
      snackbar("success", response.message);
    }
  };

  const setFieldsValues = () => {
    let timeFrom, timeTo;

    if (alertDetails?.time_from) {
      timeFrom = dayjs()
        .set("hour", alertDetails?.time_from?.split(":")[0])
        .set("minute", alertDetails?.time_from?.split(":")[1]);
    }
    if (alertDetails.time_to) {
      timeTo = dayjs()
        .set("hour", alertDetails.time_to?.split(":")[0])
        .set("minute", alertDetails.time_to?.split(":")[1]);
    }

    return {
      email_ids: alertDetails?.email_ids?.split(",") || null,
      event_condition: alertDetails?.event_condition || null,
      event_name: alertDetails?.event_name || null,
      event_value: alertDetails?.event_value || null,
      days: alertDetails?.days?.split(",") || [],
      duration: alertDetails?.duration || null,
      location_id: alertDetails?.location_id || null,
      mobile: alertDetails?.mobile_numbers?.split(",") || null,
      parameter_id: 1,
      repeat_every: alertDetails?.repeat_every || null,
      time_from: alertDetails?.time_from ? timeFrom : null,
      time_to: alertDetails?.time_to ? timeTo : null,
    };
  };

  const handleCancel = (refreshList: boolean = false) => {
    onCancel(refreshList);
    form.resetFields();
  };

  useEffect(() => {
    getFielsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      className={classNames(commonStyles.modal_wrapper)}
      footer={false}
      mask
      maskClosable={false}
      open={open}
      onCancel={() => handleCancel()}
      title={<h5>{title}</h5>}
      style={{ top: "1rem" }}
    >
      {loading && <Loader />}
      <Form
        layout="vertical"
        form={form}
        onFinish={() => {
          sendAddEditAlert();
        }}
        initialValues={setFieldsValues()}
      >
        <div className={classNames("modal_form", styles.alert_form)}>
          <FormFields
            name="event_name"
            label="Alert Name"
            placeholder="Alert Name"
            $style_type="input"
            rules={validation["event_name"]}
            disabled={type === "Show"}
          />
          <FormFields
            name="location_id"
            label="Location"
            placeholder="Choose Location"
            $style_type="select"
            valueKey="id"
            labelKey="location_name"
            menuItem={locationList}
            rules={validation["location_id"]}
            disabled={type === "Show"}
          />
          <Row gutter={[16, 0]}>
            <Col xs={24}>
              <p className={commonStyles.m_b_0_2}>Define Event</p>
            </Col>
            <Col md={10} xs={24}>
              <FormFields
                name="parameter_id"
                placeholder="Choose LDR Value"
                $style_type="select"
                valueKey="id"
                labelKey="parameter_name"
                menuItem={parametersList}
                rules={validation["parameter_id"]}
                disabled={type === "Show"}
              />
            </Col>
            <Col md={7} xs={24}>
              <FormFields
                name="event_condition"
                placeholder="Choose Condition"
                $style_type="select"
                menuItem={CONDITIONS}
                rules={validation["event_condition"]}
                disabled={type === "Show"}
              />
            </Col>
            <Col md={7} xs={24}>
              <FormFields
                name="event_value"
                placeholder="Value"
                $style_type="input"
                rules={validation["event_value"]}
                disabled={type === "Show"}
              />
            </Col>
          </Row>
          <FormFields
            name="duration"
            label="Duration"
            $style_type="radio-group"
            labelKey="duration_name"
            valueKey="duration_value"
            menuItem={DURATION_TIME_LIST}
            rules={validation["duration"]}
            disabled={type === "Show"}
          />
          <FormFields
            name="days"
            label="On Days"
            placeholder="Select Days"
            $style_type="select"
            selectType="multiple"
            menuItem={DAYS}
            rules={validation["days"]}
            disabled={type === "Show"}
          />
          <Row gutter={[16, 0]}>
            <Col md={12} xs={24}>
              <FormFields
                name="time_from"
                label="Start Time"
                $style_type="time-picker"
                rules={validation["time_from"]}
                disabled={type === "Show"}
              />
            </Col>
            <Col md={12} xs={24}>
              <FormFields
                name="time_to"
                label="End Time"
                $style_type="time-picker"
                rules={validation["time_to"]}
                disabled={type === "Show"}
              />
            </Col>
          </Row>

          <FormFields
            name="repeat_every"
            label="Repeat Every"
            $style_type="radio-group"
            labelKey="time_name"
            valueKey="time_value"
            menuItem={REPEAT_TIME_LIST}
            rules={validation["repeat_every"]}
            disabled={type === "Show"}
          />
          <FormFields
            name="email_ids"
            label="Email Log"
            max={10}
            $style_type="tag-input"
            infoText="Separate by comma and max 10 allowed"
            placeholder="Email Logs"
            disabled={type === "Show"}
            value={emailIds}
            onChangeTags={(tag: string[]) => {
              if (Array.isArray(tag)) {
                form.setFieldValue("email_ids", tag);
                setEmailIds(tag);
              }
            }}
            rules={!emailIds.length ? validation["email_ids"] : undefined}
            tagValidation={{
              regExp: {
                pattern: new RegExp(
                  /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/
                ),
                message: "Please enter valid email ID",
              },
            }}
            onError={(error) => {
              form.setFields([
                {
                  name: "email_ids",
                  errors: error ? [error] : undefined,
                },
              ]);
            }}
          />
          <FormFields
            name="mobile"
            label="SMS Log"
            max={4}
            $style_type="tag-input"
            infoText="Separate by comma and max 4 allowed"
            placeholder="SMS Logs"
            disabled={type === "Show"}
            value={mobileNumbers}
            onChangeTags={(tag: string[]) => {
              if (Array.isArray(tag)) {
                form.setFieldValue("mobile", tag);
                setMobileNumbers(tag);
              }
            }}
            rules={!mobileNumbers.length ? validation["mobile"] : undefined}
            tagValidation={{
              regExp: {
                message: "Please enter valid phone number",
                pattern: new RegExp(/^\d{10}$/),
              },
            }}
            onError={(error) => {
              form.setFields([
                {
                  name: "mobile",
                  errors: error ? [error] : undefined,
                },
              ]);
            }}
          />
        </div>
        <div className="modal_footer">
          {type !== "Show" && (
            <>
              <StyledButton
                label="Reset"
                $style_type="ghost"
                onClick={handleReset}
              />
              <StyledButton
                htmlType="submit"
                label="Submit"
                disabled={loading}
              />
            </>
          )}
          <StyledButton
            label="Close"
            $style_type="secondary"
            onClick={() => handleCancel()}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default AddEditAlert;
