import React from "react";
import styles from "./Loader.module.scss"; // Import your custom CSS file

const Loader = () => {
  return (
    <div className={styles.custom_loader}>
      <div className={styles.loader_inner} />
    </div>
  );
};

export default Loader;
