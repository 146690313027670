import { useAuthCheck } from "../../../context/AuthCheckProvider";
import BothUserDashboard from "./BothUserDashboard";
import IotUserDashboard from "./IotUserDashboard";
import StreetLightUserDashboard from "./StreetLightUserDashboard";

const Dashboard = () => {
  const userAuth = useAuthCheck();

  return (
    <main>
      {userAuth?.userDetails?.working_as === "iot" && <IotUserDashboard />}
      {userAuth?.userDetails?.working_as === "street_light" && (
        <StreetLightUserDashboard />
      )}
      {userAuth?.userDetails?.working_as === "both" && <BothUserDashboard />}
    </main>
  );
};

export default Dashboard;
