import { Card, Col, Form, Row, Spin } from "antd";
import { Rule } from "antd/es/form";
import classNames from "classnames";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { fetchAllUserBasedData } from "../../../api/authorizeApi";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import FormFields from "../../../components/FormFields/FormFields";
import StyledButton from "../../../components/StyledButton/StyledButton";
import { useAuthCheck } from "../../../context/AuthCheckProvider";
import { useSnackBar } from "../../../context/SnackBarProvider";
import useApiResponse from "../../../customHooks/useApiResponse";
import { DurationProps } from "../../../utils/commonInterface";
import { formatDate } from "../../../utils/helperFunctions";
import {
  DURATION_LIST,
  EXTRACTION_TYPE,
  REPORT_TYPE,
} from "../../../utils/webStaticData";
import styles from "./Reports.module.scss";

const Reports = () => {
  const userAuth = useAuthCheck();
  const userID = userAuth?.userDetails?.id;
  const { apiResponse } = useApiResponse();
  const { snackbar } = useSnackBar();
  const [datePicker, setDatePicker] = useState<{
    picker?: DurationProps;
    format?: string;
  }>({});
  const [filteredReportform] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [locationList, setLocationList] = useState<Record<string, any>[]>([]);
  const [monthlyReportForm] = Form.useForm();
  const [sensorsList, setSensorsList] = useState<Record<string, any>[]>([]);
  const [sublocationList, setSublocationList] = useState<Record<string, any>[]>(
    []
  );

  const validation: Record<string, Rule[]> = {
    month: [{ required: true, message: "Please select month" }],
    durationRange: [{ required: true, message: "Please select date range" }],
  };

  const downloadReport = (
    jsonData: Record<string, any>[],
    selectedFormat: "xlsx" | "csv" = "xlsx"
  ) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(jsonData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: selectedFormat,
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type:
        selectedFormat === "xlsx"
          ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
          : "text/csv;charset=UTF-8",
    });
    saveAs(blob, `${Date.now()}.${selectedFormat}`);
  };

  const getMonthlyReport = async () => {
    setLoading(true);
    const values = monthlyReportForm.getFieldsValue();
    const payload = {
      user_id: userID,
      filter_month: formatDate(values.month?.$d, "MM-yyyy"),
    };
    const response = await apiResponse(
      await fetchAllUserBasedData("monthly_report", null, payload)
    );
    if (response) {
      if (response.report?.length) {
        downloadReport(response.report);
        snackbar("success", "Report downloaded successfully");
      } else {
        snackbar("error", "No report available for the selected month");
      }
    }
    setLoading(false);
  };

  const getFielsData = async () => {
    setLoading(true);
    const locationsResp = await apiResponse(
      await fetchAllUserBasedData("get_location", null, { user_id: userID })
    );
    const sensorsResp = await apiResponse(
      await fetchAllUserBasedData("allSensersByUser", null, { user_id: userID })
    );

    setLocationList(locationsResp.locations);
    setSensorsList(
      typeof sensorsResp.sensors === "string" ? [] : sensorsResp.sensors
    );
    setLoading(false);
  };

  const getFilteredReport = async () => {
    setLoading(true);
    const values = filteredReportform.getFieldsValue();
    let startDate = formatDate(values.durationRange[0]?.$d, "DD-MM-YYYY");
    let endDate = formatDate(values.durationRange[1]?.$d, "DD-MM-YYYY");
    if (values.duration === "month" || values.duration === "week") {
      startDate = formatDate(
        values.durationRange[0]?.$d,
        "DD-MM-YYYY",
        values.duration === "month" ? "month" : "week"
      );
      endDate = formatDate(
        values.durationRange[1]?.$d,
        "DD-MM-YYYY",
        null,
        values.duration === "month" ? "month" : "week"
      );
    }
    const payload = {
      user_id: userID,
      start_date: startDate,
      end_date: endDate,
      location_id: values.location_id,
      sublocation_id: values.sublocation_id,
      sensor_id: values.sensor_id,
      report_type: values.report_type,
    };

    const response = await apiResponse(
      await fetchAllUserBasedData("report", null, payload)
    );
    if (response) {
      if (response.result?.length) {
        downloadReport(response.result, values.extraction_type);
        snackbar("success", "Report downloaded successfully");
      } else {
        snackbar("error", "No report available");
      }
    }
    setLoading(false);
  };

  const handleDuration = (value: DurationProps) => {
    let datePickerData: { picker?: DurationProps; format?: string } = {
      picker: value,
    };
    if (value === "month") {
      datePickerData["format"] = "MMMM/YYYY";
    }
    setDatePicker(datePickerData);
    filteredReportform.setFieldValue("durationRange", "");
  };

  const handleLoactionChange = async (location: string | number) => {
    filteredReportform.setFieldValue("sublocation_id", null);
    if (location) {
      setLoading(true);
      const sublocationResp = await apiResponse(
        await fetchAllUserBasedData("sublocations", location)
      );

      setSublocationList(sublocationResp.sublocations);
      setLoading(false);
    }
  };

  useEffect(() => {
    getFielsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <Row className={commonStyles.display_j_center}>
        <Col xl={12} lg={15} md={20} sm={24} xs={24}>
          <Card
            className={classNames(
              commonStyles.card_wrapper,
              commonStyles.p_xy_1
            )}
          >
            <Spin spinning={loading}>
              <h5 className={commonStyles.m_b_1}>Reports</h5>
              <Form
                className={classNames(
                  commonStyles.m_b_1_5,
                  styles.monthly_report_form
                )}
                form={monthlyReportForm}
                layout="vertical"
                onFinish={() => getMonthlyReport()}
              >
                <FormFields
                  $style_type="date-picker"
                  label="Select Month"
                  name="month"
                  picker="month"
                  placeholder="Select Month"
                  rules={validation["month"]}
                  disabledDate={(current) =>
                    current.isAfter(dayjs().endOf("day"))
                  }
                />
                <StyledButton
                  label="Download Monthly Report"
                  htmlType="submit"
                />
              </Form>

              <Form
                form={filteredReportform}
                layout="vertical"
                initialValues={{ duration: "date" }}
                onFinish={() => getFilteredReport()}
              >
                <Row gutter={[16, 0]}>
                  <Col xs={24}>
                    <p className={commonStyles.m_b_0_2}>Duration</p>
                  </Col>
                  <Col md={12} sm={24}>
                    <FormFields
                      name="duration"
                      placeholder="Select duration type"
                      $style_type="select"
                      menuItem={DURATION_LIST}
                      uniqueKey="key"
                      labelKey="label"
                      valueKey="value"
                      onChangeSelect={(value) => handleDuration(value)}
                    />
                  </Col>
                  <Col md={12} sm={24}>
                    <FormFields
                      $style_type="range-picker"
                      dateformat={datePicker.format}
                      name="durationRange"
                      picker={datePicker.picker}
                      rules={validation["durationRange"]}
                      disabledDate={(current) => {
                        if (datePicker.picker === "week") {
                          return current.isAfter(dayjs().endOf("day").add(1));
                        } else {
                          return current.isAfter(dayjs().endOf("day"));
                        }
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <FormFields
                      name="location_id"
                      label="Location"
                      placeholder="Choose Location"
                      $style_type="select"
                      labelKey="location_name"
                      valueKey="id"
                      menuItem={locationList}
                      onChangeSelect={(value) => handleLoactionChange(value)}
                    />
                    <FormFields
                      name="sublocation_id"
                      label="SubLocation"
                      placeholder="Choose SubLoaction"
                      $style_type="select"
                      labelKey="location_name"
                      valueKey="id"
                      menuItem={sublocationList}
                    />
                    <FormFields
                      name="sensor_id"
                      label="Sensor"
                      placeholder="Choose Sensor"
                      $style_type="select"
                      labelKey="sensor_name"
                      valueKey="id"
                      menuItem={sensorsList}
                    />
                    <FormFields
                      name="report_type"
                      label="Report Type"
                      placeholder="Choose Report Type"
                      $style_type="select"
                      menuItem={REPORT_TYPE}
                    />
                    <FormFields
                      name="extraction_type"
                      label="Extraction Type"
                      placeholder="Choose Extraction Type"
                      $style_type="select"
                      menuItem={EXTRACTION_TYPE}
                    />
                    <StyledButton label="Extract" htmlType="submit" />
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Card>
        </Col>
      </Row>
    </main>
  );
};
export default Reports;
