import { ResponseType } from "axios";
import apiClient from "./api";

export const fetchKWh = async (
  day: "today" | "previous",
  userID: number | string
) => {
  return apiClient(
    "get",
    `/${day === "today" ? "gettodaykwh" : "getpreviousdaykwh"}/${userID}`
  );
};

export const fetchAllUserBasedData = async (
  type: string,
  id?: string | number | null,
  params?: any,
  responseType?: ResponseType
) => {
  return apiClient(
    "get",
    `/${type}${id ? "/" + id : ""}`,
    params,
    null,
    responseType
  );
};

export const userBasedResourceActions = async (
  type: string,
  requestType: "delete" | "post" | "patch" = "post",
  id?: string | number | null,
  body?: Record<string, any> | null,
  params?: Record<string, any> | null
) => {
  return apiClient(
    requestType,
    `/${type}${id ? "/" + id : ""}`,
    params,
    body,
    undefined,
    undefined
  );
};

export const fetchUsersList = async (params: any) => {
  return apiClient("get", `/admin/getallusers`, params);
};

export const fetchAllCompanyList = async () => {
  return apiClient("get", `/superadmin/company`);
};

export const fetchAllResourcesList = async (type: string) => {
  return apiClient("get", `/superadmin/${type}/list`);
};

export const fetchAllSuperAdminUserBasedData = async (
  type: string,
  id?: string | number
) => {
  return apiClient("get", `/superadmin/${type}${id ? "/" + id : ""}`);
};

export const createUpdateResource = async (
  type: string,
  body: Record<string, any>,
  requestType: "create" | "update" = "create",
  id?: string | number
) => {
  if (requestType === "create") {
    return apiClient("post", `/superadmin/${type}/create`, null, body);
  } else {
    return apiClient(
      "patch",
      `/superadmin/${type}/update${id ? "/" + id : ""}`,
      null,
      body
    );
  }
};

export const recoveryEnableDisable = async (
  type: string,
  body: Record<string, any>
) => {
  return apiClient("patch", `/superadmin/recovery/${type}`, null, body);
};
