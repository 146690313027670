import { Card, Spin, Table } from "antd";
import classNames from "classnames";
import { useEffect, useState } from "react";
import {
  fetchAllUserBasedData,
  userBasedResourceActions,
} from "../../../api/authorizeApi";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import { useAuthCheck } from "../../../context/AuthCheckProvider";
import { useSnackBar } from "../../../context/SnackBarProvider";
import useApiResponse from "../../../customHooks/useApiResponse";
import { USERS_CONTROLLERS_COLUMN } from "../../../utils/webTableConfig";
import styles from "./Controllers.module.scss";

const Controllers = () => {
  const { apiResponse } = useApiResponse();
  const [loading, setLoading] = useState(false);
  const { snackbar } = useSnackBar();
  const userAuth = useAuthCheck();
  const userID = userAuth?.userDetails?.id;
  const [userStreetLight, setUserStreetLight] = useState([]);

  const handleTableActions = (
    type: string,
    rowData: Record<string, any>,
    checked: boolean
  ) => {
    switch (type) {
      case "automate_status":
        handleAuotomateController(rowData.id, checked);
        break;
      case "controller_status":
        handleControllerStatus(
          checked,
          rowData?.controller_deviceid,
          rowData?.controller_details?.id
        );
        break;
      default:
        break;
    }
  };
  const handleAuotomateController = async (
    id: string | number,
    check: boolean
  ) => {
    setLoading(true);
    const response = await apiResponse(
      await userBasedResourceActions("automate_on_off", "post", null, {
        id: id,
        automate_status: check ? "1" : "0",
      })
    );
    if (response) {
      snackbar("success", "Automate Status updated successfully");
      getUserStreetLightData();
    }
    setLoading(false);
  };

  const handleControllerStatus = async (
    checked: boolean,
    controller_did?: string | number,
    controller_id?: string | number
  ) => {
    if (controller_did && controller_id) {
      setLoading(true);
      let response = await apiResponse(
        await userBasedResourceActions("controller_onoff", "post", null, {
          controller_did: controller_did,
          controller_id: controller_id,
          status: checked,
        })
      );
      setLoading(false);
      if (response) {
        snackbar("success", "Manual Status updated successfully");
        getUserStreetLightData();
      }
    }
  };

  const getUserStreetLightData = async () => {
    setLoading(true);
    const response = await apiResponse(
      await fetchAllUserBasedData("getControllers", null, {
        userid: userID,
      })
    );
    setUserStreetLight(response?.controllers || []);
    setLoading(false);
  };

  useEffect(() => {
    getUserStreetLightData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <Card
        title="Controllers"
        className={classNames(
          commonStyles.card_wrapper,
          styles.dashboards_card
        )}
      >
        <Spin spinning={loading}>
          <Table
            dataSource={userStreetLight}
            columns={USERS_CONTROLLERS_COLUMN(handleTableActions)}
            pagination={false}
            className={classNames(
              commonStyles.table_wrap,
              styles.dashboard_table
            )}
          />
        </Spin>
      </Card>
    </main>
  );
};
export default Controllers;
