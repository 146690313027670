import { Route, Routes } from "react-router-dom";
import Header from "../components/Header/Header";
import { useAuthCheck } from "../context/AuthCheckProvider";
import AdditionalSettings from "../pages/AdminUsers/AdditionalSettings";
import Alerts from "../pages/AdminUsers/Alerts";
import Calibration from "../pages/AdminUsers/Calibration";
import Controllers from "../pages/AdminUsers/Controllers";
import Dashboard from "../pages/AdminUsers/Dashboard";
import Reports from "../pages/AdminUsers/Reports";
import Profile from "../pages/SharedComponents/Profile";
import Users from "../pages/SharedComponents/Users";

function UsersAdminRoutes() {
  const authCheck = useAuthCheck();
  return (
    <>
      <Header />
      <Routes>
        <Route index path="/" element={<Dashboard />} />
        {["iot", "both"].includes(authCheck?.userDetails?.working_as) && (
          <>
            <Route index path="/controllers" element={<Controllers />} />
          </>
        )}

        {["street_light", "both"].includes(
          authCheck?.userDetails?.working_as
        ) && (
          <>
            <Route
              index
              path="/additional-setting"
              element={<AdditionalSettings />}
            />
            <Route index path="/mode" element={<Calibration />} />
          </>
        )}

        <Route index path="/alerts" element={<Alerts />} />
        <Route index path="/profile" element={<Profile />} />
        <Route index path="/reports" element={<Reports />} />
        {authCheck?.userDetails?.role === "admin" && (
          <Route index path="/users" element={<Users />} />
        )}
      </Routes>
    </>
  );
}

export default UsersAdminRoutes;
