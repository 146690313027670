import { Form } from "antd";
import styled from "styled-components";
import { styledHTMLComponentsProps } from "../../utils/commonInterface";
import { colors } from "../../utils/helperFunctions";
import { FormFieldsProps } from "./FormFields";

export const StyledFormField = styled(Form.Item)<
  styledHTMLComponentsProps<HTMLElement> & FormFieldsProps
>`
  .ant-form-item-label {
    padding: 0 0 3px;
    label {
      font-weight: 400;
      color: ${(props) => props.color || colors("black4")};
    }
  }
  ${(props) =>
    props.starticon || props.endicon
      ? ".ant-input-affix-wrapper,"
      : ".ant-input,"}
  .ant-picker,
  .ant-input-number,
  .ant-select
    .ant-select-selector {
    display: flex;
    border-color: ${(props) => props.$border_color || colors("grey4")};
    border-radius: ${(props) => props.radius || "10px"};
    min-height: ${(props) => props.height || "2.75rem"};
    width: ${(props) => props.width || "100%"};
    color: ${(props) => props.color || colors("black4")};
    margin: ${(props) => props.margin || "0"};
    align-items: center;
    &:focus,
    &:hover {
      border-color: ${(props) =>
        props.$hv_border_color || colors("blue1")}!important;
      box-shadow: 0 0 0 2px ${colors("blue2")}!important;
    }
    .ant-select-selection-search {
      display: flex;
      align-items: center;
    }
  }
  .ant-input-affix-wrapper {
    color: ${(props) => props.color || colors("black4")};
    font-size: 14px;
    .ant-input {
      // font-family: "Rubik", sans-serif;
      &::placeholder {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
  .ant-input-suffix {
    font-size: 16px;
    color: ${colors("grey2")};
  }
  .ant-select-focused.ant-select .ant-select-selector,
  .ant-select-focused .ant-input-password,
  .ant-input-password:hover {
    border-color: ${(props) =>
      props.$hv_border_color || colors("blue1")}!important;
    box-shadow: 0 0 0 2px ${colors("blue2")}!important;
  }
  .ant-input-password {
    padding: 0;
    border-radius: 10px;
    .ant-input {
      padding: 4px 11px;
      border-radius: 10px;
      &:hover {
        border: none !important;
        box-shadow: none !important;
      }
    }
    .ant-input-suffix {
      font-size: 1.2rem;
      color: ${colors("black4")};
      padding: 5px 10px;
      cursor: pointer;
    }
  }
  .ant-form-item-explain-error {
    text-align: left !important;
  }
`;
