import { Card, Col, Row } from "antd";
import classNames from "classnames";
import commonStyles from "../../../../../assets/styles/commonStyles.module.scss";
import { SUMMARY_CARD_Data } from "../../../../../utils/dummyData";
import styles from "../../Dashboard.module.scss";

interface SummarySectionProps {
  values: string[] | number[] | [];
}

const SummarySection: React.FC<SummarySectionProps> = ({ values }) => {
  return (
    <section
      className={classNames(commonStyles.m_b_1_5, styles.summary_wrapper)}
    >
      <Row gutter={[16, 16]}>
        {SUMMARY_CARD_Data.map((summary, index) => (
          <Col lg={6} md={12} sm={12} xs={24} key={summary.key}>
            <Card
              className={classNames(styles.summary_card, styles[summary.color])}
            >
              <div
                className={classNames(
                  commonStyles.display_flex,
                  commonStyles.display_j_spacebetween
                )}
              >
                <div>
                  <p className={commonStyles.text_secondary}>{summary.label}</p>
                  <h4 className={styles.summary_values}>{values[index]}</h4>
                </div>
                <div className={styles.widgets_icons}>{summary.icon}</div>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </section>
  );
};

export default SummarySection;
