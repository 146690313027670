import {
  FaCircleCheck,
  FaCube,
  FaLightbulb,
  FaMagnifyingGlassChart,
  FaMicrochip,
  FaRegChartBar,
  FaSatelliteDish,
  FaSliders,
  FaTriangleExclamation,
  FaUserGear,
  FaUsers,
} from "react-icons/fa6";
import { HiHome } from "react-icons/hi";
import { iconStylesProps } from "./helperFunctions";

import {
  blue,
  cyan,
  green,
  magenta,
  orange,
  purple,
  red,
  yellow,
} from "@ant-design/colors";

export const CONDITIONS = ["=", ">", "<", ">=", "<="];

export const COLORS = [blue, cyan, green, magenta, orange, purple, red, yellow];

export const DAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const DEVICE_TYPES_LIST = ["DG", "Mains", "Greens"];

export const DURATION_LIST = [
  {
    key: "duration-daily",
    label: "Daily",
    value: "date",
  },
  {
    key: "duration-weekly",
    label: "Weekly",
    value: "week",
  },
  {
    key: "duration-monthly",
    label: "Monthly",
    value: "month",
  },
];

export const DURATION_TIME_LIST = [
  { duration_name: "2 Min", duration_value: 2 },
  { duration_name: "5 Min", duration_value: 5 },
  { duration_name: "10 Min", duration_value: 10 },
  { duration_name: "15 Min", duration_value: 15 },
  { duration_name: "30 Min", duration_value: 30 },
  { duration_name: "1 Hour", duration_value: 60 },
  { duration_name: "1 Day", duration_value: 1440 },
];

export const EXTRACTION_TYPE = ["EXCEL", "CSV"];

export const GRAPH_REFRESH_DURATION = 1000 * 60 * 2;

export const HEADER_DATA = (working_as?: string, tab?: string) => [
  {
    key: "header-dashboard",
    label: "Dashboard",
    url: "/",
    icon: <HiHome {...iconStylesProps({ fontSize: "1.1875em" })} />,
    role: ["customer", "admin"],
  },
  {
    key: "header-users",
    label: "Users",
    url: "/users",
    icon: <FaCube {...iconStylesProps()} />,
    role: ["admin"],
  },
  {
    key: "header-controller",
    label: "Controllers",
    url: "/controllers",
    icon: <FaCube {...iconStylesProps()} />,
    role: ["iot"],
  },
  {
    key: "header-mode",
    label:
      working_as === "both" && tab === "Dashboard-IOT" ? "Controller" : "Mode",
    url: "/mode",
    icon: <FaMagnifyingGlassChart {...iconStylesProps()} />,
    role: ["street_light", "both"],
  },
  {
    key: "header-additional-setting",
    label: "Additional Setting",
    url: "/additional-setting",
    icon: <FaSliders {...iconStylesProps()} />,
    role: ["street_light", "both"],
  },
  {
    key: "header-alerts",
    label: "Alerts",
    url: "/alerts",
    icon: <FaTriangleExclamation {...iconStylesProps()} />,
    role: ["customer", "admin"],
  },
  {
    key: "header-reports",
    label: "Reports",
    url: "/reports",
    icon: <FaRegChartBar {...iconStylesProps()} />,
    role: ["customer", "admin"],
  },
];

export const REPEAT_TIME_LIST = [
  { time_name: "15 Min", time_value: 15 },
  { time_name: "30 Min", time_value: 30 },
  { time_name: "1 Hour", time_value: 60 },
  { time_name: "6 Hour", time_value: 360 },
  { time_name: "1 Day", time_value: 1440 },
];

export const REPORT_TYPE = ["Saving", "Consumption", "Theft"];

export const STATUS_LIST = [
  { id: "1", name: "Active" },
  { id: "0", name: "Inactive" },
];

export const SUPER_ADMIN_HEADER_DATA = [
  {
    key: "header-dashboard",
    label: "Dashboard",
    url: "/superadmin",
    icon: <HiHome {...iconStylesProps({ fontSize: "1.1875em" })} />,
    role: ["superadmin"],
  },
  {
    key: "header-clients",
    label: "Clients",
    url: "/superadmin/clients",
    icon: <FaUsers {...iconStylesProps()} />,
    role: ["superadmin"],
  },
  {
    key: "header-manage-users",
    label: "User Manage",
    url: "/superadmin/manage-users",
    icon: <FaUserGear {...iconStylesProps()} />,
    role: ["superadmin"],
  },
  {
    key: "header-device-type",
    label: "Device Type",
    url: "/superadmin/device-type",
    icon: <FaSatelliteDish {...iconStylesProps()} />,
    role: ["superadmin"],
  },
  {
    key: "header-sensors",
    label: "Sensors",
    url: "/superadmin/sensors",
    icon: <FaMicrochip {...iconStylesProps()} />,
    role: ["superadmin"],
  },
  {
    key: "header-controllers",
    label: "Controllers",
    url: "/superadmin/controllers",
    icon: <FaSliders {...iconStylesProps()} />,
    role: ["superadmin"],
  },
  {
    key: "header-light-control",
    label: "Light Control",
    url: "/superadmin/light-control",
    icon: <FaLightbulb {...iconStylesProps()} />,
    role: ["superadmin"],
  },
  {
    key: "header-recovery-check",
    label: "Recovery Check",
    url: "/superadmin/recovery-check",
    icon: <FaCircleCheck {...iconStylesProps()} />,
    role: ["superadmin"],
  },
];

export const ROLES_LIST = [
  {
    role: "admin",
    label: "Admin",
  },
  {
    role: "customer",
    label: "Customer",
  },
];

export const USER_WORKING_AS_LIST = [
  {
    value: "iot",
    label: "IOT",
  },
  {
    value: "street_light",
    label: "Street Light",
  },
  { value: "both", label: "IOT & Street Light" },
];

export const WORKING_AS_MAP: Record<string, string> = {
  iot: "IOT",
  street_light: "Street Light",
  both: "IOT & Street Light",
};

export const CALIBRATION_MODE: Record<string, string> = {
  clock: "Clock",
  ldr: "LDR",
  manual: "Manual",
};
