import { Card, Spin, Table } from "antd";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  fetchAllCompanyList,
  userBasedResourceActions,
} from "../../../api/authorizeApi";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import StyledButton from "../../../components/StyledButton/StyledButton";
import { useSnackBar } from "../../../context/SnackBarProvider";
import useApiResponse from "../../../customHooks/useApiResponse";
import { USERS_COLUMNS } from "../../../utils/webTableConfig";
import AddEditCompany from "./AddEditCompany";
import AddLocation from "./AddLocation";
import styles from "./Clients.module.scss";

const Clients = () => {
  const [actionType, setActionType] = useState("");
  const { apiResponse } = useApiResponse();
  const [companiesList, setCompaniesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [selectedCompany, setSelectedCompany] = useState<Record<string, any>>(
    {}
  );
  const [showAddEditClients, setShowAddEditClients] = useState(false);
  const [showAddLocation, setShowAddLocation] = useState(false);
  const navigateTo = useNavigate();
  const { snackbar } = useSnackBar();
  const handleAddEditClients = (title?: string, refresh?: boolean) => {
    if (title) {
      setModalTitle(title);
    }
    setShowAddEditClients((prev) => !prev);
    if (refresh) {
      getClientssData();
    }
  };

  const handleAddLocation = (title?: string, refresh?: boolean) => {
    if (title) {
      setModalTitle(title);
    }
    setShowAddLocation((prev) => !prev);
    if (refresh) {
      getClientssData();
    }
  };

  const handleTableActions = (type: string, rowData: any) => {
    setSelectedCompany(rowData || {});
    setActionType(type);
    switch (type) {
      case "Edit":
        handleAddEditClients("Edit Clients");
        break;
      case "Show":
        // setShowClientDetails(true);
        handleAddEditClients("Show Client Details");
        break;
      case "Location":
        handleAddLocation("Add Location");
        break;
      case "Sublocation":
        handleAddLocation("Add Sublocation");
        break;
      case "Redirect":
        navigateTo(`/superadmin/manage-users/admin/${rowData.id}`);
        break;
      case "Delete":
        handleDeleteCompany(+rowData.id);
        break;
      default:
        break;
    }
  };
  const getClientssData = async () => {
    setLoading(true);
    const clientsData = await fetchAllCompanyList();
    const response = await apiResponse(clientsData);
    setCompaniesList(response?.result || []);
    setLoading(false);
  };

  const handleDeleteCompany = async (id: number) => {
    setLoading(true);
    const response = await apiResponse(
      await userBasedResourceActions("superadmin/company/delete", "delete", id)
    );
    snackbar(response?.success ? "success" : "error", response.message);
    if (response?.success) {
      getClientssData();
    }
    setLoading(false);
  };

  useEffect(() => {
    getClientssData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <Card
        title="Clients"
        extra={
          <StyledButton
            label="Add New Company"
            onClick={() => {
              handleAddEditClients("Create New Company");
              setActionType("");
              setSelectedCompany({});
            }}
          />
        }
        className={classNames(commonStyles.card_wrapper, styles.sensors_card)}
      >
        <Spin spinning={loading}>
          <Table
            dataSource={companiesList}
            columns={USERS_COLUMNS("company", handleTableActions)}
            pagination={false}
            className={classNames(commonStyles.table_wrap, styles.sensor_table)}
          />
        </Spin>
      </Card>
      {showAddEditClients && (
        <AddEditCompany
          title={modalTitle}
          type={actionType}
          open={showAddEditClients}
          companyDetails={selectedCompany}
          onCancel={(refresh) => handleAddEditClients("", refresh)}
        />
      )}
      {showAddLocation && (
        <AddLocation
          company_id={selectedCompany?.id}
          title={modalTitle}
          open={showAddLocation}
          onCancel={(refresh) => handleAddLocation("", refresh)}
        />
      )}
    </main>
  );
};
export default Clients;
