import { Card, Col, Form, Modal, Row, Spin, Upload, UploadProps } from "antd";
import { Rule } from "antd/es/form";
import { RcFile, UploadFile } from "antd/es/upload";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { FiEye, FiEyeOff, FiPlus } from "react-icons/fi";
import {
  fetchAllSuperAdminUserBasedData,
  userBasedResourceActions,
} from "../../../api/authorizeApi";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import FormFields from "../../../components/FormFields/FormFields";
import StyledButton from "../../../components/StyledButton/StyledButton";
import { useAuthCheck } from "../../../context/AuthCheckProvider";
import { useSnackBar } from "../../../context/SnackBarProvider";
import useApiResponse from "../../../customHooks/useApiResponse";
import { validatePassword } from "../../../utils/helperFunctions";
const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      typeof reader.result === "string" &&
      resolve(reader.result?.split(",")[1]);
    reader.onerror = (error) => reject(error);
  });

const Profile = () => {
  const { apiResponse } = useApiResponse();
  const userAuth = useAuthCheck();
  const [clientsList, setClientsList] = useState<Record<string, any>[]>([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { snackbar } = useSnackBar();
  const userRole = userAuth?.userDetails?.role;
  const userID = userAuth?.userDetails?.id;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = (info) => {
    setFileList(info.fileList);
  };

  const uploadButton = (
    <div>
      <FiPlus />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const sendProfileEditRequest = async () => {
    const values = form.getFieldsValue();
    values["user_id"] = userID;
    if (fileList?.length && fileList[0].originFileObj)
      values["profile_image"] = fileList[0].originFileObj;
    setLoading(true);
    const response = await apiResponse(
      await userBasedResourceActions("profile-edit", "post", null, values)
    );
    setLoading(false);
    if (response) {
      snackbar("success", response.message);
      userAuth?.updateUserDetails(response?.result);
    }
  };

  const setFieldsValues = () => {
    return {
      name: userAuth?.userDetails?.name || "",
      email: userAuth?.userDetails?.email || "",
      phone: userAuth?.userDetails?.phone || "",
      company_id: userAuth?.userDetails?.company_id || "",
      address: userAuth?.userDetails?.address1 || "",
      city: userAuth?.userDetails?.city || "",
      state: userAuth?.userDetails?.state || "",
      country: userAuth?.userDetails?.country || "",
      zip: userAuth?.userDetails?.zip || "",
    };
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      if (userRole !== "superadmin") {
        const clients = await apiResponse(
          await fetchAllSuperAdminUserBasedData("company")
        );
        setClientsList(clients?.result || []);
      }
      setLoading(false);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validation: Record<string, Rule[]> = {
    name: [{ required: true, message: "Please enter name" }],
    email: [
      { required: true, message: "Please enter the email ID" },
      { type: "email", message: "Please enter valid email ID" },
    ],
    address: [{ required: true, message: "Please enter address" }],
    city: [{ required: true, message: "Please enter city" }],
    state: [{ required: true, message: "Please enter state" }],
    company_id: [{ required: true, message: "Please enter company name" }],
    zip: [
      {
        required: true,
        message: "Please enter valid pincode",
      },
      {
        message: "Please enter number only",
        pattern: new RegExp(/^\d{6}$/),
      },
    ],
    phone: [
      {
        required: true,
        message: "Please enter mobile number",
      },
      {
        message: "Please enter valid phone number",
        pattern: new RegExp(/^\d{10}$/),
      },
    ],
    country: [{ required: true, message: "Please enter country name" }],
    password: [{ validator: validatePassword }],
  };

  useEffect(() => {
    if (userAuth?.userDetails?.profile_image) {
      const profileImage = userAuth.userDetails.profile_image.split("/");
      setFileList([
        {
          uid: "-1",
          name: profileImage[profileImage.length - 1],
          status: "done",
          url: `${process.env.REACT_APP_BASE_URL}/${userAuth?.userDetails?.profile_image}`,
        },
      ]);
    }
    form.setFieldsValue(setFieldsValues());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth]);

  return (
    <main>
      <Spin spinning={loading}>
        <Row gutter={[20, 20]}>
          <Col xl={9} lg={9} md={10} xs={24}>
            <Card>
              <div
                className={classNames(
                  commonStyles.display_flex,
                  commonStyles.display_column,
                  commonStyles.display_a_center
                )}
              >
                {/*<Avatar
                  size={64}
                  icon={
                    <img
                      src={require("../../../assets/images/user-image.png")}
                      alt="profile"
                    />
                  }
                />*/}
                <Upload
                  listType="picture-circle"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  maxCount={1}
                  beforeUpload={() => false}
                  className={commonStyles.widthAuto}
                >
                  {fileList.length ? null : uploadButton}
                </Upload>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>
                <h3>{userAuth?.userDetails?.name || "-"}</h3>
                <p>
                  <b>Role: </b>
                  {userAuth?.userDetails?.role
                    ? userAuth?.userDetails?.role?.toUpperCase()
                    : "-"}
                </p>
                {userAuth?.userDetails?.role === "customer" && (
                  <p>
                    <b>Working As: </b>
                    {userAuth?.userDetails?.working_as === "iot"
                      ? "IOT"
                      : "Street Light"}
                  </p>
                )}
              </div>
            </Card>
          </Col>
          <Col xl={15} lg={15} md={14} xs={24}>
            <Card>
              <Form
                form={form}
                // labelCol={{ flex: "110px" }}
                // labelAlign="left"
                // labelWrap
                // wrapperCol={{ flex: 1 }}
                // colon={false}
                // style={{ maxWidth: 600 }}
                layout="vertical"
                onFinish={sendProfileEditRequest}
                initialValues={setFieldsValues()}
              >
                <FormFields
                  name="name"
                  label="Full Name"
                  placeholder="Full Name"
                  $style_type="input"
                  rules={validation["name"]}
                />
                <FormFields
                  $style_type="input"
                  name="email"
                  label="Email"
                  placeholder="jhon@placeholder.com"
                  rules={validation["email"]}
                />
                <FormFields
                  $style_type="password"
                  name="password"
                  label="Password"
                  placeholder="Enter Password"
                  iconRender={(visible) => (visible ? <FiEye /> : <FiEyeOff />)}
                  type="password"
                  rules={validation["password"]}
                />
                <FormFields
                  name="phone"
                  label="Mobile"
                  placeholder="Mobile"
                  $style_type="input"
                  rules={validation["phone"]}
                />
                {userRole !== "superadmin" ? (
                  <FormFields
                    name="company_id"
                    label="Client"
                    placeholder="Choose Client"
                    $style_type="select"
                    menuItem={clientsList}
                    valueKey="id"
                    labelKey="name"
                    rules={validation["company_id"]}
                  />
                ) : (
                  <></>
                )}

                <FormFields
                  name="address"
                  label="Address"
                  placeholder="Address"
                  $style_type="input"
                  rules={validation["address"]}
                />
                <FormFields
                  name="city"
                  label="City"
                  placeholder="City"
                  $style_type="input"
                  rules={validation["city"]}
                />

                <FormFields
                  name="state"
                  label="State"
                  placeholder="State"
                  $style_type="input"
                  rules={validation["state"]}
                />
                <FormFields
                  name="zip"
                  label="Pincode"
                  placeholder="Pincode"
                  $style_type="input"
                  rules={validation["zip"]}
                />
                <FormFields
                  name="country"
                  label="Country"
                  placeholder="Country"
                  $style_type="input"
                  rules={validation["country"]}
                />
                <StyledButton
                  htmlType="submit"
                  label="Save Changes"
                  width="100%"
                />
              </Form>
            </Card>
          </Col>
        </Row>
      </Spin>
    </main>
  );
};

export default Profile;
