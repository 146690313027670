import { useSnackBar } from "../context/SnackBarProvider";

const useApiResponse = () => {
  const { snackbar } = useSnackBar();
  const apiResponse = (response?: Record<string, any>) => {
    if (response?.type === "net_unauth" || response?.type === "error") {
      snackbar("error", response.message);
      return false;
    } else if (response?.type === "success") {
      return response?.data;
    }
  };
  return { apiResponse };
};

export default useApiResponse;
