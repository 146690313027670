import { Card, Spin, Table } from "antd";
import classNames from "classnames";
import { useEffect, useState } from "react";
import {
  fetchAllResourcesList,
  userBasedResourceActions,
} from "../../../api/authorizeApi";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import StyledButton from "../../../components/StyledButton/StyledButton";
import { useSnackBar } from "../../../context/SnackBarProvider";
import useApiResponse from "../../../customHooks/useApiResponse";
import { SUPER_ADMIN_DEVICE_TYPE_COLUMN } from "../../../utils/webTableConfig";
import AddEditDeviceType from "./AddEditDeviceType";
import styles from "./DeviceType.module.scss";

const DeviceType = () => {
  const { apiResponse } = useApiResponse();
  const [actionType, setActionType] = useState("");
  const [deviceList, setDeviceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [selectedDevice, setSelectedDevice] = useState<Record<string, any>>({});
  const [showAddEditDeviceType, setShowAddEditDeviceType] = useState(false);
  const { snackbar } = useSnackBar();

  const getDeviceData = async () => {
    setLoading(true);
    const deviceData = await fetchAllResourcesList("device");
    const response = await apiResponse(deviceData);
    setDeviceList(response?.result || []);
    setLoading(false);
  };

  const handleAddEditDeviceType = (title?: string, refresh?: boolean) => {
    if (title) {
      setModalTitle(title);
    }
    setShowAddEditDeviceType((prev) => !prev);
    if (refresh) {
      getDeviceData();
    }
  };
  const handleTableActions = (type: string, rowData: any) => {
    setSelectedDevice(rowData || {});
    setActionType(type);
    switch (type) {
      case "Edit":
        handleAddEditDeviceType("Edit Device");
        break;
      case "Show":
        handleAddEditDeviceType("Show Device");
        break;
      case "Delete":
        handleDeleteDevice(+rowData.id);
        break;
      default:
        break;
    }
  };

  const handleDeleteDevice = async (id: number) => {
    setLoading(true);
    const response = await apiResponse(
      await userBasedResourceActions(
        "superadmin/device/delete",
        "delete",
        null,
        null,
        { device_id: id }
      )
    );
    snackbar(response?.success ? "success" : "error", response.message);
    if (response?.success) {
      getDeviceData();
    }
    setLoading(false);
  };

  useEffect(() => {
    getDeviceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <Card
        title="Device Type"
        extra={
          <StyledButton
            label="Add Device"
            onClick={() => {
              handleAddEditDeviceType("Create New Device");
              setActionType("");
              setSelectedDevice({});
            }}
          />
        }
        className={classNames(commonStyles.card_wrapper, styles.sensors_card)}
      >
        <Spin spinning={loading}>
          <Table
            dataSource={deviceList}
            columns={SUPER_ADMIN_DEVICE_TYPE_COLUMN(handleTableActions)}
            pagination={false}
            className={classNames(commonStyles.table_wrap, styles.sensor_table)}
          />
        </Spin>
      </Card>
      {showAddEditDeviceType && (
        <AddEditDeviceType
          title={modalTitle}
          type={actionType}
          deviceDetails={selectedDevice}
          open={showAddEditDeviceType}
          onCancel={(refresh) => handleAddEditDeviceType("", refresh)}
        />
      )}
    </main>
  );
};
export default DeviceType;
