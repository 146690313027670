import {
  FaCommentDots,
  FaFlag,
  FaFlagUsa,
  FaHeartCircleCheck,
  FaMicrochip,
  FaMoneyBillTrendUp,
  FaPlugCircleBolt,
  FaShareNodes,
} from "react-icons/fa6";
import { colors, iconStylesProps } from "./helperFunctions";

export const SUMMARY_CARD_Data = [
  {
    color: "blue",
    icon: <FaMicrochip />,
    key: "summary-card-sensor",
    label: "Total Sensors",
  },
  {
    color: "red",
    icon: <FaPlugCircleBolt />,
    key: "summary-card-last-day",
    label: "Last Day",
  },
  {
    color: "green",
    icon: <FaPlugCircleBolt />,
    key: "summary-card-today",
    label: "Today",
  },
  {
    color: "orange",
    icon: <FaMoneyBillTrendUp />,
    key: "summary-card-consumption-cost",
    label: "Consumption Cost",
  },
];

export const LIKE_SHARE_COMMENTS = [
  {
    key: "like-share-comments-1",
    title: "Total Likes",
    value: "45.6M",
    statistic: "+6.2% from last week",
    icon: <FaHeartCircleCheck />,
    color: "purple",
  },
  {
    key: "like-share-comments-2",
    title: "Comments",
    value: "25.6K",
    statistic: "+3.7% from last week",
    icon: <FaCommentDots />,
    color: "red",
  },
  {
    key: "like-share-comments-3",
    title: "Total Sgares",
    value: "85.4M",
    statistic: "+4.6% from last week",
    icon: <FaShareNodes />,
    color: "yellow",
  },
];

export const RECENT_ORDERS_COUNTRIES = [
  {
    key: "recent-order-1",
    country: "USA",
    icon: <FaFlagUsa {...iconStylesProps({ fontSize: "14px" })} />,
    percent: "70",
    color: colors("blue5"),
  },
  {
    key: "recent-order-2",
    country: "Canada",
    icon: <FaFlag {...iconStylesProps({ fontSize: "14px" })} />,
    percent: "65",
    color: colors("red1"),
  },
  {
    key: "recent-order-3",
    country: "England",
    icon: <FaFlag {...iconStylesProps({ fontSize: "14px" })} />,
    percent: "60",
    color: colors("green1"),
  },
  {
    key: "recent-order-4",
    country: "Australia",
    icon: <FaFlag {...iconStylesProps({ fontSize: "14px" })} />,
    percent: "55",
    color: colors("orange1"),
  },
  {
    key: "recent-order-5",
    country: "India",
    icon: <FaFlag {...iconStylesProps({ fontSize: "14px" })} />,
    percent: "50",
    color: colors("blue4"),
  },
  {
    key: "recent-order-6",
    country: "China",
    icon: <FaFlag {...iconStylesProps({ fontSize: "14px" })} />,
    percent: "45",
    color: colors("black4"),
  },
];
