import React from "react";
import { FormItemProps, Switch, SwitchProps } from "antd";
import { styledHTMLComponentsProps } from "../../utils/commonInterface";
import { StyledFormField } from "./StyledFormField";
import commonStyles from "../../assets/styles/commonStyles.module.scss";
import classNames from "classnames";

const SwitchField: React.FC<
  styledHTMLComponentsProps<HTMLElement> & FormItemProps & SwitchProps
> = ({
  className = "",
  defaultChecked,
  disabled,
  rules,
  valuePropName,
  onChange,
  ...props
}) => {
  return (
    <div
      className={classNames(
        commonStyles.display_flex,
        commonStyles.display_a_center,
        commonStyles.gap_2,
        commonStyles.m_b_1
      )}
    >
      <StyledFormField
        name={props.name}
        rules={rules}
        style={{ marginBottom: 0 }}
        className={className}
        valuePropName={valuePropName}
        {...props}
        label={""}
      >
        <Switch defaultChecked={defaultChecked} disabled={disabled} />
      </StyledFormField>
      <label
        htmlFor={props.name}
        className={classNames(commonStyles.m_b_0, commonStyles.pointer)}
      >
        {props.label}
      </label>
    </div>
  );
};

export default SwitchField;
