import { Card, Spin, Table } from "antd";
import classNames from "classnames";
import { useEffect, useState } from "react";
import {
  fetchAllUserBasedData,
  userBasedResourceActions,
} from "../../../api/authorizeApi";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import StyledButton from "../../../components/StyledButton/StyledButton";
import { useAuthCheck } from "../../../context/AuthCheckProvider";
import { useSnackBar } from "../../../context/SnackBarProvider";
import useApiResponse from "../../../customHooks/useApiResponse";
import { ADMIN_CUSTOMER_ALERTS_COLUMN } from "../../../utils/webTableConfig";
import AddEditAlert from "./AddEditAlert";
import styles from "./Alerts.module.scss";

const Alerts = () => {
  const userAuth = useAuthCheck();
  const userID = userAuth?.userDetails?.id;
  const { apiResponse } = useApiResponse();
  const { snackbar } = useSnackBar();
  const [actionType, setActionType] = useState("");
  const [alertList, setAlertList] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [selectedAlert, setSelectedAlert] = useState<Record<string, any>>({});
  const [showAddEditAlert, setShowAddEditAlert] = useState(false);

  const getAlerts = async () => {
    setLoading(true);
    const response = await apiResponse(
      await fetchAllUserBasedData("get_alerts", null, { userid: userID })
    );
    setAlertList(response.allAlerts);
    setLoading(false);
  };

  const handleAddEditAlert = (title?: string, refresh?: boolean) => {
    if (title) {
      setModalTitle(title);
    }
    setShowAddEditAlert((prev) => !prev);
    if (refresh) {
      getAlerts();
    }
  };

  const handleDelete = async (id: string | number) => {
    if (id) {
      setLoading(true);
      const response = await apiResponse(
        await userBasedResourceActions("delete_alerts", "delete", null, null, {
          alert_id: id,
        })
      );
      if (response) {
        snackbar("success", response.message);
        getAlerts();
      }
      setLoading(false);
    } else {
      snackbar("warning", "Invalid Alert");
    }
  };

  const handleTableActions = (
    type: string,
    rowData: any,
    checked: boolean = false
  ) => {
    setSelectedAlert(rowData || {});
    setActionType(type);
    switch (type) {
      case "Delete":
        handleDelete(rowData.id);
        break;
      case "Edit":
        handleAddEditAlert("Edit Alert");
        break;
      case "Show":
        handleAddEditAlert("Show Alert Details");
        break;
      case "Status":
        handleStatus(rowData.id, checked);
        break;
      default:
        break;
    }
  };

  const handleStatus = async (id: string | number, isChecked: boolean) => {
    if (id) {
      setLoading(true);
      const response = await apiResponse(
        await userBasedResourceActions(
          isChecked ? "alerts/enable" : "alerts/disable",
          "patch",
          null,
          null,
          {
            id,
          }
        )
      );
      if (response) {
        snackbar("success", response.message);
        getAlerts();
      }
    } else {
      snackbar("warning", "Invalid Alert");
    }
  };

  useEffect(() => {
    getAlerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <Card
        title="Alerts"
        extra={
          <StyledButton
            label="Add Alert"
            onClick={() => {
              handleAddEditAlert("Create New Alert");
              setSelectedAlert({});
              setActionType("");
            }}
          />
        }
        className={classNames(commonStyles.card_wrapper, styles.sensors_card)}
      >
        <Spin spinning={loading}>
          <Table
            dataSource={alertList}
            columns={ADMIN_CUSTOMER_ALERTS_COLUMN(handleTableActions)}
            pagination={false}
            className={classNames(commonStyles.table_wrap, styles.sensor_table)}
          />
        </Spin>
      </Card>
      {showAddEditAlert && (
        <AddEditAlert
          title={modalTitle}
          open={showAddEditAlert}
          onCancel={(refresh) => handleAddEditAlert("", refresh)}
          type={actionType}
          alertDetails={selectedAlert}
        />
      )}
    </main>
  );
};
export default Alerts;
