import { Card, Spin, Table } from "antd";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { fetchAllUserBasedData } from "../../../../api/authorizeApi";
import commonStyles from "../../../../assets/styles/commonStyles.module.scss";
import { useAuthCheck } from "../../../../context/AuthCheckProvider";
import useApiResponse from "../../../../customHooks/useApiResponse";
import { USERS_STREETLIST_DASHBOARD_COLUMN } from "../../../../utils/webTableConfig";

const StreetLightUserDashboard = () => {
  const { apiResponse } = useApiResponse();
  const [loading, setLoading] = useState(false);
  const userAuth = useAuthCheck();
  const userID = userAuth?.userDetails?.id;
  const [userStreetLight, setUserStreetLight] = useState([]);

  const getUserStreetLightData = async () => {
    setLoading(true);
    const response = await apiResponse(
      await fetchAllUserBasedData("all_street_light_data", null, {
        user_id: userID,
      })
    );
    setUserStreetLight(response?.result || []);
    setLoading(false);
  };

  useEffect(() => {
    getUserStreetLightData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card title="Dashboard" className={classNames(commonStyles.card_wrapper)}>
      <Spin spinning={loading}>
        <Table
          dataSource={userStreetLight}
          columns={USERS_STREETLIST_DASHBOARD_COLUMN()}
          pagination={false}
          className={classNames(commonStyles.table_wrap)}
        />
      </Spin>
    </Card>
  );
};
export default StreetLightUserDashboard;
