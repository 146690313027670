import { Form, Modal } from "antd";
import { Rule } from "antd/es/form";
import classNames from "classnames";
import { useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { createUpdateResource } from "../../../api/authorizeApi";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import FormFields from "../../../components/FormFields/FormFields";
import Loader from "../../../components/Loader/Loader";
import StyledButton from "../../../components/StyledButton/StyledButton";
import { useSnackBar } from "../../../context/SnackBarProvider";
import useApiResponse from "../../../customHooks/useApiResponse";
import { colors, iconStylesProps } from "../../../utils/helperFunctions";

interface AddEditUsersProps {
  open: boolean;
  title: string;
  type: string;
  deviceDetails: Record<string, any>;
  onCancel: (refresh?: boolean) => void;
}
const AddEditDeviceType: React.FC<AddEditUsersProps> = ({
  open,
  title,
  type,
  deviceDetails,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { apiResponse } = useApiResponse();
  const { snackbar } = useSnackBar();

  const validation: Record<string, Rule[]> = {
    device_name: [{ required: true, message: "Please enter device name" }],
    device_description: [
      { required: true, message: "Please enter device description" },
    ],
    parameter_name: [
      { required: true, message: "Please enter parameter name" },
      {
        message: "Comma is not allowed",
        pattern: new RegExp(/^(?![\s\S]*,)[\s\S]*$/),
      },
    ],
    mapped_parameter: [
      { required: true, message: "Please enter parameter key" },
      {
        message: "Comma is not allowed",
        pattern: new RegExp(/^(?![\s\S]*,)[\s\S]*$/),
      },
    ],
  };

  const sendAddEditDeviceTypeRequest = async () => {
    const values = form.getFieldsValue();
    const payload = {
      device_name: values["device_name"],
      device_description: values["device_description"],
      device_parameter: JSON.stringify(values["device_parameters"]),
    };

    setLoading(true);
    let createUpdateResponse = await createUpdateResource(
      "device",
      payload,
      type === "Edit" ? "update" : "create",
      type === "Edit" && deviceDetails.id
    );
    const response = await apiResponse(createUpdateResponse);
    setLoading(false);
    if (response) {
      onCancel(true);
      form.resetFields();
      snackbar("success", response.message);
    }
  };

  const setFieldsValues = () => {
    const device_parameters = deviceDetails?.device_parameters?.map(
      (device: Record<string, any>) => ({
        ...(type === "Edit" ? { id: device.id } : {}),
        parameter_name: device.parameter_name || "",
        mapped_parameter: device.mapped_parameter || "",
      })
    );
    return {
      device_name: deviceDetails?.device_name || "",
      device_description: deviceDetails?.device_description || "",
      device_parameters:
        device_parameters && device_parameters.length
          ? device_parameters
          : [
              {
                ...(type === "Edit" ? { id: "" } : {}),
                parameter_name: "",
                mapped_parameter: "",
              },
            ],
    };
  };

  const handleReset = () => {
    if (deviceDetails && Object.keys(deviceDetails).length) {
      form.setFieldsValue(setFieldsValues());
    } else {
      form.resetFields();
    }
  };

  return (
    <Modal
      className={classNames(commonStyles.modal_wrapper)}
      footer={false}
      mask
      maskClosable={false}
      open={open}
      onCancel={() => onCancel()}
      title={<h5>{title}</h5>}
      style={{ top: "1rem", width: "60vw" }}
    >
      {loading && <Loader />}
      <Form
        layout="vertical"
        form={form}
        onFinish={sendAddEditDeviceTypeRequest}
        initialValues={setFieldsValues()}
      >
        <div className="modal_form">
          <FormFields
            name="device_name"
            label="Device Name"
            placeholder="Device Name"
            $style_type="input"
            rules={validation["device_name"]}
            disabled={type === "Show"}
          />

          <FormFields
            name="device_description"
            label="Device Description"
            placeholder="Device Description"
            $style_type="input"
            rules={validation["device_description"]}
            disabled={type === "Show"}
          />

          <Form.List name="device_parameters">
            {(fields, { add, remove }) => (
              <>
                {type !== "Show" && (
                  <StyledButton
                    label="Add"
                    onClick={() => add()}
                    margin="3rem 0 1rem"
                  />
                )}
                {fields?.map(({ key, name, ...restField }) => (
                  <div
                    className={classNames(
                      commonStyles.display_flex,
                      commonStyles.display_a_center,
                      commonStyles.gap_2
                    )}
                  >
                    <FormFields
                      key={`Parameter-${key}`}
                      name={[name, "parameter_name"]}
                      label="Parameter Name"
                      placeholder="Enter Parameter Name"
                      rules={validation["parameter_name"]}
                      disabled={type === "Show"}
                    />
                    <FormFields
                      key={`Parameter-Key-${key}`}
                      name={[name, "mapped_parameter"]}
                      label="Parameter Key"
                      placeholder="Enter Parameter Key"
                      rules={validation["mapped_parameter"]}
                      disabled={type === "Show"}
                    />
                    {type !== "Show" && fields.length !== 1 ? (
                      <FiTrash2
                        {...iconStylesProps({
                          fontSize: "1.1rem",
                          width: "3rem",
                          color: colors("red2"),
                          cursor: "pointer",
                        })}
                        onClick={() => remove(name)}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
              </>
            )}
          </Form.List>
        </div>
        <div className="modal_footer">
          {type !== "Show" && (
            <>
              <StyledButton
                label="Reset"
                $style_type="ghost"
                onClick={handleReset}
              />
              <StyledButton
                htmlType="submit"
                label="Submit"
                disabled={loading}
              />
            </>
          )}
          <StyledButton
            label="Close"
            $style_type="secondary"
            onClick={() => onCancel()}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default AddEditDeviceType;
