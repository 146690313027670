import { Col, Form, Modal, Row } from "antd";
import { Rule } from "antd/es/form";
import classNames from "classnames";
import { cloneDeep } from "lodash";
import { useMemo, useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { userBasedResourceActions } from "../../../api/authorizeApi";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import FormFields from "../../../components/FormFields/FormFields";
import SwitchField from "../../../components/FormFields/SwitchField";
import Loader from "../../../components/Loader/Loader";
import StyledButton from "../../../components/StyledButton/StyledButton";
import { useSnackBar } from "../../../context/SnackBarProvider";
import useApiResponse from "../../../customHooks/useApiResponse";
import {
  colors,
  handleFormPreventDefault,
  iconStylesProps,
} from "../../../utils/helperFunctions";
import styles from "./Clients.module.scss";
import ViewCompany from "./ViewCompany";

interface AddEditUsersProps {
  open: boolean;
  title: string;
  type: string;
  companyDetails: Record<string, any>;
  onCancel: (refresh?: boolean) => void;
}
const AddEditCompany: React.FC<AddEditUsersProps> = ({
  open,
  title,
  type,
  companyDetails,
  onCancel,
}) => {
  const [actionType, setActionType] = useState(type);
  const [modalTitle, setModalTitle] = useState(title);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { apiResponse } = useApiResponse();
  const { snackbar } = useSnackBar();
  const defaultLocation = {
    id: null,
    location_name: null,
    sublocations: [],
    unit_rate: 1,
  };
  const locations_details: Record<string, any>[] = [];
  const locations_Parameters = useMemo(() => {
    return companyDetails?.locations?.length
      ? [...companyDetails?.locations]?.map((location: Record<string, any>) => {
          if (location) {
            locations_details.push({
              id: location.id || null,
              location_name: location.location_name || null,
              sublocations:
                location.sublocations?.map(
                  (sublocation: Record<string, any>) => ({
                    sublocation_id: sublocation.subocation_id || null,
                    sublocation_name: sublocation.sublocation_name,
                    location_id: location.id || null,
                  })
                ) || [],
              unit_rate: location.unit_rate || 1,
            });
          }
          return {
            id: location.id || null,
            location_name: location.location_name || "",
            sublocations:
              location.sublocations?.map(
                (sublocation: Record<string, any>) =>
                  sublocation.sublocation_name
              ) || [],
            unit_rate: location.unit_rate || 1,
          };
        })
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [locationsDetails, setLocationsDetails] = useState<
    Record<string, any>[]
  >(locations_details || []);

  const [initialLoactionDetails] = useState<Record<string, any>[]>(
    locations_details || []
  );
  const validation: Record<string, Rule[]> = {
    name: [{ required: true, message: "Please enter company name" }],
    email: [
      { required: true, message: "Please enter the email ID" },
      { type: "email", message: "Please enter valid email ID" },
    ],
    address: [{ required: true, message: "Please enter company address" }],
    city: [{ required: true, message: "Please enter city" }],
    state: [{ required: true, message: "Please enter state" }],
    pincode: [
      {
        required: true,
        message: "Please enter pincode",
      },
      {
        message: "Please enter valid pincode",
        pattern: new RegExp(/^\d{6}$/),
      },
    ],
    country: [{ required: true, message: "Please enter country name" }],
    location_name: [{ required: true, message: "Please enter location" }],
    unit_rate: [
      { required: true, message: "Please enter unit rate" },
      {
        pattern: new RegExp(/^[1-9]\d*$/),
        message: "Please enter a positive number not less than 1",
      },
    ],
  };

  const sendAddEditCompanyRequest = async () => {
    const values = form.getFieldsValue();
    values["status"] = values["status"] ? "1" : "0";
    values["is_controller"] = values["is_controller"] ? "1" : "0";
    values["locations"] = locationsDetails.length
      ? JSON.stringify(locationsDetails)
      : null;
    delete values["locationsParameter"];
    if (actionType === "Edit") {
      values["id"] = companyDetails.id;
    }
    setLoading(true);
    let createUpdateResponse = await userBasedResourceActions(
      actionType === "Edit"
        ? "superadmin/company/update_with_location"
        : "superadmin/company/create",
      actionType === "Edit" ? "patch" : "post",
      null,
      values
    );
    const response = await apiResponse(createUpdateResponse);
    setLoading(false);
    if (response.success) {
      onCancel(true);
      form.resetFields();
      snackbar("success", response.message);
    } else {
      snackbar("error", response.message);
    }
  };

  const setFieldsValues = () => {
    return {
      address: companyDetails?.address || "",
      city: companyDetails?.city || "",
      country: companyDetails?.country || "",
      email: companyDetails?.email || "",
      locationsParameter:
        locations_Parameters && locations_Parameters.length
          ? locations_Parameters
          : null,
      is_controller:
        companyDetails?.is_controller && +companyDetails?.is_controller === 1
          ? true
          : false,
      name: companyDetails?.name || "",
      pincode: companyDetails?.pincode || "",
      state: companyDetails?.state || "",
      status:
        companyDetails?.status && +companyDetails?.status === 1 ? true : false,
    };
  };

  const handleReset = () => {
    if (companyDetails && Object.keys(companyDetails).length) {
      form.setFieldsValue(setFieldsValues());
      setLocationsDetails([...initialLoactionDetails]);
    } else {
      form.resetFields();
    }
  };

  return (
    <Modal
      className={classNames(commonStyles.modal_wrapper, commonStyles.wideModal)}
      footer={false}
      mask
      maskClosable={false}
      open={open}
      onCancel={() => onCancel()}
      closeIcon={false}
      title={
        <div
          className={classNames(
            commonStyles.display_flex,
            commonStyles.display_a_center,
            commonStyles.gap_3
          )}
        >
          <h5>{modalTitle}</h5>
          {actionType && (
            <StyledButton
              margin="0 0 0 auto"
              htmlType="button"
              label={actionType === "Show" ? "Edit" : "View"}
              disabled={loading}
              onClick={() => {
                setActionType(actionType === "Show" ? "Edit" : "Show");
                setModalTitle("Edit Client");
              }}
            />
          )}
          {actionType === "Show" && (
            <StyledButton
              label="Close"
              $style_type="secondary"
              onClick={() => onCancel()}
            />
          )}
        </div>
      }
      style={{ top: "1rem" }}
    >
      {loading && <Loader />}
      {actionType !== "Show" ? (
        <Form
          layout="vertical"
          form={form}
          onKeyDown={handleFormPreventDefault}
          onFinish={sendAddEditCompanyRequest}
          initialValues={setFieldsValues()}
        >
          <div className="modal_form">
            <Row gutter={[16, 0]}>
              <Col md={12} xs={24}>
                <FormFields
                  name="name"
                  label="Company Name"
                  placeholder="Company Name"
                  $style_type="input"
                  rules={validation["name"]}
                />
              </Col>
              <Col md={12} xs={24}>
                <FormFields
                  name="email"
                  label="Company Email"
                  placeholder="Company Email"
                  $style_type="input"
                  rules={validation["email"]}
                />
              </Col>
              <Col xs={24}>
                <FormFields
                  name="address"
                  label="Company Address"
                  placeholder="Company Address"
                  $style_type="input"
                  rules={validation["address"]}
                />
              </Col>
              <Col md={12} xs={24}>
                <FormFields
                  name="city"
                  label="Company City"
                  placeholder="Company City"
                  $style_type="input"
                  rules={validation["city"]}
                />
              </Col>
              <Col md={12} xs={24}>
                <FormFields
                  name="state"
                  label="Company State"
                  placeholder="Company State"
                  $style_type="input"
                  rules={validation["state"]}
                />
              </Col>
              <Col md={12} xs={24}>
                <FormFields
                  name="pincode"
                  label="Pincode"
                  placeholder="Pincode"
                  $style_type="input"
                  rules={validation["pincode"]}
                />
              </Col>
              <Col md={12} xs={24}>
                <FormFields
                  name="country"
                  label="Company Country"
                  placeholder="Company Country"
                  $style_type="input"
                  rules={validation["country"]}
                />
              </Col>
              <Col xs={24}>
                <Form.List name="locationsParameter">
                  {(fields, { add, remove }) => (
                    <>
                      <StyledButton
                        label="Add Location"
                        onClick={() => add(defaultLocation)}
                        margin="1rem 0"
                      />

                      {fields?.map(({ key, name, ...restField }) => (
                        <>
                          <h6
                            className={classNames(
                              styles.sensorDetail,
                              commonStyles.m_b_0_5,
                              commonStyles.display_flex,
                              commonStyles.display_center_spacebetween,
                              commonStyles.gap_2
                            )}
                          >
                            Location Detail: {name + 1}
                            {fields.length !== 1 ? (
                              <FiTrash2
                                {...iconStylesProps({
                                  fontSize: "1.1rem",
                                  width: "1.3rem",
                                  color: colors("red2"),
                                  cursor: "pointer",
                                  margin: "0",
                                })}
                                onClick={() => {
                                  remove(name);
                                  setLocationsDetails((prev) =>
                                    prev.filter(
                                      (location, index) => index !== name
                                    )
                                  );
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </h6>
                          <Row gutter={[16, 0]}>
                            <Col md={12} xs={24}>
                              <FormFields
                                key={`Location-Name-${key}`}
                                name={[name, "location_name"]}
                                label="Location Name"
                                placeholder="Enter Location Name"
                                rules={validation["location_name"]}
                              />
                            </Col>
                            <Col md={12} xs={24}>
                              <FormFields
                                key={`Sublocation-Name-${key}`}
                                name={[name, "sublocations"]}
                                label="Sublocations"
                                placeholder="Enter Sublocation Name"
                                $style_type="tag-input"
                                infoText="Separate by comma"
                                onChangeTags={(tags: string[]) => {
                                  let allLocations = cloneDeep(
                                    form.getFieldValue("locationsParameter")
                                  );
                                  const formLocations = cloneDeep(allLocations);

                                  allLocations = allLocations?.map(
                                    (
                                      location: Record<string, any>,
                                      index: number
                                    ) => {
                                      if (index === name) {
                                        location["sublocations"] =
                                          tags?.map((tag, subTagIndex) => ({
                                            location_id:
                                              locationsDetails[index]?.id ||
                                              null,
                                            sublocation_id:
                                              locationsDetails[index]?.[
                                                "sublocations"
                                              ]?.[subTagIndex]?.[
                                                "sublocation_id"
                                              ] || null,
                                            sublocation_name: tag,
                                          })) || [];
                                        formLocations[index]["sublocations"] = [
                                          ...tags,
                                        ];
                                      } else {
                                        location["sublocations"] =
                                          location["sublocations"]?.map(
                                            (
                                              tag: string,
                                              sublocIndex: number
                                            ) => {
                                              return {
                                                location_id:
                                                  locationsDetails[index]?.id ||
                                                  null,
                                                sublocation_id:
                                                  locationsDetails[index]?.[
                                                    "sublocations"
                                                  ]?.[sublocIndex]?.[
                                                    "sublocation_id"
                                                  ] || null,
                                                sublocation_name: tag,
                                              };
                                            }
                                          ) || [];
                                      }

                                      return location;
                                    }
                                  );
                                  setLocationsDetails(allLocations);

                                  form.setFieldValue(
                                    "locationsParameter",
                                    formLocations
                                  );
                                }}
                                value={
                                  form.getFieldValue("locationsParameter")[
                                    name
                                  ]["sublocations"]
                                }
                              />
                            </Col>
                            <Col md={12} xs={24}>
                              <FormFields
                                key={`Unit-Rate-Name-${key}`}
                                name={[name, "unit_rate"]}
                                label="Unit Rate"
                                placeholder="Enter Unit Rate"
                                rules={validation["unit_rate"]}
                              />
                            </Col>
                          </Row>
                        </>
                      ))}
                    </>
                  )}
                </Form.List>
              </Col>
            </Row>

            <SwitchField
              label="Status"
              name="status"
              valuePropName={
                +companyDetails.status === 0 ? "unchecked" : "checked"
              }
              defaultChecked={form.getFieldValue("status")}
            />
            <SwitchField
              label="Add Controller?"
              name="is_controller"
              valuePropName={
                +companyDetails.is_controller === 0 ? "unchecked" : "checked"
              }
              defaultChecked={form.getFieldValue("is_controller") || false}
            />
          </div>
          <div className="modal_footer">
            {actionType !== "Show" && (
              <>
                <StyledButton
                  label="Reset"
                  $style_type="ghost"
                  onClick={handleReset}
                />
                <StyledButton
                  htmlType="submit"
                  label="Submit"
                  disabled={loading}
                />
              </>
            )}
            <StyledButton
              label="Close"
              $style_type="secondary"
              onClick={() => onCancel()}
            />
          </div>
        </Form>
      ) : (
        <ViewCompany companyDetails={companyDetails} />
      )}
    </Modal>
  );
};

export default AddEditCompany;
