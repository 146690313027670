import { useState, useEffect } from "react";
import { Card, Spin, Table } from "antd";
import classNames from "classnames";
import { SUPER_ADMIN_LIGHT_CONTROL_COLUMN } from "../../../utils/webTableConfig";
import {
  createUpdateResource,
  fetchAllResourcesList,
} from "../../../api/authorizeApi";
import useApiResponse from "../../../customHooks/useApiResponse";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import styles from "./LightControl.module.scss";
import { useSnackBar } from "../../../context/SnackBarProvider";

const LightControl = () => {
  const { apiResponse } = useApiResponse();
  const { snackbar } = useSnackBar();
  const [editedRow, setEditedRow] = useState<
    Record<string, Record<string, any>>
  >({});
  const [lightControlList, setLightControlList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getLightControlData = async () => {
    setLoading(true);
    const lightControlData = await fetchAllResourcesList("lights");
    const response = await apiResponse(lightControlData);
    setLightControlList(response?.result);
    setLoading(false);
  };
  const fetchTheEditedRows = (
    index: string,
    rowData: any,
    type: string,
    value: any
  ) => {
    const allEditedFields = { ...editedRow };
    const allEditedIndex = Object.keys(allEditedFields);
    if (!allEditedIndex.includes(index)) {
      allEditedFields[index] = {
        area_from_to: rowData["area_from_to"],
        total_lights: rowData["total_lights"],
        total_poles: rowData["total_poles"],
      };
    }
    allEditedFields[index][type] = value?.target.value;
    setEditedRow(allEditedFields);
  };

  const sendLightControlUpdate = async (
    rowData: Record<string, any>,
    index: string
  ) => {
    setLoading(true);
    let createUpdateResponse = await createUpdateResource(
      "lights",
      editedRow[index],
      "update",
      rowData.controller_meterno
    );

    const response = await apiResponse(createUpdateResponse);
    setLoading(false);
    if (response) {
      getLightControlData();
      snackbar("success", response.message);
    }
    setLoading(false);
  };

  const handleTableActions = (
    type: string,
    index: string,
    rowData: any,
    value?: any
  ) => {
    fetchTheEditedRows(index, rowData, type, value);
    if (type === "Update") {
      sendLightControlUpdate(rowData, index);
    }
  };

  useEffect(() => {
    getLightControlData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <Card
        title="Light Control"
        className={classNames(commonStyles.card_wrapper, styles.sensors_card)}
      >
        <Spin spinning={loading}>
          <Table
            dataSource={lightControlList}
            columns={SUPER_ADMIN_LIGHT_CONTROL_COLUMN(handleTableActions)}
            pagination={false}
            className={classNames(commonStyles.table_wrap, styles.sensor_table)}
          />
        </Spin>
      </Card>
    </main>
  );
};
export default LightControl;
