import classNames from "classnames";
import { FaHome } from "react-icons/fa";
import { FaMoon } from "react-icons/fa6";
import { FiChevronDown, FiLogOut, FiSearch, FiUser } from "react-icons/fi";

import { Dropdown, MenuProps } from "antd";
import { Link, useNavigate } from "react-router-dom";
import commonStyles from "../../assets/styles/commonStyles.module.scss";
import { useAuthCheck } from "../../context/AuthCheckProvider";
import { useHeaderTab } from "../../context/HeaderTabProvider";
import { iconStylesProps } from "../../utils/helperFunctions";
import {
  HEADER_DATA,
  SUPER_ADMIN_HEADER_DATA,
} from "../../utils/webStaticData";
import FormFields from "../FormFields/FormFields";
import styles from "./Header.module.scss";
const Header = () => {
  const navigateTo = useNavigate();
  const userAuth = useAuthCheck();
  const { headerTab } = useHeaderTab();
  const profileSetting: MenuProps = {
    items: [
      {
        key: "profile",
        label: "Profile",
        icon: <FiUser {...iconStylesProps()} />,
        onClick: () =>
          navigateTo(
            `${
              userAuth?.userDetails?.role === "superadmin"
                ? "/superadmin/profile"
                : "/profile"
            }`
          ),
      },
      {
        key: "dashboard",
        label: "Dashboard",
        icon: <FaHome {...iconStylesProps()} />,
        onClick: () => navigateTo("/"),
      },
      {
        key: "logout",
        label: "Logout",
        icon: <FiLogOut {...iconStylesProps()} />,
        onClick: () => handleLogout(),
      },
    ],
  };

  const handleLogout = () => {
    userAuth?.loggedOut();
    navigateTo("/login");
  };

  return (
    <header className={styles.header}>
      <nav
        className={classNames(
          commonStyles.display_flex,
          commonStyles.display_a_center,
          commonStyles.gap16,
          styles.main_navbar
        )}
      >
        <div className={styles.logo_icon}>
          <img
            src={require("../../assets/images/energy-box.png")}
            alt="energy-box-logo"
          />
        </div>
        <FormFields
          $style_type="input"
          starticon={<FiSearch {...iconStylesProps()} />}
          placeholder="Search"
          width="19rem"
        />
        <FaMoon className={commonStyles.m_l_auto} />
        <Dropdown
          menu={profileSetting}
          trigger={["click"]}
          className={styles.profile_dropdown}
        >
          <div
            className={classNames(
              commonStyles.display_flex,
              commonStyles.display_a_center,
              commonStyles.gap6,
              styles.profile_wrap
            )}
          >
            <img
              src={
                userAuth?.userDetails?.profile_image
                  ? `${process.env.REACT_APP_BASE_URL}/${userAuth.userDetails.profile_image}`
                  : require("../../assets/images/default_user.jpg")
              }
              alt="user-profile-pic"
            />
            <p
              className={classNames(
                commonStyles.display_flex,
                commonStyles.display_column
              )}
            >
              <span>{userAuth?.userDetails?.name || "-"}</span>
              <span className={styles.roleName}>
                {userAuth?.userDetails?.role?.toLocaleUpperCase() || "-"}
              </span>
            </p>
            <FiChevronDown />
          </div>
        </Dropdown>
      </nav>
      <nav
        className={classNames(
          commonStyles.display_flex,
          commonStyles.display_a_center,
          styles.sub_navbar
        )}
      >
        {(userAuth?.userDetails?.role === "superadmin"
          ? SUPER_ADMIN_HEADER_DATA
          : HEADER_DATA("both", headerTab)
        ).map(
          (headerData) =>
            userAuth?.userDetails?.role &&
            (headerData.role.includes(userAuth?.userDetails?.role) ||
              headerData.role.includes(userAuth?.userDetails?.working_as)) && (
              <Link
                to={headerData.url}
                key={headerData.key}
                className={classNames(
                  commonStyles.display_flex,
                  commonStyles.display_a_center,
                  styles.navbar_item
                )}
              >
                {headerData.icon} {headerData.label}
              </Link>
            )
        )}
      </nav>
    </header>
  );
};

export default Header;
