import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.scss";
import AuthorizedRoutes from "./Routes/AuthorizedRoutes";
import { AuthCheckProvider } from "./context/AuthCheckProvider";
import { HeaderTabProvider } from "./context/HeaderTabProvider";
import { SnackBarProvider } from "./context/SnackBarProvider";
import ForgotPassword from "./pages/SharedComponents/ForgotPassword/ForgotPassword";
import Login from "./pages/SharedComponents/Login/Login";

function App() {
  return (
    <AuthCheckProvider>
      <Router>
        <SnackBarProvider>
          <HeaderTabProvider>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route element={<AuthorizedRoutes />} path="*" />
            </Routes>
          </HeaderTabProvider>
        </SnackBarProvider>
      </Router>
    </AuthCheckProvider>
  );
}

export default App;
