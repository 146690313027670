import { notification } from "antd";
import classNames from "classnames";
import { FiAlertCircle, FiCheckCircle, FiXCircle } from "react-icons/fi";
import commonStyles from "../../assets/styles/commonStyles.module.scss";
import styles from "./Snackbar.module.scss";
import { SnackBarTypes } from "../../utils/commonInterface";

const Snackbar = (type: SnackBarTypes, message: string) => {
  notification.open({
    message: (
      <div
        className={classNames(
          commonStyles.display_flex,
          commonStyles.display_a_center,
          commonStyles.gap_2,
          styles.snackbar_body
        )}
      >
        {type === "success" && <FiCheckCircle />}
        {type === "error" && <FiXCircle />}
        {type === "warning" && <FiAlertCircle />}

        <p>{message}</p>
      </div>
    ),
    duration: 3,
    className: classNames(styles.snackbar_wrapper, styles[type!]),
    style: { padding: 0 },
  });
};
export default Snackbar;
