import { Col, Form, Modal, Row } from "antd";
import { Rule } from "antd/es/form";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import {
  createUpdateResource,
  fetchAllResourcesList,
  fetchAllSuperAdminUserBasedData,
} from "../../../api/authorizeApi";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import FormFields from "../../../components/FormFields/FormFields";
import Loader from "../../../components/Loader/Loader";
import StyledButton from "../../../components/StyledButton/StyledButton";
import { useAuthCheck } from "../../../context/AuthCheckProvider";
import { useSnackBar } from "../../../context/SnackBarProvider";
import useApiResponse from "../../../customHooks/useApiResponse";
import { colors, iconStylesProps } from "../../../utils/helperFunctions";
import { DEVICE_TYPES_LIST } from "../../../utils/webStaticData";
import styles from "./Controllers.module.scss";
interface AddEditUsersProps {
  open: boolean;
  title: string;
  type: string;
  controllerDetails: Record<string, any>;
  onCancel: (refresh?: boolean) => void;
}
const AddEditControllers: React.FC<AddEditUsersProps> = ({
  open,
  title,
  type,
  controllerDetails,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [customersList, setCustomersList] = useState<Record<string, any>[]>([]);
  const [devicesList, setDevicesList] = useState<Record<string, any>[]>([]);
  const [locationList, setLocationList] = useState<Record<string, any>[]>([]);
  const [subLocationList, setSubLocationList] = useState<Record<string, any>[]>(
    []
  );
  const { apiResponse } = useApiResponse();
  const { snackbar } = useSnackBar();
  const userAuth = useAuthCheck();
  const userID = userAuth?.userDetails?.id;
  const emptyControllerParameter = {
    controller_name: null,
    controller_desc: null,
    controller_deviceid: null,
    controller_meterno: null,
    ...(type === "Edit" ? { controller_detail_id: "" } : {}),
  };

  useEffect(() => {
    const getFieldsMenuData = async () => {
      setLoading(true);
      const customerData = await apiResponse(
        await fetchAllSuperAdminUserBasedData("company")
      );
      const deviceData = await apiResponse(
        await fetchAllResourcesList("device")
      );

      setCustomersList(customerData.result || []);
      setDevicesList(deviceData.result || []);
      setLoading(false);
    };

    getFieldsMenuData();
    if (type === "Edit" && controllerDetails) {
      getLocationsList(controllerDetails?.company_id);
      getSubLocationsList(controllerDetails?.location_id, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLocationsList = async (company_id?: string | number) => {
    const currentCompanyId = company_id
      ? company_id
      : form.getFieldValue("company_id");
    if (currentCompanyId) {
      setLoading(true);
      if (!company_id) {
        form.setFieldsValue({ location_id: "", sublocation_id: "" });
      }
      const locationData = await apiResponse(
        await fetchAllSuperAdminUserBasedData(
          "locations_under_company",
          currentCompanyId
        )
      );
      setLocationList(locationData.locations || []);
      setLoading(false);
    }
  };

  const getSubLocationsList = async (
    locationId: string | number,
    onLoad?: boolean
  ) => {
    setLoading(true);
    if (!onLoad) {
      form.setFieldValue("sublocation_id", null);
    }
    const sublocationData = await apiResponse(
      await fetchAllSuperAdminUserBasedData("sublocations", locationId)
    );
    setSubLocationList(sublocationData.sublocations || []);
    setLoading(false);
  };

  const validation: Record<string, Rule[]> = {
    company_id: [{ required: true, message: "Please select customer" }],
    location_id: [{ required: true, message: "Please select location" }],
    sublocation_id: [{ required: true, message: "Please select sublocation" }],
    device_id: [{ required: true, message: "Please select device" }],
    device_type: [{ required: true, message: "Please select device type" }],
    controller_name: [
      { required: true, message: "Please enter controller device name" },
      {
        message: "Comma is not allowed",
        pattern: new RegExp(/^(?![\s\S]*,)[\s\S]*$/),
      },
    ],
    controller_desc: [
      { required: true, message: "Please enter controller description" },
      {
        message: "Comma is not allowed",
        pattern: new RegExp(/^(?![\s\S]*,)[\s\S]*$/),
      },
    ],
    controller_deviceid: [
      { required: true, message: "Please enter controller device ID" },
      {
        message: "Comma is not allowed",
        pattern: new RegExp(/^(?![\s\S]*,)[\s\S]*$/),
      },
    ],
    controller_meterno: [
      { required: true, message: "Please enter controller meter number" },
      {
        message: "Comma is not allowed",
        pattern: new RegExp(/^(?![\s\S]*,)[\s\S]*$/),
      },
    ],
  };

  const sendAddEditControllersRequest = async () => {
    const values = form.getFieldsValue();
    let payload: Record<string, any> = {
      company_id: +values["company_id"],
      device_id: +values["device_id"],
      device_type: values["device_type"],
      location_id: +values["location_id"],
      controller_details: JSON.stringify(values["controllerParameter"]),
      sublocation_id: +values["sublocation_id"],
      user_id: +userID!,
    };

    setLoading(true);
    let createUpdateResponse = await createUpdateResource(
      "controller",
      payload,
      type === "Edit" ? "update" : "create",
      type === "Edit" && controllerDetails.id
    );
    const response = await apiResponse(createUpdateResponse);
    setLoading(false);
    if (response) {
      handleCancel(true);
      snackbar("success", response.message);
    }
  };

  const setFieldsValues = () => {
    const controller_parameter = controllerDetails?.controllerdetails?.map(
      (controller: Record<string, any>) => {
        return {
          controller_name: controller?.controller_name || "",
          controller_desc: controller?.controller_desc || "",
          controller_deviceid: controller?.controller_deviceid || "",
          controller_meterno: controller?.controller_meterno || "",
          ...(type === "Edit" ? { controller_detail_id: controller?.id } : {}),
        };
      }
    );
    return {
      company_id: +controllerDetails?.company_id || "",
      location_id: +controllerDetails?.location_id || "",
      sublocation_id: +controllerDetails?.sublocation_id || "",
      device_id: +controllerDetails?.device_id || "",
      device_type: controllerDetails?.device_type || "",
      controllerParameter:
        controller_parameter && controller_parameter.length
          ? controller_parameter
          : [emptyControllerParameter],
    };
  };

  const handleReset = () => {
    if (controllerDetails && Object.keys(controllerDetails).length) {
      getLocationsList(controllerDetails?.company_id);
      getSubLocationsList(controllerDetails?.location_id, true);
      form.setFieldsValue(setFieldsValues());
    } else {
      form.resetFields();
    }
  };

  const handleCancel = (refreshList: boolean = false) => {
    onCancel(refreshList);
    form.resetFields();
  };
  return (
    <Modal
      className={classNames(commonStyles.modal_wrapper, commonStyles.wideModal)}
      footer={false}
      mask
      maskClosable={false}
      open={open}
      onCancel={() => handleCancel()}
      title={<h5>{title}</h5>}
      style={{ top: "1rem", width: "60vw" }}
    >
      {loading && <Loader />}
      <Form
        layout="vertical"
        form={form}
        onFinish={sendAddEditControllersRequest}
        initialValues={setFieldsValues()}
      >
        <div className="modal_form">
          <Row gutter={[16, 0]}>
            <Col md={12} xs={24}>
              <FormFields
                name="company_id"
                label="Select Customer"
                placeholder="Choose Customer"
                $style_type="select"
                showSearch={true}
                menuItem={customersList}
                valueKey="id"
                labelKey="name"
                rules={validation["company_id"]}
                onChangeSelect={() => getLocationsList()}
              />
            </Col>
            <Col md={12} xs={24}>
              <FormFields
                name="location_id"
                label="Select Location"
                placeholder="Choose Location"
                $style_type="select"
                showSearch={true}
                menuItem={locationList}
                valueKey="id"
                labelKey="location_name"
                rules={validation["location_id"]}
                onChangeSelect={(id) => getSubLocationsList(id)}
              />
            </Col>
            <Col md={12} xs={24}>
              <FormFields
                name="sublocation_id"
                label="Select Sublocation"
                placeholder="Choose Sublocation"
                $style_type="select"
                showSearch={true}
                menuItem={subLocationList}
                valueKey="id"
                labelKey="location_name"
                rules={validation["sublocation_id"]}
              />
            </Col>
            <Col md={12} xs={24}>
              <FormFields
                name="device_id"
                label="Select Device"
                placeholder="Choose Device"
                $style_type="select"
                showSearch={true}
                menuItem={devicesList}
                valueKey="id"
                labelKey="device_name"
                rules={validation["device_id"]}
              />
            </Col>
            <Col md={12} xs={24}>
              <FormFields
                name="device_type"
                label="Device Type"
                placeholder="Choose Device Type"
                $style_type="select"
                showSearch={true}
                menuItem={DEVICE_TYPES_LIST}
                rules={validation["device_type"]}
              />
            </Col>
          </Row>

          <h6 className={commonStyles.m_t_3}>Controller Details</h6>
          <Form.List name="controllerParameter">
            {(fields, { add, remove }) => (
              <>
                {type !== "Show" && (
                  <StyledButton
                    label="Add"
                    onClick={() => add(emptyControllerParameter)}
                    margin="1rem 0"
                  />
                )}
                {fields?.map(({ key, name, ...restField }) => (
                  <>
                    <h6
                      className={classNames(
                        styles.controllerDetail,
                        commonStyles.m_b_0_5,
                        commonStyles.m_t_1_5,
                        commonStyles.display_flex,
                        commonStyles.display_center_spacebetween,
                        commonStyles.gap_2
                      )}
                    >
                      Controller Detail: {name + 1}
                      {fields.length !== 1 ? (
                        <FiTrash2
                          {...iconStylesProps({
                            fontSize: "1.1rem",
                            width: "1.3rem",
                            color: colors("red2"),
                            cursor: "pointer",
                            margin: "0",
                          })}
                          onClick={() => remove(name)}
                        />
                      ) : (
                        <></>
                      )}
                    </h6>
                    <Row gutter={[16, 0]}>
                      <Col md={12} xs={24}>
                        <FormFields
                          key={`Controller-Device-${key}`}
                          name={[name, "controller_name"]}
                          label="Controller Device"
                          placeholder="Enter controller Device"
                          rules={validation["controller_name"]}
                        />
                      </Col>
                      <Col md={12} xs={24}>
                        <FormFields
                          key={`Controller-Description-${key}`}
                          name={[name, "controller_desc"]}
                          label="Controller Description"
                          placeholder="Enter controller Description"
                          rules={validation["controller_desc"]}
                        />
                      </Col>
                      <Col md={12} xs={24}>
                        <FormFields
                          key={`Controller-Device-ID-${key}`}
                          name={[name, "controller_deviceid"]}
                          label="Controller Device ID"
                          placeholder="Enter controller Device ID"
                          rules={validation["controller_deviceid"]}
                        />
                      </Col>
                      <Col md={12} xs={24}>
                        <FormFields
                          key={`Controller-Meter-NO-${key}`}
                          name={[name, "controller_meterno"]}
                          label="Controller Meter NO"
                          placeholder="Enter controller Meter NO"
                          rules={validation["controller_meterno"]}
                        />
                      </Col>
                    </Row>
                  </>
                ))}
              </>
            )}
          </Form.List>
        </div>
        <div className="modal_footer">
          {type !== "Show" && (
            <>
              <StyledButton
                label="Reset"
                $style_type="ghost"
                onClick={handleReset}
              />
              <StyledButton
                htmlType="submit"
                label="Submit"
                disabled={loading}
              />
            </>
          )}
          <StyledButton
            label="Close"
            $style_type="secondary"
            onClick={() => handleCancel()}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default AddEditControllers;
