import { Card, Empty, List } from "antd";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import classNames from "classnames";
import { Doughnut } from "react-chartjs-2";

import { useEffect, useState } from "react";
import commonStyles from "../../../../../assets/styles/commonStyles.module.scss";
import { hexToRgbA } from "../../../../../utils/helperFunctions";
import { COLORS } from "../../../../../utils/webStaticData";
import styles from "../../Dashboard.module.scss";

ChartJS.register(ArcElement, Tooltip, Legend);

interface ProductTypeProps {
  productsList: Record<string, any>[];
}
const ProductType: React.FC<ProductTypeProps> = ({ productsList }) => {
  const [mappedProductList, setMappedProductList] = useState<
    Record<string, any>[]
  >([]);

  useEffect(() => {
    let productWithColor =
      productsList?.map((product, index) => ({
        ...product,
        color: COLORS[index % 8][(Math.floor(index / 8) % 8) + 2],
      })) || [];
    setMappedProductList(productWithColor);
  }, [productsList]);

  const data = {
    labels:
      mappedProductList?.map(
        (product: Record<string, any>) => product?.device_name
      ) || [],
    datasets: [
      {
        label: "consumption data",
        data:
          mappedProductList?.map(
            (product: Record<string, any>) => product?.count
          ) || [],
        backgroundColor:
          mappedProductList?.map((product: Record<string, any>) =>
            hexToRgbA(product?.color)
          ) || [],
        hoverOffset: 4,
      },
    ],
  };

  return (
    <Card
      title="Types of product"
      className={classNames(
        commonStyles.card_wrapper,
        styles.product_card,
        !productsList.length && styles.no_data_card
      )}
    >
      {productsList && productsList.length ? (
        <>
          <Doughnut data={data} className={styles.product_doughnut} />
          <List
            className={styles.product_list}
            itemLayout="horizontal"
            dataSource={mappedProductList}
            renderItem={(item, index) => (
              <List.Item className={styles.product_list_items}>
                <span className={styles.product_list_items_title}>
                  {item.device_name}
                </span>
                <span
                  className={classNames(styles.product_list_items_value)}
                  style={{ backgroundColor: item.color }}
                >
                  {item.count}
                </span>
              </List.Item>
            )}
          />
        </>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Card>
  );
};

export default ProductType;
