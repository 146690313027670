import { Spin, Table } from "antd";
import classNames from "classnames";
import { useState } from "react";
import { userBasedResourceActions } from "../../../api/authorizeApi";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import { useSnackBar } from "../../../context/SnackBarProvider";
import useApiResponse from "../../../customHooks/useApiResponse";
import { ADMIN_CUSTOMER_CLOCK_CALIBRATION_COLUMN } from "../../../utils/webTableConfig";
import EditAutomatedTime from "./EditAutomatedTime";

interface CalibrationProps {
  calibrations: Record<string, any>[];
  isRefreshed: (refresh: boolean) => void;
  mode: string;
}

const ClockCalibration: React.FC<CalibrationProps> = ({
  calibrations,
  isRefreshed,
  mode,
}) => {
  const [selectedController, setSelectedController] = useState<
    Record<string, any>
  >({});
  const { apiResponse } = useApiResponse();
  const [loading, setLoading] = useState(false);
  const { snackbar } = useSnackBar();
  const [showEditTime, setShowEditTime] = useState(false);

  const handleEditTime = (refresh?: boolean) => {
    setShowEditTime((prev) => !prev);
    if (refresh) {
      isRefreshed(refresh);
    }
  };

  const handleControllerAutomate = async (
    checked: boolean,
    controller_id?: string | number
  ) => {
    setLoading(true);
    let response = await apiResponse(
      await userBasedResourceActions("admin/automate_on_off", "post", null, {
        id: controller_id,
        automate_status: checked ? "1" : "0",
      })
    );
    setLoading(false);
    if (response) {
      snackbar("success", response.message);
      isRefreshed(true);
    }
  };
  const handleTableActions = (
    type: string,
    rowData: any,
    checked: boolean = false
  ) => {
    setSelectedController(rowData || {});
    switch (type) {
      case "Edit":
        handleEditTime();
        break;
      case "controller_automate":
        handleControllerAutomate(checked, rowData?.id);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Spin spinning={loading}>
        <Table
          dataSource={calibrations}
          columns={ADMIN_CUSTOMER_CLOCK_CALIBRATION_COLUMN(
            handleTableActions,
            mode
          )}
          pagination={false}
          className={classNames(commonStyles.table_wrap)}
        />
      </Spin>
      {showEditTime && (
        <EditAutomatedTime
          open={showEditTime}
          timings={selectedController}
          onCancel={(refresh) => handleEditTime(refresh)}
        />
      )}
    </>
  );
};
export default ClockCalibration;
