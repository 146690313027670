import {
  CategoryScale,
  Chart as ChartJS,
  Chart as ChartType,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-date-fns";
import zoomPlugin from "chartjs-plugin-zoom";
import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

const LineChartWithZoom = ({ data, resetZoom }: any) => {
  const [allFilteredData, setAllFilteredData] = useState(
    Cookies.get("energy_box_f_d")
      ? JSON.parse(Cookies.get("energy_box_f_d") || "")
      : {}
  );
  const chartRef = useRef<ChartJS>(null);
  const [zoomOptions] = useState({
    // pan: {
    //   enabled: true,
    //   mode: "xy",
    // },
    zoom: {
      // wheel: {
      //   enabled: true,
      //   speed: 0.01,
      // },
      // pinch: {
      //   enabled: true,
      // },
      drag: {
        enabled: true,
        mode: "x",
      },
      // mode: "xy",
      onZoom: ({ chart }: { chart: ChartType<"line"> }) => {
        updateXAxisDisplay(chart);
      },
    },
  });

  const updateXAxisDisplay = (chart: ChartType<"line">) => {
    const meta = chart.getDatasetMeta(0);
    const xScale = meta.xAxisID ? chart.scales[meta.xAxisID] : undefined;

    if (xScale && xScale.min && xScale.max) {
      const minDate = new Date(xScale.min);
      const maxDate = new Date(xScale.max);
      const isSameDay =
        minDate.getDate() === maxDate.getDate() &&
        minDate.getMonth() === maxDate.getMonth() &&
        minDate.getFullYear() === maxDate.getFullYear();
      if (isSameDay) {
        chart.options.scales!.x = {
          type: "time",
          time: {
            unit: "hour",
            displayFormats: {
              hour: "HH:mm:ss",
            },
          },
        };
      } else {
        chart.options.scales!.x = {
          type: "time",
          time: {
            unit: "day",
            displayFormats: {
              day: "yyyy-MM-dd",
            },
          },
        };
      }
      chart.update();
    }
  };

  const options = {
    scales: {
      x: {
        type: "time",
        time: {
          unit:
            allFilteredData.start_date !== allFilteredData.end_date
              ? "day"
              : "hour",
          displayFormats: {
            ...(allFilteredData.start_date !== allFilteredData.end_date
              ? { day: "yyyy-MM-dd" }
              : { hour: "HH:mm:ss" }),
          },
        },
      },
      y: {
        min: 0,
        ticks: {
          stepSize: 50,
        },
      },
    },
    plugins: {
      zoom: zoomOptions,
    },
    elements: {
      point: {
        borderWidth: 0,
        radius: 0,
      },
    },
  };

  useEffect(() => {
    const handleResetZoom = () => {
      if (resetZoom && chartRef && chartRef.current) {
        chartRef.current?.resetZoom();
      }
    };
    handleResetZoom();
  }, [resetZoom]);

  useEffect(() => {
    const filterDataFromCookies = Cookies.get("energy_box_f_d")
      ? JSON.parse(Cookies.get("energy_box_f_d") || "")
      : {};

    setAllFilteredData(filterDataFromCookies);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Cookies.get("energy_box_f_d")]);

  //@ts-ignore
  return <Line ref={chartRef} data={data} options={options} />;
};

export default LineChartWithZoom;
