import { Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { fetchAllUserBasedData } from "../../../../api/authorizeApi";
import commonStyles from "../../../../assets/styles/commonStyles.module.scss";
import { useAuthCheck } from "../../../../context/AuthCheckProvider";
import useApiResponse from "../../../../customHooks/useApiResponse";
import ConsumptionOverview from "./DasboardIOTComponents/ConsumptionOverview";
import ProductType from "./DasboardIOTComponents/ProductTypes";
import SensorsSection from "./DasboardIOTComponents/SensorsSection";
import SummarySection from "./DasboardIOTComponents/SummarySection";

const IotUserDashboard = () => {
  const userAuth = useAuthCheck();
  const userID = userAuth?.userDetails?.id;
  const { apiResponse } = useApiResponse();
  const [loading, setLoading] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [sensorsList, setSensorsList] = useState([]);
  const [summaryValues, setSummaryValues] = useState(Array(4).fill("-"));
  useEffect(() => {
    const fetchDashboardData = async () => {
      if (userID) {
        setLoading(true);
        const getPreviousKwh = await apiResponse(
          await fetchAllUserBasedData("getpreviousdaykwh", userID!)
        );
        const getTodayKwh = await apiResponse(
          await fetchAllUserBasedData("gettodaykwh", userID!)
        );
        const sensorCount = await apiResponse(
          await fetchAllUserBasedData(
            "get_active_inactive_sensor_count",
            null,
            { user_id: userID }
          )
        );
        const sensorList = await apiResponse(
          await fetchAllUserBasedData("allSensersByUser", null, {
            user_id: userID,
          })
        );

        const consumptionCost = await apiResponse(
          await fetchAllUserBasedData("consumption_cost", userID!)
        );

        const productsResponse = await apiResponse(
          await fetchAllUserBasedData("total_products", null, {
            user_id: userID,
          })
        );

        setProductsList(productsResponse?.products || []);

        setSensorsList(
          sensorList?.sensors && typeof sensorList.sensors !== "string"
            ? sensorList.sensors
            : []
        );
        setSummaryValues((prev) => {
          const newArray = [...prev];
          newArray[0] = sensorCount?.sensors?.total || "0";
          newArray[1] = getPreviousKwh?.kwh || "0";
          newArray[2] = getTodayKwh?.kwh || "0";
          newArray[3] = consumptionCost?.consumption_cost || "0";
          return newArray;
        });
        setLoading(false);
      }
    };
    fetchDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID]);

  return (
    <Spin spinning={loading}>
      <SummarySection values={summaryValues} />
      <section className={commonStyles.m_b_1_5}>
        <Row gutter={[16, 16]}>
          <Col md={16} xs={24}>
            <ConsumptionOverview />
          </Col>
          <Col md={8} xs={24}>
            <ProductType productsList={productsList} />
          </Col>
        </Row>
      </section>
      <SensorsSection sensorsList={sensorsList || []} />
    </Spin>
  );
};

export default IotUserDashboard;
