import { ReactNode, createContext, useContext } from "react";
import SnackBar from "../components/Snackbar/Snackbar";
import { SnackBarTypes } from "../utils/commonInterface";

interface SnackbarProviderProps {
  children: ReactNode;
}

const SnackBarContext = createContext({
  snackbar: (type: SnackBarTypes, message: string) => {},
});

export const SnackBarProvider = ({ children }: SnackbarProviderProps) => {
  const snackbar = (type: SnackBarTypes, message: string) => {
    if (type && message) {
      SnackBar(type, message);
    }
  };

  const snackbarValue = {
    snackbar,
  };
  return (
    <SnackBarContext.Provider value={snackbarValue}>
      {children}
    </SnackBarContext.Provider>
  );
};

export const useSnackBar = () => {
  return useContext(SnackBarContext);
};
