import Cookies from "js-cookie";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { fetchAllUserBasedData } from "../api/authorizeApi";
import useApiResponse from "../customHooks/useApiResponse";
interface AuthCheckProviderProps {
  children: ReactNode;
}
interface AuthCheckContextProps {
  userDetails?: Record<string, any> | null;
  isAuthorized: () => boolean;
  loggedIn: (data: Record<string, any>) => void;
  loggedOut: () => void;
  updateUserDetails: (data: Record<string, any>) => void;
}
const AuthCheckContext = createContext<AuthCheckContextProps | null>(null);

export const AuthCheckProvider = ({ children }: AuthCheckProviderProps) => {
  const { apiResponse } = useApiResponse();
  const [authorized, setAuthorized] = useState(true);
  const [userDetails, setUserDetails] = useState<Record<string, any> | null>(
    null
  );

  const getCurrentUserDetails = async () => {
    const user = await apiResponse(await fetchAllUserBasedData("user-details"));
    if (user) {
      setUserDetails(user["user-details"] || []);
      setAuthorized(true);
    } else {
      loggedOut();
    }
  };

  useEffect(() => {
    const token = Cookies.get("energy_box_t");
    if (token) {
      setAuthorized(true);
      getCurrentUserDetails();
    } else {
      loggedOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loggedIn = (data: Record<string, any>) => {
    if (data) {
      Cookies.set("energy_box_t", data.token);
      setAuthorized(true);
      setUserDetails(data.user);
    }
  };
  const loggedOut = () => {
    setAuthorized(false);
    setUserDetails(null);
    Cookies.remove("energy_box_t");
  };

  const isAuthorized = () => authorized;

  const updateUserDetails = (data: Record<string, any>) => {
    setUserDetails(data || null);
  };

  return (
    <AuthCheckContext.Provider
      value={{
        userDetails,
        isAuthorized,
        loggedIn,
        loggedOut,
        updateUserDetails,
      }}
    >
      {children}
    </AuthCheckContext.Provider>
  );
};

export const useAuthCheck = () => {
  return useContext(AuthCheckContext);
};
