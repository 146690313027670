import { Spin, Table } from "antd";
import classNames from "classnames";
import { useState } from "react";
import { userBasedResourceActions } from "../../../api/authorizeApi";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import { useSnackBar } from "../../../context/SnackBarProvider";
import useApiResponse from "../../../customHooks/useApiResponse";
import { ADMIN_CUSTOMER_LDR_CALIBRATION_COLUMN } from "../../../utils/webTableConfig";

interface CalibrationProps {
  calibrations: Record<string, any>[];
  mode?: string;
  sendMode: (mode: string) => void;
}

const LDRCalibration: React.FC<CalibrationProps> = ({
  calibrations,
  mode,
  sendMode,
}) => {
  const { apiResponse } = useApiResponse();
  const [isStartValueEnabled, setIsStartValueEnabled] = useState(false);
  const [isOffValueEnabled, setIsOffValueEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [offValue, setOffValue] = useState();
  const [startValue, setStartValue] = useState();
  const { snackbar } = useSnackBar();

  const handleCalibration = async (id: string | number) => {
    if (startValue) {
      let startValueResponse = await apiResponse(
        await userBasedResourceActions(
          "admin/update_ldr_start_calibrate_value",
          "post",
          null,
          {
            ldr_start_value: startValue,
            id: id,
          }
        )
      );
      setLoading(false);
      if (startValueResponse) {
        snackbar("success", startValueResponse.message);
      }
    }

    if (offValue) {
      let offValueResponse = await apiResponse(
        await userBasedResourceActions(
          "admin/update_ldr_stop_calibrate_value",
          "post",
          null,
          {
            ldr_stop_value: offValue,
            id: id,
          }
        )
      );
      setLoading(false);
      if (offValueResponse) {
        snackbar("success", offValueResponse.message);
      }
    }
  };

  const handleTableActions = (type: string, value: any, checked?: boolean) => {
    switch (type) {
      case "calibrateOnOff1":
        setIsStartValueEnabled(!!checked);
        break;
      case "calibrateOnOff2":
        setIsOffValueEnabled(!!checked);
        break;
      case "ldrStartValue":
        setStartValue(value);
        break;
      case "ldrOffValue":
        setOffValue(value);
        break;
      case "calibration":
        handleCalibration(value.id);
        break;
      default:
        break;
    }
  };

  return (
    <Spin spinning={loading}>
      <Table
        dataSource={calibrations}
        columns={ADMIN_CUSTOMER_LDR_CALIBRATION_COLUMN(
          handleTableActions,
          mode || "ldr",
          isStartValueEnabled,
          isOffValueEnabled
        )}
        pagination={false}
        className={classNames(commonStyles.table_wrap)}
      />
    </Spin>
  );
};
export default LDRCalibration;
