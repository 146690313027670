import { Tooltip } from "antd";
import styled from "styled-components";
import { colors } from "../../utils/helperFunctions";
import { styledHTMLComponentsProps } from "../../utils/commonInterface";

interface StyledButtonProps {
  className?: string;
  disabled?: boolean;
  htmlType?: "button" | "submit" | "reset" | undefined;
  label?: string;
  prefixIcon?: JSX.Element;
  sufixIcon?: JSX.Element;
  tooltip?: string;
}

const bgColor = (type: string, isHover?: boolean) => {
  switch (type) {
    case "primary":
      return isHover ? colors("blue6") : colors("blue5");
    case "success":
      return isHover ? colors("green2") : colors("green1");
    case "danger":
      return isHover ? colors("red2") : colors("red1");
    case "secondary":
      return isHover ? colors("grey9") : colors("grey7");
    case "ghost":
      return isHover ? colors("grey8") : colors("grey10");
    default:
      return "transparent";
  }
};

const checkBoxShadow = (type: string, isHover?: boolean) => {
  let boxShadow = "1px 2px 6px 0px ";
  switch (type) {
    case "primary":
      return isHover ? boxShadow + colors("green26") : "none";
    case "success":
      return isHover ? boxShadow + colors("grey12") : "none";
    case "danger":
      return isHover ? boxShadow + colors("red8") : "none";
    default:
      return "none";
  }
};

const textColor = (type: string) => {
  switch (type) {
    case "ghost":
      return colors("black");
    default:
      return colors("white");
  }
};

const disabledBgColor = (type: string) => {
  switch (type) {
    case "primary":
      return "#DCE8F1";
    case "success":
      return "#DEF1EB";
    case "danger":
      return "#EEDADA";
    case "secondary":
      return "#E6E8EB";
    case "ghost":
      return colors("transparent");
    default:
      return "transparent";
  }
};

const CustomButton = styled.button<
  styledHTMLComponentsProps<HTMLButtonElement>
>`
  align-items: center;
  background-color: ${(props) =>
    props.$bg_color || bgColor(props.$style_type || "primary")};
  border: 1px solid
    ${(props) => props.$border_color || bgColor(props.$style_type || "primary")};
  border-radius: ${(props) => props.radius || "10px"};
  color: ${(props) => props.color || textColor(props.$style_type || "primary")};
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  gap: 8px;
  height: ${(props) => props.height || "fit-content"};
  padding: ${(props) => props.padding || "12px 20px"};
  justify-content: center;
  line-height: 20px;
  width: ${(props) => props.width || "auto"};
  z-index: ${(props) => props.$z_index || "1"};
  margin: ${(props) => props.margin || "0"};
  &:hover {
    background-color: ${(props) =>
      props.$hv_bg_color || bgColor(props.$style_type || "primary", true)};
    border: 1px solid
      ${(props) =>
        props.$hv_border_color ||
        bgColor(props.$style_type || "primary", true)};
    box-shadow: ${(props) =>
      props.$hv_box_shadow ||
      checkBoxShadow(props.$style_type || "primary", true)};
    color: ${(props) =>
      props.$hv_color || textColor(props.$style_type || "primary")};
  }
  &:disabled {
    background-color: ${(props) =>
      disabledBgColor(props.$style_type || "primary")};
    color: #8f8f8f;
    border-color: ${(props) => disabledBgColor(props.$style_type || "primary")};
  }
`;

const StyledButton: React.FC<
  styledHTMLComponentsProps<HTMLButtonElement> & StyledButtonProps
> = ({
  className,
  disabled = false,
  htmlType,
  label,
  onClick,
  prefixIcon,
  sufixIcon,
  tooltip,
  ...props
}) => {
  return (
    <Tooltip title={tooltip}>
      <CustomButton
        {...props}
        onClick={onClick}
        disabled={disabled}
        type={htmlType ? htmlType : "button"}
        className={className}
      >
        {prefixIcon && prefixIcon}
        {label && label}
        {sufixIcon && sufixIcon}
      </CustomButton>
    </Tooltip>
  );
};

export default StyledButton;
