import { Col, Form, Modal, Row } from "antd";
import { Rule } from "antd/es/form";
import classNames from "classnames";
import dayjs from "dayjs";
import { useState } from "react";
import { userBasedResourceActions } from "../../../api/authorizeApi";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import FormFields from "../../../components/FormFields/FormFields";
import Loader from "../../../components/Loader/Loader";
import StyledButton from "../../../components/StyledButton/StyledButton";
import { useSnackBar } from "../../../context/SnackBarProvider";
import useApiResponse from "../../../customHooks/useApiResponse";
import { arrayToCommaSeperatedString } from "../../../utils/helperFunctions";
import { DAYS } from "../../../utils/webStaticData";
interface EditAutomatedTimeProps {
  open: boolean;
  timings: Record<string, any>;
  onCancel: (refresh?: boolean) => void;
}
const EditAutomatedTime: React.FC<EditAutomatedTimeProps> = ({
  open,
  timings,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { apiResponse } = useApiResponse();
  const { snackbar } = useSnackBar();

  const validation: Record<string, Rule[]> = {
    days: [{ required: true, message: "Please select day" }],
    start_time: [{ required: true, message: "Please select start time" }],
    end_time: [{ required: true, message: "Please select end time" }],
  };

  const handleReset = () => {
    if (timings && Object.keys(timings).length) {
      form.setFieldsValue(setFieldsValues());
    } else {
      form.resetFields();
    }
  };

  const sendEditAutomatedTime = async () => {
    setLoading(true);
    const values = form.getFieldsValue();

    let payload: Record<string, any> = {
      days: arrayToCommaSeperatedString(values.days, false),
      start_hr: values.start_time?.$H,
      start_min: values.start_time?.$m,
      end_hr: values.end_time?.$H,
      end_min: values.end_time?.$m,
      id: timings.id,
    };

    let createUpdateResponse = await userBasedResourceActions(
      "add_automate_time",
      "post",
      null,
      payload
    );
    const response = await apiResponse(createUpdateResponse);
    setLoading(false);
    if (response) {
      onCancel(true);
      form.resetFields();
      snackbar("success", response.message);
    }
  };

  const setFieldsValues = () => {
    const times = timings ? JSON.parse(timings.automate_time) : {};
    let timeFrom, timeTo;
    if (times?.start_time) {
      timeFrom = dayjs()
        .set("hour", times.start_time?.split(":")[0])
        .set("minute", times.start_time?.split(":")[1]);
    }
    if (times?.end_time) {
      timeTo = dayjs()
        .set("hour", times.end_time?.split(":")[0])
        .set("minute", times.end_time?.split(":")[1]);
    }
    return {
      days: times?.days?.[0]?.split(",") || [],
      start_time: times?.start_time ? timeFrom : "",
      end_time: times?.end_time ? timeTo : "",
    };
  };

  return (
    <Modal
      className={classNames(commonStyles.modal_wrapper)}
      footer={false}
      mask
      maskClosable={false}
      open={open}
      onCancel={() => onCancel()}
      title={<h5>Edit Time</h5>}
      style={{ top: "1rem" }}
    >
      {loading && <Loader />}
      <Form
        layout="vertical"
        form={form}
        onFinish={sendEditAutomatedTime}
        initialValues={setFieldsValues()}
      >
        <div className="modal_form">
          <FormFields
            name="days"
            label="On Days"
            placeholder="Select Days"
            $style_type="select"
            selectType="multiple"
            menuItem={DAYS}
            rules={validation["days"]}
          />
          <Row gutter={[16, 0]}>
            <Col md={12} xs={24}>
              <FormFields
                name="start_time"
                label="Start Time"
                $style_type="time-picker"
                rules={validation["start_time"]}
              />
            </Col>
            <Col md={12} xs={24}>
              <FormFields
                name="end_time"
                label="End Time"
                $style_type="time-picker"
                rules={validation["end_time"]}
              />
            </Col>
          </Row>
        </div>
        <div className="modal_footer">
          <StyledButton
            label="Reset"
            $style_type="ghost"
            onClick={handleReset}
          />
          <StyledButton htmlType="submit" label="Submit" disabled={loading} />
          <StyledButton
            label="Close"
            $style_type="secondary"
            onClick={() => onCancel()}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default EditAutomatedTime;
