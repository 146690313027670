import { Card, Form } from "antd";
import { InputOTP } from "antd-input-otp";
import { Rule } from "antd/es/form";
import Countdown from "antd/es/statistic/Countdown";
import classNames from "classnames";
import { useState } from "react";
import { FaClockRotateLeft } from "react-icons/fa6";
import { FiArrowLeft, FiEye, FiEyeOff } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import {
  forgotPasswordResetPassword,
  forgotPasswordSendEmail,
  forgotPasswordVerifyOtp,
} from "../../../api/unAuthorizeApi";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import FormFields from "../../../components/FormFields/FormFields";
import StyledButton from "../../../components/StyledButton/StyledButton";
import { useSnackBar } from "../../../context/SnackBarProvider";
import useApiResponse from "../../../customHooks/useApiResponse";
import {
  colors,
  iconStylesProps,
  validatePassword,
} from "../../../utils/helperFunctions";
import styles from "./ForgotPassword.module.scss";

const ForgotPassword = () => {
  const { apiResponse } = useApiResponse();
  const [form] = Form.useForm();
  const navigateTo = useNavigate();
  const [isResendOtp, setIsResendOtp] = useState(false);
  const { snackbar } = useSnackBar();
  const [step, setStep] = useState("email");
  const validation: Record<string, Rule[]> = {
    email: [
      { required: true, message: "Please enter the email ID" },
      { type: "email", message: "Please enter valid email ID" },
    ],
    otp: [{ required: true, message: "Please enter the otp" }],
    password: [
      { required: true, message: "Please enter your password" },
      { validator: validatePassword },
    ],
    confirm_password: [
      { required: true, message: "Please confirm your password" },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue("password") === value) {
            return Promise.resolve();
          }
          return Promise.reject("The two passwords do not match!");
        },
      }),
    ],
  };
  const sendEmailAddress = async () => {
    const values = form.getFieldsValue();
    const sendEmailResponse = await apiResponse(
      await forgotPasswordSendEmail(values)
    );
    if (sendEmailResponse) {
      setStep("otp");
      snackbar("success", sendEmailResponse.message);
    }
  };
  const sendOTPToVerify = async () => {
    const payload = {
      email: form.getFieldValue("email"),
      otp: form.getFieldValue("otp")?.join(""),
    };
    const verifyOtpResponse = await apiResponse(
      await forgotPasswordVerifyOtp(payload)
    );
    if (verifyOtpResponse) {
      setStep("password");
      snackbar("success", verifyOtpResponse.message);
    }
  };

  const sendNewPassword = async () => {
    const payload = {
      email: form.getFieldValue("email"),
      password: form.getFieldValue("password"),
      confirm_password: form.getFieldValue("confirm_password"),
    };
    const sendNewPasswordResponse = await apiResponse(
      await forgotPasswordResetPassword(payload)
    );
    if (sendNewPasswordResponse) {
      setStep("passwordUpdated");
      snackbar("success", sendNewPasswordResponse.message);
    }
  };

  return (
    <main
      className={classNames(
        commonStyles.display_flex,
        commonStyles.display_j_center
      )}
    >
      <Card className={classNames(styles.forgot_password_card)}>
        <StyledButton
          $style_type="ghost"
          prefixIcon={<FiArrowLeft />}
          label="Back to Login"
          margin="0 0 1rem"
          padding="0"
          $border_width="0"
          $hv_bg_color="transparent"
          $hv_border_color="transparent"
          $hv_color={colors("blue1")}
          onClick={() => navigateTo("/login")}
        />
        <FaClockRotateLeft
          {...iconStylesProps({
            fontSize: "6.25rem",
            color: colors("blue5"),
            margin: "0 auto 3rem",
          })}
          className={commonStyles.display_block}
        />
        {step === "email" && (
          <>
            <h4 className={commonStyles.m_b_0_5}>Forgot Password?</h4>
            <p className={commonStyles.m_b_1_5}>
              Enter your registered email ID to reset the password
            </p>
            <Form form={form} layout="vertical" onFinish={sendEmailAddress}>
              <FormFields
                name="email"
                $style_type="input"
                label="Email ID"
                placeholder="jhon@placeholder.com"
                rules={validation["email"]}
              />
              <StyledButton
                htmlType="submit"
                label="Send"
                width="100%"
                margin="1.5rem 0 0.5rem "
              />
            </Form>
          </>
        )}

        {step === "otp" && (
          <>
            <h4 className={commonStyles.m_b_0_5}>Verify OTP</h4>
            <p className={commonStyles.m_b_1_5}>
              An OTP has been sent to <b>{form.getFieldValue("email")}.</b>
            </p>
            <Form form={form} layout="vertical" onFinish={sendOTPToVerify}>
              <Form.Item name="otp" rules={validation["otp"]}>
                <InputOTP inputType="numeric" />
              </Form.Item>
              <StyledButton
                htmlType="submit"
                label="Verify"
                width="100%"
                margin="1.5rem 0 0.5rem "
              />
              {isResendOtp ? (
                <p className={styles.resend_otp}>
                  Didn't received the verification OTP?
                  <span onClick={() => setIsResendOtp(false)}>Resend OTP</span>
                </p>
              ) : (
                <div
                  className={classNames(
                    commonStyles.display_flex,
                    commonStyles.display_a_center
                  )}
                >
                  <span className={styles.resend_otp}>Time Remaining:</span>
                  <Countdown
                    format="mm:ss"
                    onFinish={() => setIsResendOtp(true)}
                    value={Date.now() + 5 * 60 * 1000}
                    valueStyle={{
                      fontSize: "13px",
                      fontWeight: 500,
                      marginLeft: "0.2rem",
                      color: colors("blue7"),
                    }}
                  />
                </div>
              )}
            </Form>
          </>
        )}

        {step === "password" && (
          <>
            <h4 className={commonStyles.m_b_0_5}>Set New Password</h4>
            <p className={commonStyles.m_b_1_5}>Enter your new password.</p>
            <Form form={form} layout="vertical" onFinish={sendNewPassword}>
              <FormFields
                $style_type="password"
                name="password"
                label="New Password"
                placeholder="Enter your new password"
                iconRender={(visible) => (visible ? <FiEye /> : <FiEyeOff />)}
                type="password"
                rules={validation["password"]}
              />

              <FormFields
                $style_type="password"
                name="confirm_password"
                label="Confirm Password"
                placeholder="Confirm your new password"
                iconRender={(visible) => (visible ? <FiEye /> : <FiEyeOff />)}
                type="password"
                rules={validation["confirm_password"]}
              />
              <StyledButton
                htmlType="submit"
                label="Send"
                width="100%"
                margin="1.5rem 0 0.5rem "
              />
            </Form>
          </>
        )}

        {step === "passwordUpdated" && (
          <>
            <h4 className={commonStyles.m_b_0_5}>Password Reset Successful</h4>
            <p className={commonStyles.m_b_1_5}>
              You have successfully reset the password.
            </p>
          </>
        )}
      </Card>
    </main>
  );
};

export default ForgotPassword;
