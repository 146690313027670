import { Card, Col, Form, Row, Spin } from "antd";
import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";
import {
  fetchAllUserBasedData,
  userBasedResourceActions,
} from "../../../api/authorizeApi";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import FormFields from "../../../components/FormFields/FormFields";
import StyledButton from "../../../components/StyledButton/StyledButton";
import { useAuthCheck } from "../../../context/AuthCheckProvider";
import { useSnackBar } from "../../../context/SnackBarProvider";
import useApiResponse from "../../../customHooks/useApiResponse";

const AdditionalSettings = () => {
  const userAuth = useAuthCheck();
  const userID = userAuth?.userDetails?.id;
  const { apiResponse } = useApiResponse();
  const { snackbar } = useSnackBar();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [controllerList, setControllerList] = useState<Record<string, any>[]>(
    []
  );
  const [mappedController, setMappedController] = useState<
    Record<string, string | number>
  >({});
  const [allUpdatedController, setAllUpdatedController] = useState<
    Record<string, any>
  >({});
  const [errorFields, setErrorFields] = useState<Record<string, any>>({});

  const getFielsData = async () => {
    setLoading(true);
    let response = await apiResponse(
      await fetchAllUserBasedData("all_fix_current_data", null, {
        user_id: userID,
      })
    );
    const fix_currents = response?.all_controller || [];

    setControllerList(fix_currents);

    const data: Record<string, string | number> = {};
    for (let index = 0; index < fix_currents?.length; index++) {
      data[fix_currents[index].id] = fix_currents[index].fix_current;
    }

    form.setFieldsValue(data);
    setMappedController(data);
    setLoading(false);
  };

  const handleCurrentChange = (id: string, value: any) => {
    const updatedControllers = { ...allUpdatedController };
    const allErrorFields = { ...errorFields };

    if (updatedControllers[id] === mappedController[id]) {
      delete updatedControllers[id];
    } else {
      updatedControllers[id] = value.target.value;
    }
    setAllUpdatedController(updatedControllers);

    allErrorFields[id] = validateField(value.target.value);
    setErrorFields(allErrorFields);
  };

  const sendFixCurrentData = async (id: string | number) => {
    setLoading(true);
    const values = form.getFieldsValue();
    const payload = {
      id: id,
      fix_current: values[id],
    };
    let createUpdateResponse = await userBasedResourceActions(
      "update_fix_current",
      "post",
      null,
      payload
    );
    const response = await apiResponse(createUpdateResponse);
    if (response) {
      const updatedControllers = { ...allUpdatedController };
      const allErrorFields = { ...errorFields };
      delete updatedControllers[id];
      delete allErrorFields[id];

      setAllUpdatedController(updatedControllers);
      setErrorFields(allErrorFields);
      snackbar("success", response.message);
    }
    setLoading(false);
  };

  const validateField = (value: any) => {
    if (!value.trim() || !new RegExp(/^[0-9]+$/).test(value)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    getFielsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <Row className={commonStyles.display_j_center}>
        <Col xl={12} lg={15} md={20} sm={24} xs={24}>
          <Card
            className={classNames(
              commonStyles.card_wrapper,
              commonStyles.p_xy_1
            )}
          >
            <h5 className={commonStyles.m_b_1}>Current Update</h5>
            <Spin spinning={loading}>
              <Form layout="vertical" form={form}>
                {controllerList?.map(
                  (controller) =>
                    controller && (
                      <Fragment key={controller.id}>
                        <FormFields
                          name={controller.id}
                          label={controller.area_from_to || "-"}
                          placeholder="Zone current 1"
                          $style_type="input"
                          onChange={(value) =>
                            handleCurrentChange(
                              JSON.stringify(controller.id),
                              value
                            )
                          }
                          rules={[
                            {
                              required: true,
                              message: "Please enter zone current",
                            },
                            {
                              message: "Please enter number only",
                              pattern: new RegExp(/^[0-9]+$/),
                            },
                          ]}
                        />

                        <div
                          className={classNames(
                            commonStyles.display_flex,
                            commonStyles.display_a_center,
                            commonStyles.gap_2
                          )}
                        >
                          <StyledButton
                            disabled={
                              !allUpdatedController[controller.id] ||
                              errorFields[controller.id]
                            }
                            label="Submit"
                            onClick={() => sendFixCurrentData(controller.id)}
                          />
                          <StyledButton
                            disabled={!allUpdatedController[controller.id]}
                            label="Reset"
                            $style_type="ghost"
                            onClick={() => {
                              form.setFieldValue(
                                controller.id,
                                mappedController[controller.id]
                              );
                              form.validateFields();
                            }}
                          />
                        </div>
                      </Fragment>
                    )
                )}
              </Form>
            </Spin>
          </Card>
        </Col>
      </Row>
    </main>
  );
};
export default AdditionalSettings;
