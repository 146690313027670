import { Form, Modal } from "antd";
import { Rule } from "antd/es/form";
import classNames from "classnames";
import { useEffect, useState } from "react";
import {
  createUpdateResource,
  fetchAllSuperAdminUserBasedData,
} from "../../../api/authorizeApi";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import FormFields from "../../../components/FormFields/FormFields";
import Loader from "../../../components/Loader/Loader";
import StyledButton from "../../../components/StyledButton/StyledButton";
import { useSnackBar } from "../../../context/SnackBarProvider";
import useApiResponse from "../../../customHooks/useApiResponse";

interface AddEditUsersProps {
  company_id?: string | number;
  open: boolean;
  title: string;
  onCancel: (refresh?: boolean) => void;
}
const AddLocation: React.FC<AddEditUsersProps> = ({
  company_id,
  open,
  title,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [isSubLocation] = useState(title.includes("Sublocation"));
  const [loading, setLoading] = useState(false);
  const [locationList, setLocationList] = useState<Record<string, any>[]>([]);
  const { apiResponse } = useApiResponse();
  const { snackbar } = useSnackBar();

  const validation: Record<string, Rule[]> = {
    location_name: [{ required: true, message: "Please enter location" }],
    parent_location_id: [
      { required: true, message: "Please select a location" },
    ],
    unit_rate: [
      { required: true, message: "Please enter unit rate" },
      {
        pattern: new RegExp(/^[1-9]\d*$/),
        message: "Please enter a positive number not less than 1",
      },
    ],
  };

  const sendAddLocationRequest = async () => {
    setLoading(true);
    const values = form.getFieldsValue();
    let payload;
    if (isSubLocation) {
      payload = {
        sub_location_name: values.location_name,
        company_id: company_id,
        parent_location_id: values.parent_location_id,
      };
    } else {
      payload = {
        location_name: values.location_name,
        company_id: company_id,
        unit_rate: +values.unit_rate,
      };
    }
    let createUpdateResponse = await createUpdateResource(
      !isSubLocation ? "company/location" : "company/sublocation",
      payload
    );
    const response = await apiResponse(createUpdateResponse);
    setLoading(false);
    if (response) {
      onCancel(true);
      form.resetFields();
      snackbar("success", response.message);
    }
  };

  const getLoactionList = async () => {
    setLoading(true);
    const locationData = await apiResponse(
      await fetchAllSuperAdminUserBasedData(
        "locations_under_company",
        company_id
      )
    );
    setLocationList(locationData.locations || []);
    setLoading(false);
  };
  useEffect(() => {
    getLoactionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      centered
      className={classNames(commonStyles.modal_wrapper)}
      footer={false}
      mask
      maskClosable={false}
      open={open}
      onCancel={() => onCancel()}
      title={<h5>{title}</h5>}
    >
      {loading && <Loader />}
      <Form layout="vertical" form={form} onFinish={sendAddLocationRequest}>
        <div className="modal_form">
          {isSubLocation && (
            <FormFields
              name="parent_location_id"
              label="Location"
              placeholder="Location Name"
              $style_type="select"
              showSearch={true}
              menuItem={locationList}
              valueKey="id"
              labelKey="location_name"
              rules={validation["parent_location_id"]}
            />
          )}
          <FormFields
            name="location_name"
            label="Location Name"
            placeholder="Location Name"
            $style_type="input"
            rules={validation["location_name"]}
          />
          {!isSubLocation && (
            <FormFields
              name="unit_rate"
              label="Unit Rate"
              placeholder="Unit Rate"
              $style_type="input"
              rules={validation["unit_rate"]}
            />
          )}
        </div>
        <div className="modal_footer">
          <StyledButton
            label="Reset"
            $style_type="ghost"
            onClick={() => form.resetFields()}
          />
          <StyledButton htmlType="submit" label="Submit" disabled={loading} />
          <StyledButton
            label="Close"
            $style_type="secondary"
            className={commonStyles.m_l_auto}
            onClick={() => onCancel()}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default AddLocation;
