import { Spin, Table } from "antd";
import classNames from "classnames";
import { useState } from "react";
import { userBasedResourceActions } from "../../../api/authorizeApi";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import { useSnackBar } from "../../../context/SnackBarProvider";
import useApiResponse from "../../../customHooks/useApiResponse";
import { ADMIN_CUSTOMER_MANULA_CALIBRATION_COLUMN } from "../../../utils/webTableConfig";

interface CalibrationProps {
  calibrations: Record<string, any>[];
  isRefreshed: (refresh: boolean) => void;
  mode: string;
}

const ManualCalibration: React.FC<CalibrationProps> = ({
  calibrations,
  isRefreshed,
  mode,
}) => {
  const { apiResponse } = useApiResponse();
  const [loading, setLoading] = useState(false);
  const { snackbar } = useSnackBar();

  const handleManualStatus = async (
    checked: boolean,
    controller_did?: string | number,
    controller_id?: string | number
  ) => {
    if (controller_did && controller_id) {
      setLoading(true);
      let response = await apiResponse(
        await userBasedResourceActions(
          "admin/manual_status_change",
          "patch",
          null,
          {
            controller_did: controller_did,
            controller_id: controller_id,
            status: checked,
          }
        )
      );
      setLoading(false);
      if (response) {
        isRefreshed(true);
        snackbar("success", "Manual Status updated successfully");
      }
    }
  };

  const handleTableActions = (
    type: string,
    rowData: any,
    checked: boolean = false
  ) => {
    if (type === "controller_status") {
      handleManualStatus(
        checked,
        rowData?.controller_deviceid,
        rowData?.controller_status?.id
      );
    }
  };

  return (
    <Spin spinning={loading}>
      <Table
        dataSource={calibrations}
        columns={ADMIN_CUSTOMER_MANULA_CALIBRATION_COLUMN(
          handleTableActions,
          mode
        )}
        pagination={false}
        className={classNames(commonStyles.table_wrap)}
      />
    </Spin>
  );
};
export default ManualCalibration;
