import { Card, Empty, Spin } from "antd";
import classNames from "classnames";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { fetchAllUserBasedData } from "../../../../../api/authorizeApi";
import commonStyles from "../../../../../assets/styles/commonStyles.module.scss";
import StyledButton from "../../../../../components/StyledButton/StyledButton";
import { useAuthCheck } from "../../../../../context/AuthCheckProvider";
import { useSnackBar } from "../../../../../context/SnackBarProvider";
import useApiResponse from "../../../../../customHooks/useApiResponse";
import {
  checkTimeFormat,
  colors,
  formatDate,
} from "../../../../../utils/helperFunctions";
import { GRAPH_REFRESH_DURATION } from "../../../../../utils/webStaticData";
import styles from "../../Dashboard.module.scss";
import LineChartWithZoom from "./LineChartWithZoom";
import SensorFilter from "./SensorFilter";

const ConsumptionOverview = () => {
  const { apiResponse } = useApiResponse();
  const [allFilteredData, setAllFilteredData] = useState<
    Record<string, string>
  >(
    Cookies.get("energy_box_f_d")
      ? JSON.parse(Cookies.get("energy_box_f_d") || "")
      : {}
  );
  const { snackbar } = useSnackBar();
  const [consumptionData, setConsumptionData] = useState<
    Record<string, any> | null | undefined
  >({});
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const userAuth = useAuthCheck();
  const userID = userAuth?.userDetails?.id;
  const [resetZoom, setResetZoom] = useState(false);
  const handleShowFilter = (filterData?: Record<string, any>) => {
    setShowFilter((prev) => !prev);
    if (filterData) {
      setLoading(true);
      getGraphData(filterData);
      setResetZoom(false);
    }
  };

  const getGraphData = async (data: Record<string, any>, onLoad?: boolean) => {
    if (!data.start_date) {
      data.start_date = formatDate(new Date(), "DD-MM-YYYY");
      data.end_date = formatDate(new Date(), "DD-MM-YYYY");
    }
    const response = await apiResponse(
      await fetchAllUserBasedData("getGraphData", null, data)
    );
    if (response && !onLoad) {
      snackbar("success", "Filter applied successful");
    }
    setConsumptionData(response || []);
    setLoading(false);
  };

  const chartData = {
    labels: consumptionData?.result?.map((consumption: Record<string, any>) => {
      return checkTimeFormat(consumption?.date)
        ? allFilteredData.start_date + "T" + consumption?.date
        : consumption?.date.split(" ")?.[0] +
            "T" +
            consumption?.date.split(" ")?.[1];
    }),
    datasets: [
      {
        label: `${consumptionData?.parameter_name}${
          allFilteredData?.device_type
            ? " (" + allFilteredData.device_type + ") "
            : ""
        }`,
        data: consumptionData?.result?.map(
          (consumption: Record<string, any>) => consumption?.visits
        ),
        fill: false,
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  const fetchFilterParamters = async () => {
    if (!allFilteredData || JSON.stringify(allFilteredData) === "{}") {
      const parameterList = await apiResponse(
        await fetchAllUserBasedData("getAllParameters")
      );
      const finalValues = {
        user_id: userID,
        parameter_id: (parameterList?.Parameters || [])?.[0]?.id || null,
      };
      setAllFilteredData(finalValues);
      Cookies.set(
        "energy_box_f_d",
        JSON.stringify({
          ...finalValues,
        })
      );
      getGraphData(finalValues, true);
    } else {
      getGraphData(allFilteredData);
    }
  };

  useEffect(() => {
    fetchFilterParamters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (resetZoom) setResetZoom(false);
  }, [resetZoom]);

  useEffect(() => {
    const filterDataFromCookies = Cookies.get("energy_box_f_d")
      ? JSON.parse(Cookies.get("energy_box_f_d") || "")
      : {};

    setAllFilteredData(filterDataFromCookies);

    const intervalCall = setInterval(() => {
      if (
        filterDataFromCookies &&
        JSON.stringify(filterDataFromCookies) !== "{}"
      ) {
        getGraphData(filterDataFromCookies, true);
      } else {
        clearInterval(intervalCall);
      }
    }, GRAPH_REFRESH_DURATION);

    return () => {
      clearInterval(intervalCall);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Cookies.get("energy_box_f_d")]);

  return (
    <>
      <Card
        title="Consumption Overview"
        extra={
          <div
            className={classNames(
              commonStyles.display_flex,
              commonStyles.gap_2
            )}
          >
            <StyledButton label="Filter" onClick={() => handleShowFilter()} />
            <StyledButton
              $style_type="danger"
              label="Reset Zoom"
              onClick={() => setResetZoom(true)}
            />
          </div>
        }
        className={classNames(
          commonStyles.card_wrapper,
          styles.consumption_card
        )}
      >
        <Spin spinning={loading}>
          <div
            className={classNames(
              commonStyles.height100,
              commonStyles.display_flex,
              commonStyles.display_column,
              commonStyles.display_j_spacebetween,
              styles.graph_card
            )}
          >
            {consumptionData?.parameter_name &&
            consumptionData?.result?.length ? (
              <>
                <div
                  className={classNames(
                    commonStyles.display_flex,
                    commonStyles.gap_2,
                    styles.tags
                  )}
                >
                  {consumptionData?.parameter_name && (
                    <span
                      className={classNames(
                        commonStyles.display_flex,
                        commonStyles.display_a_center,
                        commonStyles.gap_1
                      )}
                      style={{ color: colors("blue5") }}
                    >
                      <b>Parameter:</b>
                      {consumptionData?.parameter_name}
                    </span>
                  )}
                  {allFilteredData?.device_type && (
                    <span
                      className={classNames(
                        commonStyles.display_flex,
                        commonStyles.display_a_center,
                        commonStyles.gap_1
                      )}
                      style={{ color: colors("blue5") }}
                    >
                      <b>Device Type:</b>
                      {allFilteredData?.device_type}
                    </span>
                  )}
                </div>
                <LineChartWithZoom data={chartData} resetZoom={resetZoom} />
                <span
                  className={classNames(
                    commonStyles.display_flex,
                    commonStyles.display_centered,
                    commonStyles.gap_1,
                    commonStyles.m_t_1_5
                  )}
                  style={{ color: colors("blue5") }}
                >
                  <b>Date:</b>
                  {`${
                    allFilteredData?.start_date ||
                    formatDate(new Date(), "DD-MM-YYYY")
                  }${
                    allFilteredData?.end_date !== allFilteredData?.start_date
                      ? " - " + allFilteredData?.end_date
                      : ""
                  }`}
                </span>
              </>
            ) : (
              <Empty
                className={classNames(
                  styles.no_data,
                  commonStyles.display_flex,
                  commonStyles.display_column,
                  commonStyles.display_j_center
                )}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  !allFilteredData
                    ? "Add filter to get data"
                    : "No data avilable for the selected filter"
                }
              />
            )}
          </div>
        </Spin>
      </Card>
      {showFilter && (
        <SensorFilter open={showFilter} onCancel={handleShowFilter} />
      )}
    </>
  );
};

export default ConsumptionOverview;
