import { Tabs, TabsProps } from "antd";
import { useHeaderTab } from "../../../../context/HeaderTabProvider";
import styles from "../Dashboard.module.scss";
import IotUserDashboard from "../IotUserDashboard";
import StreetLightUserDashboard from "../StreetLightUserDashboard";

const BothUserDashboard = () => {
  const { setHeader, headerTab } = useHeaderTab();
  const items: TabsProps["items"] = [
    {
      key: "Dashboard-IOT",
      label: "IOT",
      children: <IotUserDashboard />,
    },
    {
      key: "Dashboard-Street-Light",
      label: "Street Light",
      children: <StreetLightUserDashboard />,
    },
  ];

  return (
    <Tabs
      centered
      defaultActiveKey={headerTab}
      items={items}
      size="middle"
      className={styles.both_user_dashboard}
      onChange={(activeKye) => {
        setHeader(activeKye);
      }}
    />
  );
};

export default BothUserDashboard;
