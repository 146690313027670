import {
  DatePicker,
  FormItemProps,
  Input,
  Radio,
  Select,
  SelectProps,
  TimePicker,
} from "antd";
import { DefaultOptionType } from "antd/es/select";
import type { Dayjs } from "dayjs";
import React, { ReactNode, useState } from "react";
import {
  DurationProps,
  styledHTMLComponentsProps,
} from "../../utils/commonInterface";
import { StyledFormField } from "./StyledFormField";
import TagInput from "./TagInput";

const { TextArea } = Input;
const { RangePicker } = DatePicker;
export interface FormFieldsProps {
  endicon?: JSX.Element;
  dateformat?: string;
  infoText?: string;
  max?: number;
  picker?: DurationProps;
  rows?: number;
  starticon?: JSX.Element;
  placeholder?: string;
  type?: string;
  allowClear?: boolean;
  tagValidation?: { [key: string]: { pattern?: RegExp; message: string } };
  disabledDate?: (date: Dayjs) => boolean;
  iconRender?: (visible: boolean) => ReactNode;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>;
  onChangeTags?: (tag: string[]) => void;
  onError?: (error: string | null) => void;
}

export interface SelectFieldProps {
  menuItem?: any[];
  labelKey?: string;
  selectType?: "multiple" | "tags";
  uniqueKey?: string;
  valueKey?: string;
  onChangeSelect?: (
    value: any,
    option: DefaultOptionType | DefaultOptionType[]
  ) => void;
  hasSelectAll?: boolean;
}

const FormFields: React.FC<
  styledHTMLComponentsProps<HTMLElement> &
    // InputProps &
    FormItemProps &
    SelectProps &
    FormFieldsProps &
    SelectFieldProps
> = ({
  className = "",
  disabled,
  dateformat,
  hasSelectAll = false,
  infoText,
  labelKey,
  max,
  menuItem,
  picker,
  rows,
  rules,
  selectType,
  showSearch,
  tagValidation,
  uniqueKey,
  valueKey,
  value,
  disabledDate,
  iconRender,
  onPressEnter,
  onChange,
  onChangeSelect,
  onChangeTags,
  onError,
  ...props
}) => {
  const icon = props.starticon || null;
  const endIcon = props.endicon || null;
  const [isSelectAll, setIsSelectAll] = useState(false);

  return (
    <StyledFormField
      label={props.label}
      name={props.name}
      rules={rules}
      style={{ width: "100%", margin: props.margin || "0 0 1rem 0" }}
      className={className}
      {...props}
    >
      {(!props.$style_type || props.$style_type === "input") && (
        <Input
          prefix={icon}
          suffix={endIcon}
          placeholder={props.placeholder}
          onPressEnter={onPressEnter}
          onChange={onChange}
          disabled={disabled}
          allowClear={props.allowClear}
          type={props.type}
          defaultValue={value}
        />
      )}

      {props.$style_type === "select" && (
        <Select
          placeholder={props.placeholder}
          defaultValue={value}
          mode={selectType}
          allowClear
          disabled={disabled}
          onChange={(
            values: any,
            option: DefaultOptionType | DefaultOptionType[]
          ) => {
            if (hasSelectAll && menuItem && values) {
              if (values.includes("select-all")) {
                setIsSelectAll(true);
                values = menuItem.map((item: any) =>
                  valueKey ? item[valueKey] : item
                );
              } else if (values.includes("unselect-all")) {
                setIsSelectAll(false);
                values = [];
              } else if (menuItem.length === values.length) {
                setIsSelectAll(true);
              } else if (values.length === 0) {
                setIsSelectAll(false);
              }
            }
            onChangeSelect?.(values, option);
          }}
          showSearch={showSearch}
          filterOption={(input, option) => {
            return (
              JSON.stringify(option?.children ?? "")
                .toLowerCase()
                .includes(input.toLowerCase()) || false
            );
          }}
        >
          {hasSelectAll && menuItem?.length && menuItem?.length > 1 && (
            <Select.Option
              key={!isSelectAll ? "select-all" : "unselect-all"}
              value={!isSelectAll ? "select-all" : "unselect-all"}
            >
              {!isSelectAll ? "Select All" : "Unselect All"}
            </Select.Option>
          )}
          {menuItem?.map((item: any, index: number) => (
            <Select.Option
              key={uniqueKey ? item[uniqueKey] : `${props.label}-${index}`}
              value={valueKey ? item[valueKey] : item}
            >
              {labelKey ? item[labelKey] : item}
            </Select.Option>
          ))}
        </Select>
      )}

      {props.$style_type === "date-picker" && (
        <DatePicker
          format="MMMM/YYYY"
          picker={picker}
          disabledDate={disabledDate}
        />
      )}
      {props.$style_type === "range-picker" && (
        <RangePicker
          format={dateformat || "MMMM DD, YYYY"}
          picker={picker}
          disabledDate={disabledDate}
        />
      )}

      {props.$style_type === "textarea" && (
        <TextArea rows={rows} disabled={disabled} />
      )}

      {props.$style_type === "radio-group" && (
        <Radio.Group name="duration" disabled={disabled}>
          {menuItem?.map((item, index) => (
            <Radio
              key={uniqueKey ? item[uniqueKey] : `${props.label}-${index}`}
              value={valueKey ? item[valueKey] : item}
            >
              {labelKey ? item[labelKey] : item}
            </Radio>
          ))}
        </Radio.Group>
      )}

      {props.$style_type === "time-picker" && (
        <TimePicker format="HH:mm" placeholder="HH:MM" disabled={disabled} />
      )}

      {props.$style_type === "tag-input" && (
        <TagInput
          label={props.label}
          infoText={infoText}
          max={max}
          placeholder={props.placeholder}
          values={value}
          onChange={onChange}
          onChangeTags={(tags: string[]) => onChangeTags?.(tags)}
          onError={onError}
          disabled={disabled}
          tagValidation={tagValidation}
        />
      )}

      {props.$style_type === "password" && (
        <Input.Password
          disabled={disabled}
          type="password"
          placeholder={props.placeholder}
          onChange={onChange}
          iconRender={iconRender}
        />
      )}
    </StyledFormField>
  );
};

export default FormFields;
