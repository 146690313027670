import { Switch, Tag } from "antd";
import classNames from "classnames";
import { FaCircle } from "react-icons/fa6";
import commonStyles from "../assets/styles/commonStyles.module.scss";
import FormFields from "../components/FormFields/FormFields";
import StyledButton from "../components/StyledButton/StyledButton";
import {
  arrayOfValuesForKey,
  arrayToCommaSeperatedString,
  colors,
  iconStylesProps,
} from "./helperFunctions";
import { WORKING_AS_MAP } from "./webStaticData";

export const ADMIN_CUSTOMER_ALERTS_COLUMN = (onActionClick: Function) => [
  {
    title: "Alert Name",
    dataIndex: "event_name",
    key: "event_name",
    render: (event_name?: string | null) => (event_name ? event_name : "-"),
  },
  {
    title: "Phone Number",
    dataIndex: "mobile_numbers",
    key: "mobile_numbers",
    render: (mobile_numbers?: string | null) =>
      mobile_numbers ? mobile_numbers : "-",
  },
  {
    title: "Days",
    dataIndex: "days",
    key: "days",
    render: (days?: string | null) =>
      days ? arrayToCommaSeperatedString(days?.split(",")) : "-",
  },
  {
    title: "Time",
    key: "time",
    render: (_: any, rowData: Record<string, any>) =>
      !rowData.time_from || !rowData.time_to
        ? "-"
        : `${rowData.time_from} - ${rowData.time_to}`,
  },
  {
    title: "Active",
    dataIndex: "status",
    render: (status: any, rowData: any) => (
      <Switch
        defaultChecked={status === "0" ? false : true}
        onChange={(checked: boolean) =>
          onActionClick("Status", rowData, checked)
        }
      />
    ),
  },
  {
    title: "Actions",
    dataIndex: "actions",
    render: (_: any, rowData: any) => (
      <div
        className={classNames(
          commonStyles.display_flex,
          commonStyles.display_a_center,
          commonStyles.gap_2
        )}
      >
        <StyledButton
          label="View"
          $style_type="success"
          onClick={() => onActionClick("Show", rowData)}
        />
        <StyledButton
          label="Edit"
          onClick={() => onActionClick("Edit", rowData)}
        />
        <StyledButton
          label="Delete"
          $style_type="danger"
          onClick={() => onActionClick("Delete", rowData)}
        />
      </div>
    ),
  },
];

export const ADMIN_CUSTOMER_CLOCK_CALIBRATION_COLUMN = (
  onActionClick: Function,
  mode: string
) => [
  {
    title: "Location Name",
    dataIndex: "location_name",
    key: "location_name",
    render: (location_name?: string | null) =>
      location_name ? location_name : "-",
  },
  {
    title: "Description",
    dataIndex: "controller_desc",
    key: "controller_desc",
    render: (controller_desc?: string | null) =>
      controller_desc ? controller_desc : "-",
  },
  {
    title: "Timing",
    dataIndex: "timing",
    key: "timing",
    render: (_: any, rowData: Record<string, any>) => {
      const timing = JSON.parse(rowData?.automate_time || "[]");
      const days = arrayToCommaSeperatedString(timing.days);
      return rowData.automate_time?.trim() && timing && days?.trim()
        ? `${days} ${timing.start_time} - ${timing.end_time}`
        : "-";
    },
  },
  {
    title: "Edit Time",
    dataIndex: "editTime",
    render: (_: any, rowData: any) => (
      <StyledButton
        disabled={mode !== "clock"}
        label="Edit Time"
        onClick={() => onActionClick("Edit", rowData)}
      />
    ),
  },
  {
    title: "Automate",
    dataIndex: "controller_automate",
    render: (controller_automate: string | number, rowData: any) => (
      <Switch
        defaultChecked={[1, "1"].includes(controller_automate) ? true : false}
        disabled={mode !== "clock"}
        onChange={(checked: boolean) =>
          onActionClick("controller_automate", rowData, checked)
        }
      />
    ),
  },
];

export const ADMIN_CUSTOMER_CONTROLLER_COLUMN = (
  onAction: Function,
  mode: string,
  isStartValueEnabled: boolean,
  isOffValueEnabled: boolean
) => [
  {
    title: "Zone",
    dataIndex: "location_name",
    key: "location_name",
    render: (location_name?: string | null) =>
      location_name ? location_name : "-",
  },
  {
    title: "LDR Start Value",
    dataIndex: "ldr_start_value",
    render: (ldr_start_value?: string | number | null) => (
      <FormFields
        disabled={mode !== "ldr" || !isStartValueEnabled}
        $style_type="input"
        placeholder="LDR Start Value"
        value={ldr_start_value}
        onChange={(ldrValue) =>
          onAction("ldrStartValue", ldrValue.target.value)
        }
      />
    ),
  },
  {
    title: "LDR Off Value",
    dataIndex: "ldr_stop_value",
    render: (ldr_stop_value?: string | number | null) => (
      <FormFields
        disabled={mode !== "ldr" || !isOffValueEnabled}
        $style_type="input"
        placeholder="LDR Off Value"
        value={ldr_stop_value}
        onChange={(ldrValue) => onAction("ldrOffValue", ldrValue.target.value)}
      />
    ),
  },
  {
    title: "Calibration",
    dataIndex: "calibration",
    render: (_: any, rowData: any) => (
      <StyledButton
        disabled={mode !== "ldr"}
        label="Calibrate"
        onClick={() => onAction("calibration", rowData)}
      />
    ),
  },
  {
    title: "Calibrate On/Off",
    dataIndex: "calibrateOnOff",
    render: (_: any, rowData: any) => (
      <div
        className={classNames(
          commonStyles.display_flex,
          commonStyles.display_a_center,
          commonStyles.gap_1
        )}
      >
        <Switch
          defaultChecked={!!+rowData.is_ldr_off}
          disabled={mode !== "ldr"}
          onChange={(checked: boolean) =>
            onAction("calibrateOnOff1", rowData, checked)
          }
        />
        <Switch
          defaultChecked={!!+rowData.is_ldr_on}
          disabled={mode !== "ldr"}
          onChange={(checked: boolean) =>
            onAction("calibrateOnOff2", rowData, checked)
          }
        />
      </div>
    ),
  },
];

export const ADMIN_CUSTOMER_LDR_CALIBRATION_COLUMN = (
  onAction: Function,
  mode: string,
  isStartValueEnabled: boolean,
  isOffValueEnabled: boolean
) => [
  {
    title: "Location Name",
    dataIndex: "location_name",
    key: "location_name",
    render: (location_name?: string | null) =>
      location_name ? location_name : "-",
  },
  {
    title: "LDR Start Value",
    dataIndex: "ldr_start_value",
    render: (ldr_start_value?: string | number | null) => (
      <FormFields
        disabled={mode !== "ldr" || !isStartValueEnabled}
        $style_type="input"
        placeholder="LDR Start Value"
        value={ldr_start_value}
        onChange={(ldrValue) =>
          onAction("ldrStartValue", ldrValue.target.value)
        }
      />
    ),
  },
  {
    title: "LDR Off Value",
    dataIndex: "ldr_stop_value",
    render: (ldr_stop_value?: string | number | null) => (
      <FormFields
        disabled={mode !== "ldr" || !isOffValueEnabled}
        $style_type="input"
        placeholder="LDR Off Value"
        value={ldr_stop_value}
        onChange={(ldrValue) => onAction("ldrOffValue", ldrValue.target.value)}
      />
    ),
  },
  {
    title: "Calibration",
    dataIndex: "calibration",
    render: (_: any, rowData: any) => (
      <StyledButton
        disabled={mode !== "ldr"}
        label="Calibrate"
        onClick={() => onAction("calibration", rowData)}
      />
    ),
  },
  {
    title: "Calibrate On/Off",
    dataIndex: "calibrateOnOff",
    render: (_: any, rowData: any) => (
      <div
        className={classNames(
          commonStyles.display_flex,
          commonStyles.display_a_center,
          commonStyles.gap_1
        )}
      >
        <Switch
          defaultChecked={!!+rowData.is_ldr_off}
          disabled={mode !== "ldr"}
          onChange={(checked: boolean) =>
            onAction("calibrateOnOff1", rowData, checked)
          }
        />
        <Switch
          defaultChecked={!!+rowData.is_ldr_on}
          disabled={mode !== "ldr"}
          onChange={(checked: boolean) =>
            onAction("calibrateOnOff2", rowData, checked)
          }
        />
      </div>
    ),
  },
];

export const ADMIN_CUSTOMER_MANULA_CALIBRATION_COLUMN = (
  onActionClick: Function,
  mode: string
) => [
  {
    title: "Location Name",
    dataIndex: "location_name",
    key: "location_name",
    render: (location_name?: string | null) =>
      location_name ? location_name : "-",
  },
  {
    title: "Description",
    dataIndex: "controller_desc",
    key: "controller_desc",
    render: (controller_desc?: string | null) =>
      controller_desc ? controller_desc : "-",
  },
  {
    title: "Manual Status",
    dataIndex: "controller_status",
    render: (
      controller_status: Record<string, any>,
      rowData: Record<string, any>
    ) => (
      <Switch
        defaultChecked={
          [1, "1"].includes(controller_status?.[rowData.controller_deviceid])
            ? true
            : false
        }
        disabled={mode !== "manual"}
        onChange={(checked: boolean) =>
          onActionClick("controller_status", rowData, checked)
        }
      />
    ),
  },
];

export const ADMIN_CUSTOMER_SENSORS_COLUMN = [
  {
    title: "Sensor Name",
    dataIndex: "sensor_name",
    key: "sensor_name",
    render: (sensor_name?: string | null) => (sensor_name ? sensor_name : "-"),
  },
  {
    title: "Location",
    dataIndex: "location_name",
    key: "location_name",
    render: (location_name?: string | null) =>
      location_name ? location_name : "-",
  },
  {
    title: "Sublocation",
    dataIndex: "sublocation_name",
    key: "sublocation_name",
    render: (sublocation_name?: string | null) =>
      sublocation_name ? sublocation_name : "-",
  },
  {
    title: "Type",
    dataIndex: "device_name",
    key: "device_name",
    render: (device_name?: string | null) => (device_name ? device_name : "-"),
  },
  {
    title: "Status",
    dataIndex: "sensor_status",
    render: (sensor_status: string) =>
      [1, "1", 0, "0"].includes(sensor_status) ? (
        <Tag
          icon={
            <FaCircle
              {...iconStylesProps({
                color: [1, "1"].includes(sensor_status)
                  ? colors("green1")
                  : colors("red1"),
              })}
            />
          }
          className={classNames(
            commonStyles.display_flex,
            commonStyles.display_a_center
          )}
          style={{ width: "fit-content", fontSize: "14px" }}
        >
          {[1, "1"].includes(sensor_status) ? "Active" : "Inactive"}
        </Tag>
      ) : (
        "-"
      ),
  },
];

export const SUPER_ADMIN_COMPANY_COLUMN = (onActionClick: Function) => [
  {
    title: "Company Name",
    dataIndex: "name",
    key: "SUPER_ADMIN_COMPANY_COLUMN_NAME",
    render: (name?: string | null) => (name ? name : "-"),
  },
  {
    title: "Total Users",
    dataIndex: "users",
    key: "SUPER_ADMIN_COMPANY_COLUMN_USERS",
    render: (users?: string | null) => (users ? users : "-"),
  },
  {
    title: "Sensors",
    dataIndex: "sensors",
    key: "SUPER_ADMIN_COMPANY_COLUMN_SENSORS",
    render: (sensors?: string | null) => (sensors ? sensors : "-"),
  },
  {
    title: "Address",
    dataIndex: "address1",
    key: "SUPER_ADMIN_COMPANY_COLUMN_ADDRESS",
    render: (address: string | null, data: Record<string, any>) => {
      return [address, data.city, data.state, data.zip, data.country]
        .filter((address) => address)
        .join(", ");
    },
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "SUPER_ADMIN_COMPANY_COLUMN_ACTION",
    render: (_: any, rowData: any) => (
      <div
        className={classNames(commonStyles.display_flex, commonStyles.gap_2)}
      >
        <StyledButton
          label="Edit"
          onClick={() => onActionClick("Edit", rowData)}
        />
        <StyledButton
          label="Show"
          onClick={() => onActionClick("Show", rowData)}
        />
        <StyledButton
          label="Location"
          onClick={() => onActionClick("Location", rowData)}
        />
        <StyledButton
          label="Sublocation"
          onClick={() => onActionClick("Sublocation", rowData)}
        />
      </div>
    ),
  },
];

export const SUPER_ADMIN_CONTROLLERS_COLUMN = (onActionClick: Function) => [
  {
    title: "Customer Name",
    dataIndex: "company",
    key: "SUPER_ADMIN_CONTROLLERS_COLUMN_CUSTOMER",
    render: (company?: Record<string, any> | null) =>
      company && company.name ? company.name : "-",
  },
  {
    title: "Location Name",
    dataIndex: "location",
    key: "SUPER_ADMIN_CONTROLLERS_COLUMN_LOCATION",
    render: (location?: Record<string, any> | null) =>
      location && location.location_name ? location.location_name : "-",
  },
  {
    title: "Device Name",
    dataIndex: "device",
    key: "SUPER_ADMIN_CONTROLLERS_COLUMN_DEVICE",
    render: (device?: Record<string, any> | null) =>
      device && device.device_name ? device.device_name : "-",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "SUPER_ADMIN_CONTROLLERS_COLUMN_ACTION",
    render: (_: any, rowData: any) => (
      <div
        className={classNames(commonStyles.display_flex, commonStyles.gap_2)}
      >
        <StyledButton
          label="Edit"
          onClick={() => onActionClick("Edit", rowData)}
        />
        <StyledButton
          label="Delete"
          $style_type="danger"
          onClick={() => onActionClick("Delete", rowData)}
        />
      </div>
    ),
  },
];

export const SUPER_ADMIN_DEVICE_TYPE_COLUMN = (onActionClick: Function) => [
  {
    title: "Name",
    dataIndex: "device_name",
    key: "SUPER_ADMIN_DEVICE_TYPE_COLUMN_NAME",
    render: (device_name?: string | null) => (device_name ? device_name : "-"),
  },
  {
    title: "Description",
    dataIndex: "device_description",
    key: "SUPER_ADMIN_DEVICE_TYPE_COLUMN_DESCRIPTION",
    render: (device_description?: string | null) =>
      device_description ? device_description : "-",
  },
  {
    title: "Parameters",
    dataIndex: "device_parameters",
    key: "SUPER_ADMIN_DEVICE_TYPE_COLUMN_DEVICE_PARAMETERS",
    render: (device_parameters?: Record<string, any>[] | null) =>
      device_parameters && device_parameters.length
        ? arrayToCommaSeperatedString(
            arrayOfValuesForKey(device_parameters, "parameter_name")
          )
        : "-",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "SUPER_ADMIN_DEVICE_TYPE_COLUMN_ACTION",
    render: (_: any, rowData: any) => (
      <div
        className={classNames(commonStyles.display_flex, commonStyles.gap_2)}
      >
        <StyledButton
          label="Edit"
          onClick={() => onActionClick("Edit", rowData)}
        />
        <StyledButton
          label="View"
          onClick={() => onActionClick("Show", rowData)}
        />
        <StyledButton
          label="Delete"
          $style_type="danger"
          onClick={() => onActionClick("Delete", rowData)}
        />
      </div>
    ),
  },
];

export const SUPER_ADMIN_LIGHT_CONTROL_COLUMN = (onAction: Function) => [
  {
    title: "Area From To",
    dataIndex: "area_from_to",
    key: "area_from_to",
    render: (
      area_from_to: string | null,
      rowData: Record<string, any>,
      index: number
    ) => (
      <FormFields
        $style_type="input"
        value={area_from_to || 0}
        onChange={(value) =>
          onAction("area_from_to", index + "", rowData, value)
        }
      />
    ),
  },
  {
    title: "Total Poles",
    dataIndex: "total_poles",
    key: "total_poles",
    render: (
      total_poles: string | null,
      rowData: Record<string, any>,
      index: number
    ) => (
      <FormFields
        $style_type="input"
        value={total_poles || 0}
        onChange={(value) =>
          onAction("total_poles", index + "", rowData, value)
        }
      />
    ),
  },
  {
    title: "Total Lights",
    dataIndex: "total_lights",
    key: "total_lights",
    render: (
      total_lights: string | null,
      rowData: Record<string, any>,
      index: number
    ) => (
      <FormFields
        $style_type="input"
        value={total_lights || 0}
        onChange={(value) =>
          onAction("total_lights", index + "", rowData, value)
        }
      />
    ),
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (_: any, rowData: Record<string, any>, index: number) => (
      <div
        className={classNames(commonStyles.display_flex, commonStyles.gap_2)}
      >
        <StyledButton
          label="Update"
          htmlType="submit"
          onClick={() => onAction("Update", index + "", rowData)}
        />
      </div>
    ),
  },
];

export const SUPER_ADMIN_RECOVERY_CHECK_COLUMN = (onActionClick: Function) => [
  {
    title: "Sensor Name",
    dataIndex: "sensor_name",
    key: "SUPER_ADMIN_RECOVERY_CHECK_COLUMN_SENSOR_NAME",
    render: (sensor_name?: string | null) => (sensor_name ? sensor_name : "-"),
  },
  {
    title: "Sensor Device Id",
    dataIndex: "sensor_deviceid",
    key: "SUPER_ADMIN_RECOVERY_CHECK_COLUMN_SENSOR_DEVICE_ID",
    render: (sensor_deviceid?: string | null) =>
      sensor_deviceid ? sensor_deviceid : "-",
  },
  {
    title: "Meter Number",
    dataIndex: "sensor_meterno",
    key: "SUPER_ADMIN_RECOVERY_CHECK_COLUMN_METER_NO",
    render: (sensor_meterno?: string | null) =>
      sensor_meterno ? sensor_meterno : "-",
  },
  {
    title: "Action",
    dataIndex: "recovery_status",
    key: "SUPER_ADMIN_RECOVERY_CHECK_COLUMN_ACTION",
    render: (recovery_status: any, rowData: any) => (
      <div
        className={classNames(commonStyles.display_flex, commonStyles.gap_2)}
      >
        <Switch
          defaultChecked={recovery_status}
          onChange={(checked: boolean) => onActionClick(rowData, checked)}
        />
      </div>
    ),
  },
];

export const SUPER_ADMIN_SENSORS_COLUMN = (onActionClick: Function) => [
  {
    title: "Customer Name",
    dataIndex: "company",
    key: "SUPER_ADMIN_SENSORS_COLUMN_CUSTOMER",
    render: (company?: Record<string, any> | null) =>
      company && company.name ? company.name : "-",
  },
  {
    title: "Location Name",
    dataIndex: "location",
    key: "SUPER_ADMIN_SENSORS_COLUMN_LOCATION",
    render: (location?: Record<string, any> | null) =>
      location && location.location_name ? location.location_name : "-",
  },
  {
    title: "Sub Location Name",
    dataIndex: "sublocation",
    key: "SUPER_ADMIN_SENSORS_COLUMN_SUBLOCATION",
    render: (sublocation?: Record<string, any> | null) =>
      sublocation && sublocation.location_name
        ? sublocation.location_name
        : "-",
  },
  {
    title: "Device Name",
    dataIndex: "device",
    key: "SUPER_ADMIN_SENSORS_COLUMN_DEVICE_NAME",
    render: (device?: Record<string, any> | null) =>
      device && device.device_name ? device.device_name : "-",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "SUPER_ADMIN_SENSORS_COLUMN_ACTION",
    render: (_: any, rowData: any) => (
      <div
        className={classNames(commonStyles.display_flex, commonStyles.gap_2)}
      >
        <StyledButton
          label="Edit"
          onClick={() => onActionClick("Edit", rowData)}
        />
        <StyledButton
          label="Delete"
          $style_type="danger"
          onClick={() => onActionClick("Delete", rowData)}
        />
      </div>
    ),
  },
];

export const SUPER_ADMIN_USER_MANAGE_COLUMN = (onActionClick: Function) => [
  {
    title: "Client Name",
    dataIndex: "name",
    key: "SUPER_ADMIN_USER_MANAGE_COLUMN_CLIENT_NAME",
    render: (name: string | null, rowData: Record<string, any>) => {
      return name ? (
        <a href={`/superadmin/manage-users/${rowData.id}`}>{name}</a>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Number Of Users",
    dataIndex: "users",
    key: "SUPER_ADMIN_USER_MANAGE_COLUMN_NUMBER_OD_USERS",
    render: (users?: string | null) => (users ? users : "-"),
  },
];

export const USERS_COLUMNS = (
  currentScreen: string,
  onActionClick: Function
) => {
  const showRole = ["allUsersInSuperAdmin"];
  const showAllActions = [
    "adminInSuperAdmin",
    "usersInAdmin",
    "allUsersInSuperAdmin",
    "users",
  ];
  const nameFieldTitle: Record<string, string> = {
    company: "Company Name",
    adminInSuperAdmin: "Admin Name",
    usersInAdmin: "User Name",
    allUsersInSuperAdmin: "User Name",
    users: "User Name",
  };
  return [
    {
      title: nameFieldTitle[currentScreen],
      dataIndex: "name",
      key: "USERS_COLUMNS_Name",
      render: (name?: string | null, rowData?: Record<string, any>) =>
        name ? (
          rowData?.role === "admin" || currentScreen === "company" ? (
            <p
              style={{ color: colors("blue7"), cursor: "pointer" }}
              onClick={() => onActionClick("Redirect", rowData)}
            >
              {name}
            </p>
          ) : (
            name
          )
        ) : (
          "-"
        ),
    },
    ...(showRole.includes(currentScreen)
      ? [
          {
            title: "Role",
            dataIndex: "role",
            key: "USERS_COLUMNS_ROLE",
            render: (role: string | null, rowData: Record<string, any>) =>
              role ? (
                <>
                  <p style={{ width: "5rem" }}>
                    {role.toLocaleUpperCase()}
                    <br />
                    <small>
                      {rowData?.working_as
                        ? " (" + WORKING_AS_MAP[rowData.working_as] + ")"
                        : ""}
                    </small>
                  </p>
                </>
              ) : (
                "-"
              ),
          },
        ]
      : []),
    ...(currentScreen !== "company"
      ? [
          {
            title: "Locations",
            dataIndex: "locations",
            key: "USERS_COLUMNS_LOCATION",
            render: (locations?: Record<string, any>[] | null) =>
              locations?.length
                ? arrayToCommaSeperatedString(
                    arrayOfValuesForKey(locations, "location_name")
                  )
                : "-",
          },
        ]
      : [
          {
            title: "Total Users",
            dataIndex: "total_users",
            key: "USER_MANAGE_COLUMN_NUMBER_OD_USERS",
            render: (total_users?: string | null) =>
              total_users ? total_users : "0",
          },
        ]),
    ...(currentScreen !== "company"
      ? [
          {
            title: "Total Sensors",
            dataIndex: "totalSensors",
            key: "USERS_COLUMNS_TOTAL_SENSOR",
            render: (sensor_count?: string | number | null) =>
              sensor_count ? sensor_count : "0",
          },
        ]
      : [
          {
            title: "Sensors",
            dataIndex: "total_sensors",
            key: "USERS_COLUMNS_TOTAL_SENSOR",
            render: (total_sensors?: string | number | null) =>
              total_sensors ? total_sensors : "0",
          },
        ]),
    ...(currentScreen === "company"
      ? [
          {
            title: "Address",
            dataIndex: "address1",
            key: "USERS_COLUMNS_ADDRESS",
            render: (address1: string | null, data: Record<string, any>) => {
              return [
                address1,
                data?.address2,
                data?.city,
                data?.state,
                data?.zip,
                data?.country,
              ]
                .filter((address) => address)
                .join(", ");
            },
          },
        ]
      : []),
    ...(showAllActions.includes(currentScreen)
      ? [
          {
            title: "Moving SP",
            dataIndex: "enable_sp_controller",
            key: "USERS_COLUMNS_MOVING_SP",
            render: (enable_sp_controller: string | number, rowData: any) => (
              <Switch
                defaultChecked={
                  [1, "1"].includes(enable_sp_controller) ? true : false
                }
                onChange={(checked: boolean) =>
                  onActionClick("MovingSP", rowData, checked)
                }
              />
            ),
          },
          {
            title: "Controller Added?",
            dataIndex: "enable_controller",
            key: "USERS_COLUMNS_CONTROLLER_ADDED",
            render: (enable_controller: string | number, rowData: any) => (
              <Switch
                defaultChecked={
                  [1, "1"].includes(enable_controller) ? true : false
                }
                onChange={(checked: boolean) =>
                  onActionClick("ControllerAdded", rowData, checked)
                }
              />
            ),
          },
          {
            title: "Active",
            dataIndex: "status",
            key: "USERS_COLUMNS_STATUS",
            render: (status: string | number, rowData: any) => (
              <Switch
                defaultChecked={[1, "1"].includes(status)}
                onChange={(checked: boolean) =>
                  onActionClick("Active", rowData, checked)
                }
              />
            ),
          },
        ]
      : []),
    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, rowData: any) => (
        <div
          className={classNames(commonStyles.display_flex, commonStyles.gap_2)}
        >
          <StyledButton
            label="Edit"
            onClick={() => onActionClick("Edit", rowData)}
          />
          <StyledButton
            label="View"
            onClick={() => onActionClick("Show", rowData)}
          />
          {currentScreen === "company" ? (
            <>
              <StyledButton
                label="Location"
                onClick={() => onActionClick("Location", rowData)}
              />
              <StyledButton
                label="Sublocation"
                onClick={() => onActionClick("Sublocation", rowData)}
              />
            </>
          ) : (
            <></>
          )}
          <StyledButton
            label="Delete"
            $style_type="danger"
            onClick={() => onActionClick("Delete", rowData)}
          />
        </div>
      ),
    },
  ];
};

export const USERS_CONTROLLERS_COLUMN = (onActionClick: Function) => [
  {
    title: "Location",
    dataIndex: "location_name",
    key: "location_name",
    render: (location_name?: string | null) =>
      location_name ? location_name : "-",
  },
  {
    title: "Description",
    dataIndex: "controller_desc",
    render: (controller_desc?: string | null) =>
      controller_desc ? controller_desc : "-",
  },
  {
    title: "Timing",
    dataIndex: "area_from_to",
    render: (area_from_to?: string | number | null) =>
      area_from_to ? area_from_to : "-",
  },
  {
    title: "Automate",
    dataIndex: "controller_automate",
    render: (
      controller_automate?: string | number | null,
      rowData?: Record<string, any>
    ) => (
      <Switch
        defaultChecked={
          controller_automate && [1, "1"].includes(controller_automate)
            ? true
            : false
        }
        onChange={(checked: boolean) =>
          onActionClick("automate_status", rowData, checked)
        }
      />
    ),
  },
  {
    title: "Status",
    dataIndex: "controller_details",
    render: (
      controller_details?: Record<string, any>,
      rowData?: Record<string, any>
    ) =>
      rowData &&
      controller_details &&
      [1, "1"].includes(controller_details[rowData["controller_deviceid"]]) ? (
        <img
          width={32}
          src={require("../assets/images/green_light.png")}
          alt="enable_green_light"
        />
      ) : (
        <img
          width={32}
          src={require("../assets/images/red_light.png")}
          alt="enable_red_light"
        />
      ),
  },
  {
    title: "Action",
    dataIndex: "controller_details",
    render: (
      controller_details?: Record<string, any>,
      rowData?: Record<string, any>
    ) => (
      <Switch
        defaultChecked={
          rowData &&
          controller_details &&
          [1, "1"].includes(controller_details[rowData["controller_deviceid"]])
            ? true
            : false
        }
        onChange={(checked: boolean) =>
          onActionClick("controller_status", rowData, checked)
        }
      />
    ),
  },
];

export const USERS_STREETLIST_DASHBOARD_COLUMN = () => [
  {
    title: "Zone",
    dataIndex: "location_name",
    key: "location_name",
    render: (location_name?: string | null) =>
      location_name ? location_name : "-",
  },
  {
    title: "JN Box",
    dataIndex: "jn_box",
    render: (jn_box?: string | number | null) => (jn_box ? jn_box : "-"),
  },
  {
    title: "Area(From To)",
    dataIndex: "area_from_to",
    render: (area_from_to?: string | number | null) =>
      area_from_to ? area_from_to : "-",
  },
  {
    title: "Total Poles",
    dataIndex: "total_poles",
    render: (total_poles?: string | number | null) =>
      total_poles ? total_poles : "-",
  },
  {
    title: "Total Lights",
    dataIndex: "total_lights",
    render: (total_lights?: string | number | null) =>
      total_lights ? total_lights : "-",
  },
  {
    title: "Energy(KWH)",
    dataIndex: "kwh",
    render: (kwh?: string | number | null) => (kwh ? kwh : "-"),
  },
  {
    title: "LDR",
    dataIndex: "LDR",
    render: (LDR?: string | number | null) => (LDR ? LDR : "-"),
  },
  {
    title: "Force On",
    dataIndex: "force_on",
    render: (force_on?: string | number | null) => (force_on ? force_on : "-"),
  },
  {
    title: "Fault Status",
    dataIndex: "fault_status",
    render: (fault_status?: string | number | null) =>
      fault_status ? fault_status : "-",
  },
  {
    title: "Theft/Over Current",
    dataIndex: "theftOverCurrent",
    render: (theftOverCurrent?: string | number | null) =>
      theftOverCurrent ? theftOverCurrent : "-",
  },
  {
    title: "Current Status",
    dataIndex: "current_status",
    render: (current_status?: string | number | null) =>
      current_status ? current_status : "-",
  },
  {
    title: "Live Data",
    dataIndex: "is_livedata",
    render: (is_livedata?: string | number | null) =>
      is_livedata && [1, "1"].includes(is_livedata) ? (
        <img
          width={32}
          src={require("../assets/images/green_light.png")}
          alt="enable_green_light"
        />
      ) : (
        <img
          width={32}
          src={require("../assets/images/red_light.png")}
          alt="enable_red_light"
        />
      ),
  },
  {
    title: "Live Current(Phase1/Phase2)",
    dataIndex: "phase1_current",
    render: (_: any, rowData: Record<string, any>) =>
      `${rowData?.phase1_current ? rowData?.phase1_current : "0.00"}\\${
        rowData?.phase2_current ? rowData?.phase2_current : "0.00"
      }`,
  },
];

export const CLIENT_LOCATION = (currentScreen?: string) => [
  {
    title: "Location",
    dataIndex: "location_name",
    render: (location_name?: string | number | null) =>
      location_name ? location_name : "-",
  },
  {
    title: "Sub Locations",
    dataIndex: "sublocations",
    render: (sublocations?: Record<string, any>[] | null) =>
      sublocations?.length
        ? arrayToCommaSeperatedString(
            arrayOfValuesForKey(
              sublocations,
              currentScreen === "company" ? "sublocation_name" : "location_name"
            )
          )
        : "-",
  },
  ...(currentScreen === "company"
    ? [
        {
          title: "Unit Rate",
          dataIndex: "unit_rate",
          render: (unit_rate?: string | number | null) =>
            unit_rate ? unit_rate : "-",
        },
      ]
    : []),
];
