import { Col, Row, Table } from "antd";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import { CLIENT_LOCATION } from "../../../utils/webTableConfig";

interface ViewUserProps {
  userDetails: Record<string, any>;
  companyName?: string;
  adminName?: string;
}
const ViewUser: React.FC<ViewUserProps> = ({
  userDetails,
  companyName,
  adminName,
}) => {
  return (
    <div className="modal_form">
      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <h6>Name</h6>
          <p>{userDetails?.name || "-"}</p>
        </Col>
        <Col md={12} xs={24}>
          <h6>Email</h6>
          <p>{userDetails?.email || "-"}</p>
        </Col>
        <Col xs={24}>
          <h6>Phone Number</h6>
          <p>{userDetails?.phone || "-"}</p>
        </Col>
        <Col md={12} xs={24}>
          <h6>Role</h6>
          <p>{userDetails?.role || "-"}</p>
        </Col>
        <Col md={12} xs={24}>
          <h6>Working As</h6>
          <p>{userDetails?.working_as || "-"}</p>
        </Col>
        <Col md={12} xs={24}>
          <h6>Company Name</h6>
          <p>{companyName || "-"}</p>
        </Col>
        <Col md={12} xs={24}>
          <h6>Admin Name</h6>
          <p>{adminName || "-"}</p>
        </Col>
        <Col md={12} xs={24}>
          <h6>Status</h6>
          <p>
            {[1, "1"].includes(userDetails?.status) ? "Active" : "In-Active"}
          </p>
        </Col>
        <Col md={12} xs={24}>
          <h6>Moving SP</h6>
          <p>
            {[1, "1"].includes(userDetails?.enable_sp_controller)
              ? "Yes"
              : "No"}
          </p>
        </Col>
        <Col md={12} xs={24}>
          <h6>Controller Added?</h6>
          <p>
            {[1, "1"].includes(userDetails?.enable_controller) ? "Yes" : "No"}
          </p>
        </Col>
        <Col xs={24}>
          <h6>Locations Details</h6>

          <Table
            dataSource={userDetails?.locations || []}
            columns={CLIENT_LOCATION()}
            className={commonStyles.table_wrap}
            pagination={false}
            bordered
          />
        </Col>
      </Row>
    </div>
  );
};

export default ViewUser;
