import { ReactNode, createContext, useContext, useState } from "react";
interface HeaderTabProviderProps {
  children: ReactNode;
}
interface HeaderTabContextProps {
  headerTab?: string;
  setHeader: (tab: string) => void;
}
const HeaderTabContext = createContext<HeaderTabContextProps>({
  headerTab: "Dashboard-IOT",
  setHeader: (tab: string) => {},
});
export const HeaderTabProvider = ({ children }: HeaderTabProviderProps) => {
  const [headerTab, setHeaderTab] = useState("Dashboard-IOT");

  const setHeader = (tab: string) => {
    setHeaderTab(tab);
  };

  const headerData = {
    headerTab,
    setHeader,
  };
  return (
    <HeaderTabContext.Provider value={headerData}>
      {children}
    </HeaderTabContext.Provider>
  );
};

export const useHeaderTab = () => {
  return useContext(HeaderTabContext);
};
