import axios, { AxiosInstance, ResponseType } from "axios";
import Cookies from "js-cookie";

export const BASE_URL = process.env.REACT_APP_BASE_URL + "/api";

let axiosJSON: AxiosInstance;
axiosJSON = axios.create({
  baseURL: BASE_URL,
});

const setAuth = (isUnauthorised: boolean = false) => {
  if (Cookies.get("energy_box_t") && !isUnauthorised) {
    axiosJSON.defaults.headers["authorization"] = `Bearer ${Cookies.get(
      "energy_box_t"
    )?.trim()}`;
  } else {
    delete axiosJSON.defaults.headers["authorization"];
  }
};

axiosJSON.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const apiClient = async (
  requestType: "get" | "post" | "patch" | "delete",
  url: string,
  params?: any,
  data?: any,
  responseType?: ResponseType,
  isUnauthorised?: boolean
) => {
  setAuth(isUnauthorised);
  try {
    let res;
    if (requestType === "get" || requestType === "delete") {
      res = await axiosJSON[requestType](url, { params, responseType });
    } else {
      res = await axiosJSON[requestType](url, data, { params });
    }
    return { type: "success", data: res ? res.data : null };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error?.code === "ERR_NETWORK") {
        return {
          type: "net_unauth",
          message: "Network Error!",
        };
      } else if (error?.response?.status === 401) {
        // delete axiosJSON.defaults.headers["authorization"];
        // Cookies.remove("energy_box_t");
        // window.location.reload();
        return {
          type: "net_unauth",
          message: "Unauthorized User!",
        };
      } else if (
        error?.response?.status &&
        error?.response?.status >= 400 &&
        error?.response?.status !== 401 &&
        error?.response?.status <= 500
      ) {
        return {
          type: "error",
          message: error.response?.data.message,
        };
      }
      return;
    }
  }
};

export default apiClient;
