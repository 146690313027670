import { Form, Modal } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { fetchAllUserBasedData } from "../../../../../api/authorizeApi";
import commonStyles from "../../../../../assets/styles/commonStyles.module.scss";
import FormFields from "../../../../../components/FormFields/FormFields";
import Loader from "../../../../../components/Loader/Loader";
import StyledButton from "../../../../../components/StyledButton/StyledButton";
import { useAuthCheck } from "../../../../../context/AuthCheckProvider";
import useApiResponse from "../../../../../customHooks/useApiResponse";
import {
  checkIfObjectValuesEmpty,
  formatDate,
} from "../../../../../utils/helperFunctions";
import { DEVICE_TYPES_LIST } from "../../../../../utils/webStaticData";
import styles from "../../Dashboard.module.scss";

interface SensorFilterProps {
  open: boolean;
  onCancel: (filterData?: Record<string, any>) => void;
}
const SensorFilter: React.FC<SensorFilterProps> = ({ open, onCancel }) => {
  const cookiesFilterData = Cookies.get("energy_box_f_d");
  const filterData = cookiesFilterData ? JSON.parse(cookiesFilterData) : {};
  const userAuth = useAuthCheck();
  const userID = userAuth?.userDetails?.id;
  const { apiResponse } = useApiResponse();
  const [form] = Form.useForm();
  const formWatch = Form.useWatch([], form);
  const [grainsList, setGrainsList] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState(false);
  const [locationsList, setLocationsList] = useState<Record<string, any>[]>([]);
  const [parametersList, setParametersList] = useState<Record<string, any>[]>(
    []
  );
  const [sensorsList, setSensorsList] = useState<Record<string, any>[]>([]);
  const [subLocationsList, setSubLocationsList] = useState<
    Record<string, any>[]
  >([]);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isOptionsInitialized, setIsOptionsInitialized] = useState(false);
  const sendFilterData = () => {
    const values = form.getFieldsValue();
    values["user_id"] = userID;
    values["start_date"] = formatDate(
      values["date_range"]
        ? values["date_range"][0].$d
        : formatDate(new Date()),
      "DD-MM-YYYY"
    );
    values["end_date"] = formatDate(
      values["date_range"]
        ? values["date_range"][1].$d
        : formatDate(new Date()),
      "DD-MM-YYYY"
    );
    delete values["date_range"];
    const filterUpdatedValues = { ...values };
    if (
      filterUpdatedValues.start_date === filterUpdatedValues.end_date &&
      filterUpdatedValues.start_date === formatDate(new Date(), "DD-MM-YYYY")
    ) {
      delete filterUpdatedValues["start_date"];
      delete filterUpdatedValues["end_date"];
    }

    Cookies.set("energy_box_f_d", JSON.stringify({ ...filterUpdatedValues }));
    onCancel(values);
  };

  const fetchDataFromAPI = async (type: string, hasParam: boolean = true) => {
    return await apiResponse(
      await fetchAllUserBasedData(
        type,
        null,
        hasParam
          ? {
              user_id: userID,
            }
          : null
      )
    );
  };

  const getAllFormFieldsData = async () => {
    setLoading(true);
    const grainList = await fetchDataFromAPI("grains", false);
    const locationList = await fetchDataFromAPI("get_location");
    const sensorList = await fetchDataFromAPI("allSensersByUser");

    setGrainsList(grainList?.grains ? grainList.grains : []);
    setLocationsList(locationList?.locations ? locationList.locations : []);
    setSensorsList(
      sensorList?.sensors && typeof sensorList?.sensors !== "string"
        ? sensorList.sensors
        : []
    );

    if (filterData?.location_id) {
      subLocationOnLocationChange(filterData?.location_id);
    }
    setLoading(false);
    setIsOptionsInitialized(true);
  };
  useEffect(() => {
    getAllFormFieldsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDisableSubmit(checkIfObjectValuesEmpty(form.getFieldsValue()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formWatch]);

  const handleReset = () => {
    form.setFieldsValue(setFieldValues());
  };

  const setFieldValues = (allFilterData?: Record<string, any>) => {
    const startDate = allFilterData?.start_date?.split("-");
    const endDate = allFilterData?.end_date?.split("-");

    const finalValues = {
      date_range:
        allFilterData?.start_date && allFilterData?.end_date
          ? [
              dayjs()
                .set("date", startDate[0])
                .set("month", startDate[1] - 1)
                .set("year", startDate[2]),
              dayjs()
                .set("date", endDate[0])
                .set("month", endDate[1] - 1)
                .set("year", endDate[2]),
            ]
          : [dayjs(new Date()), dayjs(new Date())],
      location_id: allFilterData?.location_id || null,
      sublocation_id: allFilterData?.sublocation_id || null,
      sensor_id: allFilterData?.sensor_id || null,
      parameter_id: allFilterData?.parameter_id || null,
      grain: allFilterData?.grain || null,
      device_type: allFilterData?.device_type || null,
    };
    return finalValues;
  };

  const subLocationOnLocationChange = async (locationID: string | number) => {
    if (locationID) {
      setLoading(true);
      form.setFieldValue("sublocation_id", null);

      const sublocationList = await fetchDataFromAPI(
        `sublocations\\${locationID}`,
        false
      );
      setSubLocationsList(
        sublocationList?.sublocations ? sublocationList.sublocations : []
      );
      setLoading(false);
    }
  };

  const parametersOnSubLocationChange = async (
    sublocationID: string | number
  ) => {
    if (sublocationID) {
      form.setFieldValue("parameter_id", null);
      setLoading(true);

      const parametersResponse = await fetchDataFromAPI(
        `getParametersbylocation?sublocation_id=${sublocationID}`,
        false
      );
      setParametersList(
        parametersResponse?.parameters ? parametersResponse.parameters : []
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOptionsInitialized) {
      form.setFieldsValue(setFieldValues(filterData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOptionsInitialized]);

  return (
    <Modal
      className={classNames(commonStyles.modal_wrapper)}
      footer={false}
      mask
      maskClosable={false}
      open={open}
      onCancel={() => onCancel()}
      title={<h5>Sensor Filter</h5>}
      style={{ top: "1rem" }}
    >
      {loading && <Loader />}
      <Form layout="vertical" form={form} onFinish={sendFilterData}>
        <div className="modal_form">
          <FormFields
            name="date_range"
            label="Duration"
            placeholder="Select Month"
            $style_type="range-picker"
          />
          <FormFields
            name="location_id"
            label="Location"
            placeholder="Choose Location"
            $style_type="select"
            menuItem={locationsList}
            valueKey="id"
            labelKey="location_name"
            onChangeSelect={subLocationOnLocationChange}
          />
          <FormFields
            name="sublocation_id"
            label="SubLocation"
            placeholder="Choose SubLoaction"
            $style_type="select"
            menuItem={subLocationsList}
            valueKey="id"
            labelKey="location_name"
            onChangeSelect={parametersOnSubLocationChange}
          />
          <FormFields
            name="sensor_id"
            label="Sensor"
            placeholder="Choose Sensor"
            $style_type="select"
            menuItem={sensorsList}
            valueKey="id"
            labelKey="sensor_name"
          />
          <div className={commonStyles.m_b_1}>
            <FormFields
              name="parameter_id"
              label="Parameter"
              placeholder="Choose Parameter"
              $style_type="select"
              menuItem={parametersList}
              valueKey="id"
              labelKey="parameter_name"
              disabled={!form.getFieldValue("sublocation_id")}
              margin="0"
            />
            <i className={styles.infoText}>
              Select location and sublocation to get Parameter list
            </i>
          </div>
          <FormFields
            name="grain"
            menuItem={grainsList}
            label="Grain"
            placeholder="Choose Grain"
            $style_type="select"
          />
          <FormFields
            name="device_type"
            menuItem={DEVICE_TYPES_LIST}
            label="Device Type"
            placeholder="Choose Device Type"
            $style_type="select"
          />
        </div>
        <div className="modal_footer">
          <StyledButton
            label="Clear"
            $style_type="success"
            onClick={handleReset}
          />
          <StyledButton
            htmlType="submit"
            label="Submit"
            disabled={disableSubmit}
          />
          <StyledButton
            label="Close"
            $style_type="secondary"
            onClick={() => onCancel()}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default SensorFilter;
