import { Col, Form, Modal, Row } from "antd";
import { Rule } from "antd/es/form";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import {
  createUpdateResource,
  fetchAllResourcesList,
  fetchAllSuperAdminUserBasedData,
} from "../../../api/authorizeApi";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import FormFields from "../../../components/FormFields/FormFields";
import Loader from "../../../components/Loader/Loader";
import StyledButton from "../../../components/StyledButton/StyledButton";
import { useAuthCheck } from "../../../context/AuthCheckProvider";
import { useSnackBar } from "../../../context/SnackBarProvider";
import useApiResponse from "../../../customHooks/useApiResponse";
import { colors, iconStylesProps } from "../../../utils/helperFunctions";
import { DEVICE_TYPES_LIST, STATUS_LIST } from "../../../utils/webStaticData";
import styles from "./Sensors.module.scss";

interface AddEditUsersProps {
  open: boolean;
  title: string;
  type: string;
  sensorDetails: Record<string, any>;
  onCancel: (refresh?: boolean) => void;
}
const AddEditSensors: React.FC<AddEditUsersProps> = ({
  open,
  title,
  type,
  sensorDetails,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [customersList, setCustomersList] = useState<Record<string, any>[]>([]);
  const [devicesList, setDevicesList] = useState<Record<string, any>[]>([]);
  const [locationList, setLocationList] = useState<Record<string, any>[]>([]);
  const [subLocationList, setSubLocationList] = useState<Record<string, any>[]>(
    []
  );
  const { apiResponse } = useApiResponse();
  const { snackbar } = useSnackBar();
  const userAuth = useAuthCheck();
  const userID = userAuth?.userDetails?.id;
  const emptySensorParameter = {
    sensor_name: null,
    sensor_desc: null,
    sensor_deviceid: null,
    sensor_meterno: null,
    sensor_status: null,
    sensor_mf: 1,
    sensor_mf2: 1,
    sensor_mf3: 1,
    ...(type === "Edit" ? { sensor_detail_id: "" } : {}),
  };

  useEffect(() => {
    const getFieldsMenuData = async () => {
      setLoading(true);
      const customerData = await apiResponse(
        await fetchAllSuperAdminUserBasedData("company")
      );
      const deviceData = await apiResponse(
        await fetchAllResourcesList("device")
      );

      setCustomersList(customerData.result || []);
      setDevicesList(deviceData.result || []);
      setLoading(false);
    };

    getFieldsMenuData();
    if (type === "Edit" && sensorDetails) {
      getLocationsList(sensorDetails?.company_id);
      getSubLocationList(sensorDetails?.location_id, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLocationsList = async (company_id?: string | number) => {
    const currentCompanyId = company_id
      ? company_id
      : form.getFieldValue("company_id");
    if (currentCompanyId) {
      setLoading(true);
      if (!company_id) {
        form.setFieldsValue({ location_id: "", sublocation_id: "" });
      }
      const locationData = await apiResponse(
        await fetchAllSuperAdminUserBasedData(
          "locations_under_company",
          currentCompanyId
        )
      );
      setLocationList(locationData.locations || []);
      setLoading(false);
    }
  };

  const getSubLocationList = async (
    location: string | number,
    onLoad?: boolean
  ) => {
    setLoading(true);
    if (!onLoad) {
      form.setFieldValue("sublocation_id", null);
    }
    const sublocationResp = await apiResponse(
      await fetchAllSuperAdminUserBasedData("sublocations", location)
    );

    setSubLocationList(sublocationResp.sublocations || []);
    setLoading(false);
  };

  const validation: Record<string, Rule[]> = {
    company_id: [{ required: true, message: "Please select customer" }],
    location_id: [{ required: true, message: "Please select location" }],
    // sublocation_id: [{ required: true, message: "Please select sublocation" }],
    device_id: [{ required: true, message: "Please select device" }],
    device_type: [{ required: true, message: "Please select device type" }],
    sensor_name: [
      { required: true, message: "Please enter sensor device name" },
      {
        message: "Comma is not allowed",
        pattern: new RegExp(/^(?![\s\S]*,)[\s\S]*$/),
      },
    ],
    sensor_desc: [
      { required: true, message: "Please enter sensor description" },
      {
        message: "Comma is not allowed",
        pattern: new RegExp(/^(?![\s\S]*,)[\s\S]*$/),
      },
    ],
    sensor_deviceid: [
      { required: true, message: "Please enter sensor device ID" },
      {
        message: "Comma is not allowed",
        pattern: new RegExp(/^(?![\s\S]*,)[\s\S]*$/),
      },
    ],
    sensor_meterno: [
      { required: true, message: "Please enter sensor meter number" },
      {
        message: "Comma is not allowed",
        pattern: new RegExp(/^(?![\s\S]*,)[\s\S]*$/),
      },
    ],
    sensor_status: [
      { required: true, message: "Please enter sensor status" },
      {
        message: "Comma is not allowed",
        pattern: new RegExp(/^(?![\s\S]*,)[\s\S]*$/),
      },
    ],
    sensor_mf: [
      { required: true, message: "Please enter sensor multiplying factor" },
      {
        pattern: new RegExp(/^[1-9]\d*$/),
        message: "Please enter a positive number not less than 1",
      },
    ],
  };

  const sendAddEditSensorsRequest = async () => {
    const values = form.getFieldsValue();
    let payload: Record<string, any> = {
      company_id: +values["company_id"],
      device_id: +values["device_id"],
      device_type: values["device_type"],
      location_id: +values["location_id"],
      sensor_details: JSON.stringify(values["sensorParameter"]),
      sublocation_id: +values["sublocation_id"],
      user_id: +userID!,
    };
    setLoading(true);
    let createUpdateResponse = await createUpdateResource(
      "sensor",
      payload,
      type === "Edit" ? "update" : "create",
      type === "Edit" && sensorDetails.id
    );
    const response = await apiResponse(createUpdateResponse);
    setLoading(false);
    if (response) {
      handleCancel(true);
      snackbar("success", response.message);
    }
  };

  const setFieldsValues = () => {
    if (sensorDetails) {
      const sensor_parameter = sensorDetails?.sensordetails?.map(
        (sensor: Record<string, any>) => {
          return {
            sensor_name: sensor?.sensor_name || null,
            sensor_desc: sensor?.sensor_desc || null,
            sensor_deviceid: sensor?.sensor_deviceid || null,
            sensor_meterno: sensor?.sensor_meterno || null,
            sensor_status: sensor?.sensor_status || null,
            sensor_mf: sensor?.sensor_mf || 1,
            sensor_mf2: sensor?.sensor_mf2 || 1,
            sensor_mf3: sensor?.sensor_mf3 || 1,
            ...(type === "Edit" ? { sensor_detail_id: sensor?.id } : {}),
          };
        }
      );
      return {
        company_id: +sensorDetails?.company_id || null,
        location_id: +sensorDetails?.location_id || null,
        sublocation_id: +sensorDetails?.sublocation_id || null,
        device_id: +sensorDetails?.device_id || null,
        device_type: sensorDetails?.device_type || null,
        sensorParameter:
          sensor_parameter && sensor_parameter.length
            ? sensor_parameter
            : [emptySensorParameter],
      };
    }
  };

  const handleReset = () => {
    if (sensorDetails && Object.keys(sensorDetails).length) {
      getLocationsList(sensorDetails.company_id);
      form.setFieldsValue(setFieldsValues());
    } else {
      form.resetFields();
    }
  };

  const handleCancel = (refreshList: boolean = false) => {
    onCancel(refreshList);
    form.resetFields();
  };
  return (
    <Modal
      className={classNames(commonStyles.modal_wrapper, commonStyles.wideModal)}
      footer={false}
      mask
      maskClosable={false}
      open={open}
      onCancel={() => handleCancel()}
      title={<h5>{title}</h5>}
      style={{ top: "1rem", width: "60vw" }}
    >
      {loading && <Loader />}
      <Form
        layout="vertical"
        form={form}
        onFinish={sendAddEditSensorsRequest}
        initialValues={!loading ? setFieldsValues() : {}}
      >
        <div className="modal_form">
          <Row gutter={[16, 0]}>
            <Col md={12} xs={24}>
              <FormFields
                name="company_id"
                label="Select Customer"
                placeholder="Choose Customer"
                $style_type="select"
                showSearch={true}
                menuItem={customersList}
                valueKey="id"
                labelKey="name"
                rules={validation["company_id"]}
                onChangeSelect={() => getLocationsList()}
              />
            </Col>
            <Col md={12} xs={24}>
              <FormFields
                name="location_id"
                label="Select Location"
                placeholder="Choose Location"
                $style_type="select"
                showSearch={true}
                menuItem={locationList}
                valueKey="id"
                labelKey="location_name"
                rules={validation["location_id"]}
                onChangeSelect={(value) => getSubLocationList(value)}
              />
            </Col>
            <Col md={12} xs={24}>
              <FormFields
                name="sublocation_id"
                label="Select Sublocation"
                placeholder="Choose Sublocation"
                $style_type="select"
                showSearch={true}
                menuItem={subLocationList}
                valueKey="id"
                labelKey="location_name"
                rules={validation["sublocation_id"]}
              />
            </Col>
            <Col md={12} xs={24}>
              <FormFields
                name="device_id"
                label="Select Device"
                placeholder="Choose Device"
                $style_type="select"
                showSearch={true}
                menuItem={devicesList}
                valueKey="id"
                labelKey="device_name"
                rules={validation["device_id"]}
              />
            </Col>
            <Col md={12} xs={24}>
              <FormFields
                name="device_type"
                label="Device Type"
                placeholder="Choose Device Type"
                $style_type="select"
                showSearch={true}
                menuItem={DEVICE_TYPES_LIST}
                rules={validation["device_type"]}
              />
            </Col>
          </Row>

          <h6 className={commonStyles.m_t_3}>Sensor Details</h6>
          <Form.List name="sensorParameter">
            {(fields, { add, remove }) => (
              <>
                {type !== "Show" && (
                  <StyledButton
                    label="Add"
                    onClick={() => add(emptySensorParameter)}
                    margin="1rem 0"
                  />
                )}

                {fields?.map(({ key, name, ...restField }) => (
                  <>
                    <h6
                      className={classNames(
                        styles.sensorDetail,
                        commonStyles.m_b_0_5,
                        commonStyles.m_t_1_5,
                        commonStyles.display_flex,
                        commonStyles.display_center_spacebetween,
                        commonStyles.gap_2
                      )}
                    >
                      Sensor Detail: {name + 1}
                      {fields.length !== 1 ? (
                        <FiTrash2
                          {...iconStylesProps({
                            fontSize: "1.1rem",
                            width: "1.3rem",
                            color: colors("red2"),
                            cursor: "pointer",
                            margin: "0",
                          })}
                          onClick={() => remove(name)}
                        />
                      ) : (
                        <></>
                      )}
                    </h6>
                    <Row gutter={[16, 0]}>
                      <Col md={12} xs={24}>
                        <FormFields
                          key={`Sensor-Device-${key}`}
                          name={[name, "sensor_name"]}
                          label="Sensor Device"
                          placeholder="Enter Sensor Device"
                          rules={validation["sensor_name"]}
                        />
                      </Col>
                      <Col md={12} xs={24}>
                        <FormFields
                          key={`Sensor-Description-${key}`}
                          name={[name, "sensor_desc"]}
                          label="Sensor Description"
                          placeholder="Enter Sensor Description"
                          rules={validation["sensor_desc"]}
                        />
                      </Col>
                      <Col md={12} xs={24}>
                        <FormFields
                          key={`Sensor-Device-ID-${key}`}
                          name={[name, "sensor_deviceid"]}
                          label="Sensor Device ID"
                          placeholder="Enter Sensor Device ID"
                          rules={validation["sensor_deviceid"]}
                        />
                      </Col>
                      <Col md={12} xs={24}>
                        <FormFields
                          key={`Sensor-Meter-NO-${key}`}
                          name={[name, "sensor_meterno"]}
                          label="Sensor Meter NO"
                          placeholder="Enter Sensor Meter NO"
                          rules={validation["sensor_meterno"]}
                        />
                      </Col>
                      <Col md={12} xs={24}>
                        <FormFields
                          key={`Multiplying-Factor-1-${key}`}
                          name={[name, "sensor_mf"]}
                          label="Multiplying Factor 1"
                          placeholder="Enter Multiplying Factor 1"
                          rules={validation["sensor_mf"]}
                        />
                      </Col>
                      <Col md={12} xs={24}>
                        <FormFields
                          key={`Multiplying-Factor-2-${key}`}
                          name={[name, "sensor_mf2"]}
                          label="Multiplying Factor 2"
                          placeholder="Enter Multiplying Factor 2"
                          rules={validation["sensor_mf"]}
                        />
                      </Col>
                      <Col md={12} xs={24}>
                        <FormFields
                          key={`Multiplying-Factor-3-${key}`}
                          name={[name, "sensor_mf3"]}
                          label="Multiplying Factor 3"
                          placeholder="Enter Multiplying Factor 3"
                          rules={validation["sensor_mf"]}
                        />
                      </Col>
                      <Col md={12} xs={24}>
                        <FormFields
                          key={`Sensor-Sensor-${key}`}
                          name={[name, "sensor_status"]}
                          label="Status"
                          placeholder="Choose Status"
                          $style_type="select"
                          valueKey="id"
                          labelKey="name"
                          menuItem={STATUS_LIST}
                          rules={validation["sensor_status"]}
                        />
                      </Col>
                    </Row>
                  </>
                ))}
              </>
            )}
          </Form.List>
        </div>
        <div className="modal_footer">
          {type !== "Show" && (
            <>
              <StyledButton
                label="Reset"
                $style_type="ghost"
                onClick={handleReset}
              />
              <StyledButton
                htmlType="submit"
                label="Submit"
                disabled={loading}
              />
            </>
          )}
          <StyledButton
            label="Close"
            $style_type="secondary"
            onClick={() => handleCancel()}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default AddEditSensors;
