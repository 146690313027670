import { Card, Dropdown, MenuProps, Spin } from "antd";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import {
  fetchAllUserBasedData,
  userBasedResourceActions,
} from "../../../api/authorizeApi";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import StyledButton from "../../../components/StyledButton/StyledButton";
import { useAuthCheck } from "../../../context/AuthCheckProvider";
import { useSnackBar } from "../../../context/SnackBarProvider";
import useApiResponse from "../../../customHooks/useApiResponse";
import { CALIBRATION_MODE } from "../../../utils/webStaticData";
import ClockCalibration from "./ClockCalibration";
import LDRCalibration from "./LDRCalibration";
import ManualCalibration from "./ManualCalibration";

const Calibration = () => {
  const userAuth = useAuthCheck();
  const userID = userAuth?.userDetails?.id;
  const { apiResponse } = useApiResponse();
  const [loading, setLoading] = useState(false);
  const [calibrationList, setCalibrationList] = useState<Record<string, any>[]>(
    []
  );
  const [selectedMode, setSelectedMode] = useState("ldr");
  const { snackbar } = useSnackBar();

  const ChangeModeList: MenuProps = {
    items: [
      {
        key: "ldr",
        label: "LDR",
      },
      {
        key: "clock",
        label: "Clock",
      },
      {
        key: "manual",
        label: "Manual",
      },
    ],
    selectable: true,
    defaultSelectedKeys: [selectedMode!],
    onClick: (e) => {
      handleMode(e.key);
    },
  };

  const handleMode = async (mode: string) => {
    if (calibrationList?.length) {
      let response = await apiResponse(
        await userBasedResourceActions("admin/change_mode", "post", null, {
          mode: mode,
          id: calibrationList[0]?.id,
        })
      );
      setLoading(false);
      if (response) {
        snackbar("success", response.message);
        setSelectedMode(mode);
      }
    }
  };

  const getFielsData = async () => {
    setLoading(true);
    let response = await apiResponse(
      await fetchAllUserBasedData("all_controller_claibration", null, {
        user_id: userID,
      })
    );

    setCalibrationList(response.Record);
    setSelectedMode(response.mode || "ldr");
    setLoading(false);
  };

  useEffect(() => {
    getFielsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <Spin spinning={loading}>
        <Card
          title={`${CALIBRATION_MODE[selectedMode]} Calibration`}
          extra={
            <div
              className={classNames(
                commonStyles.display_flex,
                commonStyles.display_a_center,
                commonStyles.gap_2
              )}
            >
              <Dropdown menu={ChangeModeList} trigger={["click"]}>
                <StyledButton
                  label="Change Mode"
                  sufixIcon={<FiChevronDown />}
                />
              </Dropdown>
            </div>
          }
          className={classNames(commonStyles.card_wrapper, commonStyles.m_b_1)}
        >
          {selectedMode === "ldr" && (
            <LDRCalibration
              mode={selectedMode}
              calibrations={calibrationList}
              sendMode={(mode: string) => setSelectedMode(mode)}
            />
          )}
          {selectedMode === "clock" && (
            <ClockCalibration
              mode={selectedMode}
              calibrations={calibrationList}
              isRefreshed={(refresh) => refresh && getFielsData()}
            />
          )}
          {selectedMode === "manual" && (
            <ManualCalibration
              mode={selectedMode}
              calibrations={calibrationList}
              isRefreshed={(refresh) => refresh && getFielsData()}
            />
          )}
        </Card>
      </Spin>
    </main>
  );
};
export default Calibration;
