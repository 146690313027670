import { Card, Form } from "antd";
import { Rule } from "antd/es/form";
import classNames from "classnames";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../../api/unAuthorizeApi";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import FormFields from "../../../components/FormFields/FormFields";
import SwitchField from "../../../components/FormFields/SwitchField";
import StyledButton from "../../../components/StyledButton/StyledButton";
import { useAuthCheck } from "../../../context/AuthCheckProvider";
import useApiResponse from "../../../customHooks/useApiResponse";
import styles from "./Login.module.scss";

const Login = () => {
  const [form] = Form.useForm();
  const navigateTo = useNavigate();
  const { apiResponse } = useApiResponse();
  const authCheck = useAuthCheck();
  const handleLogin = async () => {
    const values = form.getFieldsValue();
    const payload = {
      email: values.email,
      password: values.password,
    };
    const loginData = await loginUser(payload);
    const response = await apiResponse(loginData);
    if (response) {
      authCheck?.loggedIn(response);
      navigateTo("/");
    }
  };

  const validation: Record<string, Rule[]> = {
    email: [
      { required: true, message: "Please enter the email ID" },
      { type: "email", message: "Please enter valid email ID" },
    ],
    password: [{ required: true, message: "Please enter password" }],
  };

  return (
    <main
      className={classNames(
        commonStyles.display_flex,
        commonStyles.display_j_center
      )}
    >
      <Card
        className={classNames(
          commonStyles.text_align_center,
          styles.login_card
        )}
      >
        <img
          alt="energy-box-logo"
          src={require("../../../assets/images/energy-box.png")}
          className={commonStyles.m_b_1}
        />
        <p>Please log in to your account</p>
        <Form form={form} layout="vertical" onFinish={handleLogin}>
          <FormFields
            $style_type="input"
            name="email"
            label="Email"
            placeholder="jhon@placeholder.com"
            rules={validation["email"]}
          />
          <FormFields
            $style_type="password"
            name="password"
            label="Password"
            placeholder="Enter Password"
            iconRender={(visible) => (visible ? <FiEye /> : <FiEyeOff />)}
            type="password"
            rules={validation["password"]}
          />

          <div
            className={classNames(
              commonStyles.display_flex,
              commonStyles.display_j_spacebetween
            )}
          >
            <SwitchField label="Remember me" name="rememberMe" />
            <a href="/forgot-password">Forgot Password?</a>
          </div>

          <StyledButton htmlType="submit" label="Sign In" width="100%" />
        </Form>
      </Card>
    </main>
  );
};

export default Login;
