import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useAuthCheck } from "../context/AuthCheckProvider";
import SuperAdminRoutes from "./SuperAdminRoutes";
import UsersAdminRoutes from "./UsersAdminRoutes";

const AuthorizedRoutes = () => {
  const authCheck = useAuthCheck();
  return !authCheck?.isAuthorized() ? (
    <Navigate to="/login" />
  ) : (
    <Routes>
      <Route element={<Outlet />}>
        {authCheck?.userDetails && (
          <Route
            element={
              authCheck?.userDetails?.role === "superadmin" ? (
                <SuperAdminRoutes />
              ) : (
                <UsersAdminRoutes />
              )
            }
            path="*"
          />
        )}
      </Route>
    </Routes>
  );
};

export default AuthorizedRoutes;
