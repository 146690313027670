import { Card, Spin, Table } from "antd";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  fetchAllSuperAdminUserBasedData,
  fetchAllUserBasedData,
  fetchUsersList,
  userBasedResourceActions,
} from "../../../api/authorizeApi";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import StyledButton from "../../../components/StyledButton/StyledButton";
import { useAuthCheck } from "../../../context/AuthCheckProvider";
import { useSnackBar } from "../../../context/SnackBarProvider";
import useApiResponse from "../../../customHooks/useApiResponse";
import { USERS_COLUMNS } from "../../../utils/webTableConfig";
import AddEditUsers from "./AddEditUsers";
import styles from "./Users.module.scss";

const Users = () => {
  const [actionType, setActionType] = useState("");
  const { apiResponse } = useApiResponse();
  const { clientID, adminID, workingAs } = useParams();
  const [adminName, setAdminName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [currentScreen, setCurrentScreen] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [modalTitle, setModalTitle] = useState("");
  const navigateTo = useNavigate();
  const [selectedUser, setSelectedUser] = useState<Record<string, any>>({});
  const [showAddEditUsers, setShowAddEditUsers] = useState(false);
  const { snackbar } = useSnackBar();
  const userAuth = useAuthCheck();
  const userID = userAuth?.userDetails?.id;
  const userRole = userAuth?.userDetails?.role;
  const [usersList, setUsersList] = useState([]);

  const getAdminList = async (clientID?: number | string) => {
    if (clientID) {
      const admins = await apiResponse(
        await fetchAllUserBasedData("superadmin/get_all_admins", null, {
          company_id: clientID,
        })
      );
      return admins?.users || [];
    }
  };

  const getClientList = async (currentCompanyId = clientID) => {
    if (currentCompanyId) {
      const clients = await apiResponse(
        await fetchAllSuperAdminUserBasedData("company")
      );
      if (clients && clients.success && Array.isArray(clients.result)) {
        const clientsList = clients?.result || [];
        setCompanyName(
          clientsList.find(
            (client: Record<string, any>) => client.id === +currentCompanyId
          )?.name
        );
      }
    }
  };
  const getAdminName = (adminsList: Record<string, any>[]) => {
    if (adminID && adminsList?.length) {
      setAdminName(
        adminsList.find((admin: Record<string, any>) => admin.id === +adminID)
          ?.name
      );
    }
  };

  const getUsersData = async () => {
    setLoading(true);
    if (userRole === "superadmin") {
      if (location.pathname?.includes("superadmin/manage-users/admin")) {
        setCurrentScreen("adminInSuperAdmin");
        setUsersList(await getAdminList(clientID));
        getClientList();
      } else if (location.pathname?.includes("superadmin/manage-users/users")) {
        setCurrentScreen("usersInAdmin");
        let usersData = await apiResponse(
          await fetchAllSuperAdminUserBasedData("users_under_admin", adminID)
        );
        setUsersList(usersData?.users || []);
        getAdminName(await getAdminList(clientID));
        getClientList();
      } else if (location.pathname?.includes("superadmin/manage-users")) {
        setCurrentScreen("allUsersInSuperAdmin");
        let usersData = await apiResponse(
          await fetchAllUserBasedData("getAllUser")
        );
        setUsersList(usersData?.users || []);
      }
    } else if (userRole === "admin") {
      setCurrentScreen("users");
      if (userID) {
        let usersData = await apiResponse(
          await fetchUsersList({ userid: userID })
        );
        setUsersList(usersData?.users || []);
        getClientList(userAuth?.userDetails?.company_id);
      }
    }
    setLoading(false);
  };

  const handleAddEditUsers = (title?: string, refresh?: boolean) => {
    if (title) {
      setModalTitle(title);
    }
    setShowAddEditUsers((prev) => !prev);
    if (refresh) {
      getUsersData();
    }
  };

  const handleTableActions = (
    type: string,
    rowData: any,
    checked?: boolean
  ) => {
    setSelectedUser(rowData || {});
    setActionType(type);
    switch (type) {
      case "Edit":
        handleAddEditUsers(
          currentScreen === "adminInSuperAdmin" ? "Edit Admin" : "Edit User"
        );
        break;
      case "Show":
        handleAddEditUsers(
          currentScreen === "adminInSuperAdmin"
            ? "Show Admin Details"
            : "Show User Details"
        );
        break;
      case "Redirect":
        navigateTo(
          `/superadmin/manage-users/users/${rowData.company_id}/${rowData.id}/${
            rowData.working_as === "iot"
              ? 0
              : rowData.working_as === "street_light"
              ? 1
              : 2
          }`
        );
        break;
      case "MovingSP":
        handleMovingSP(rowData);
        break;
      case "ControllerAdded":
        handleControllerAdded(rowData);
        break;
      case "Active":
        handleUserStatus(rowData);
        break;
      case "Delete":
        handleDeleteUser(+rowData.id);
        break;
      default:
        break;
    }
  };

  const handleUserStatus = async (rowData: Record<string, any>) => {
    const url = `${userRole}/user_status_change`;
    const body = {
      user_id: rowData.id,
      status: [1, "1"].includes(rowData.status) ? 0 : 1,
    };
    let response = await apiResponse(
      await userBasedResourceActions(url, "patch", null, body)
    );
    if (response) {
      snackbar("success", response.message);
      getUsersData();
    }
  };

  const handleControllerAdded = async (rowData: Record<string, any>) => {
    const url = `${userRole}/is_controller_added`;
    const body = {
      user_id: rowData.id,
      enable_controller: [1, "1"].includes(rowData.enable_controller) ? 0 : 1,
    };
    let response = await apiResponse(
      await userBasedResourceActions(url, "patch", null, body)
    );
    if (response) {
      snackbar("success", response.message);
      getUsersData();
    }
  };

  const handleMovingSP = async (rowData: Record<string, any>) => {
    const url = `${userRole}/moving_sp_status_change`;
    const body = {
      user_id: rowData.id,
      status: [1, "1"].includes(rowData.enable_sp_controller) ? 0 : 1,
    };
    let response = await apiResponse(
      await userBasedResourceActions(url, "patch", null, body)
    );
    if (response) {
      snackbar("success", response.message);
      getUsersData();
    }
  };

  const handleDeleteUser = async (id: number) => {
    setLoading(true);
    const response = await apiResponse(
      await userBasedResourceActions(`${userRole}/users/delete`, "delete", id)
    );
    snackbar(response?.success ? "success" : "error", response.message);
    if (response?.success) {
      getUsersData();
    }
    setLoading(false);
  };

  useEffect(() => {
    getUsersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientID, adminID, location, userID]);
  return (
    <main>
      <Card
        title={
          <>
            <p>
              {currentScreen === "adminInSuperAdmin"
                ? "Admins"
                : currentScreen === "allUsersInSuperAdmin"
                ? "All Users"
                : "Users"}
            </p>
            {currentScreen !== "allUsersInSuperAdmin" && (
              <p style={{ fontSize: "14px", fontWeight: 400 }}>
                <b>Company:</b> {companyName || "-"}
                {!["adminInSuperAdmin", "users"].includes(currentScreen) && (
                  <span style={{ marginLeft: "4px" }}>
                    | <b>Admin:</b> {adminName || "-"}
                  </span>
                )}
              </p>
            )}
          </>
        }
        extra={
          <StyledButton
            label={
              currentScreen === "adminInSuperAdmin" ? "Add Admin" : "Add User"
            }
            onClick={() => {
              handleAddEditUsers(
                currentScreen === "adminInSuperAdmin"
                  ? "Create New Admin"
                  : "Create New User"
              );
              setActionType("");
              setSelectedUser({});
            }}
          />
        }
        className={classNames(commonStyles.card_wrapper, styles.sensors_card)}
      >
        <Spin spinning={loading}>
          <Table
            dataSource={usersList}
            columns={USERS_COLUMNS(currentScreen, handleTableActions)}
            pagination={false}
            className={classNames(commonStyles.table_wrap, styles.sensor_table)}
          />
        </Spin>
      </Card>
      {showAddEditUsers && (
        <AddEditUsers
          adminID={adminID ? +adminID : null}
          clientID={clientID ? +clientID : null}
          currentScreen={currentScreen}
          title={modalTitle}
          type={actionType}
          usersDetails={selectedUser}
          open={showAddEditUsers}
          onCancel={(refresh) => handleAddEditUsers("", refresh)}
          workingAs={
            workingAs
              ? workingAs === "0"
                ? "iot"
                : workingAs === "1"
                ? "street_light"
                : "both"
              : null
          }
        />
      )}
    </main>
  );
};
export default Users;
