import { Col, Form, Modal, Row } from "antd";
import { Rule } from "antd/es/form";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import {
  fetchAllSuperAdminUserBasedData,
  fetchAllUserBasedData,
  userBasedResourceActions,
} from "../../../api/authorizeApi";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import FormFields from "../../../components/FormFields/FormFields";
import SwitchField from "../../../components/FormFields/SwitchField";
import Loader from "../../../components/Loader/Loader";
import StyledButton from "../../../components/StyledButton/StyledButton";
import { useAuthCheck } from "../../../context/AuthCheckProvider";
import { useSnackBar } from "../../../context/SnackBarProvider";
import useApiResponse from "../../../customHooks/useApiResponse";
import {
  arrayOfValuesForKey,
  validatePassword,
} from "../../../utils/helperFunctions";
import { ROLES_LIST, USER_WORKING_AS_LIST } from "../../../utils/webStaticData";
import ViewUser from "./ViewUser";

interface AddEditUsersProps {
  adminID: number | null;
  clientID: number | null;
  open: boolean;
  onCancel: (refresh?: boolean) => void;
  title: string;
  type: string;
  usersDetails: Record<string, any>;
  currentScreen: string;
  workingAs: string | null;
}
const AddEditUsers: React.FC<AddEditUsersProps> = ({
  adminID,
  clientID,
  open,
  onCancel,
  title,
  type,
  usersDetails,
  currentScreen,
  workingAs,
}) => {
  const [actionType, setActionType] = useState(type);
  const [modalTitle, setModalTitle] = useState(title);
  const [adminsList, setAdminsList] = useState<Record<string, any>[]>([]);
  const { apiResponse } = useApiResponse();
  const [clientsList, setClientsList] = useState<Record<string, any>[]>([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [locationList, setLocationList] = useState<Record<string, any>[]>([]);
  const [subLocationList, setSubLocationList] = useState<Record<string, any>[]>(
    []
  );
  const [selectedRole, setSelectedRole] = useState<string | null>(null);
  const { snackbar } = useSnackBar();
  const userAuth = useAuthCheck();
  const userID = userAuth?.userDetails?.id;
  const [hasCompanyID, setHasCompanyID] = useState(false);
  const [companyName, setCompanyName] = useState();
  const [adminName, setAdminName] = useState();

  const handleReset = () => {
    if (usersDetails && Object.keys(usersDetails).length) {
      if (currentScreen === "users") {
        getLocationsList(usersDetails.parent_id);
      } else {
        getLocationsList(usersDetails.company_id);
      }
      form.setFieldsValue(setFieldsValues());
    } else {
      form.resetFields();
    }
  };

  const getAdminList = async (clientID: number | string) => {
    const admins = await apiResponse(
      await fetchAllUserBasedData("superadmin/get_all_admins", null, {
        company_id: clientID,
      })
    );
    setAdminsList(admins?.users);
    setAdminName(
      admins?.users?.find(
        (admin: Record<string, any>) => usersDetails?.parent_id === admin.id
      )?.name
    );
  };

  const getLocationsList = async (company_id?: string | number) => {
    const currentCompanyId = company_id
      ? company_id
      : form.getFieldValue("company_id");
    if (currentCompanyId) {
      setLoading(true);
      if (!company_id) {
        form.setFieldsValue({ locations: [], sublocations: [] });
      }
      const locationData = await apiResponse(
        await fetchAllUserBasedData(
          userAuth?.userDetails?.role === "superadmin"
            ? "superadmin/locations_under_company"
            : "get_location",
          userAuth?.userDetails?.role === "superadmin"
            ? currentCompanyId
            : null,
          userAuth?.userDetails?.role === "admin"
            ? { user_id: currentCompanyId }
            : null
        )
      );
      setLocationList(locationData.locations || []);
      setLoading(false);
    }
  };

  const getSubLocationList = async (
    location: string | number,
    onLoad?: boolean
  ) => {
    if (location) {
      setLoading(true);
      if (!onLoad) {
        form.setFieldValue("sublocations", []);
      }
      const sublocationResp = await apiResponse(
        await fetchAllUserBasedData(
          userAuth?.userDetails?.role === "superadmin"
            ? "superadmin/sublocations"
            : "sublocations",
          location
        )
      );

      setSubLocationList((prev) => [
        ...prev,
        ...(sublocationResp.sublocations || []),
      ]);
      setLoading(false);
    }
  };

  const onAdminChange = (admin_id: string | number) => {
    const adminWorkingAs = adminsList?.find(
      (admin) => admin.id === admin_id
    )?.working_as;
    form.setFieldValue("working_as", adminWorkingAs);
  };

  const onCompanyChange = (company_id: string | number) => {
    setAdminsList([]);
    setLocationList([]);
    setSubLocationList([]);
    form.setFieldsValue({ locations: [], parent_id: null, sublocations: [] });
    if (company_id) {
      getAdminList(company_id);
      getLocationsList(company_id);
    }
  };

  const onLocationChange = async (locations: string[] | number[]) => {
    form.setFieldValue("locations", locations);
    setSubLocationList([]);
    form.setFieldValue("sublocations", []);
    locations?.forEach((location: string | number) => {
      getSubLocationList(location, true);
    });
  };

  const onRoleChange = (role: string) => {
    setSelectedRole(role);
    if (role === "admin" && currentScreen === "allUsersInSuperAdmin") {
      form.setFieldsValue({ parent_id: 0, working_as: null });
    } else {
      form.setFieldsValue({ parent_id: null, working_as: null });
    }
  };

  const sendAddEditUserRequest = async () => {
    const values = form.getFieldsValue();
    values["enable_controller"] = values["enable_controller"] ? "1" : "0";
    values["enable_sp_controller"] = values["enable_sp_controller"] ? "1" : "0";
    values["status"] = values["status"] ? "1" : "0";
    values["locations"] = JSON.stringify(values["locations"]);
    values["sublocations"] = JSON.stringify(values["sublocations"]);
    values["parent_id"] = setParentID(values["parent_id"]);

    if (actionType === "Edit") {
      values["id"] = usersDetails.id;
    }

    setLoading(true);
    const response = await apiResponse(
      await userBasedResourceActions(
        setURL(),
        actionType === "Edit" ? "patch" : "post",
        actionType === "Edit" ? usersDetails.id : null,
        values
      )
    );
    setLoading(false);
    if (response) {
      onCancel(true);
      form.resetFields();
      snackbar("success", response.message);
    }
  };

  const setCompanyID = (company_id?: string | number | null) => {
    if (company_id) {
      return company_id;
    } else if (
      currentScreen === "adminInSuperAdmin" ||
      currentScreen === "usersInAdmin"
    ) {
      return clientID;
    } else if (currentScreen === "users") {
      return userAuth?.userDetails?.company_id;
    } else return null;
  };

  const setFieldsValues = () => {
    return {
      company_id: setCompanyID(usersDetails?.company_id) || null,
      email: usersDetails?.email || null,
      enable_controller: [1, "1"].includes(usersDetails?.enable_controller)
        ? true
        : false,
      enable_sp_controller: [1, "1"].includes(
        usersDetails?.enable_sp_controller
      )
        ? true
        : false,
      locations: usersDetails?.locations?.length
        ? arrayOfValuesForKey(usersDetails?.locations, "id")
        : [],
      sublocations: usersDetails?.locations?.length
        ? usersDetails?.locations
            .map((location: Record<string, any>) =>
              arrayOfValuesForKey(location?.sublocations || [], "id")
            )
            .flat()
        : [],
      name: usersDetails?.name || null,
      password: usersDetails?.password || null,
      parent_id: setParentID(usersDetails.parent_id) || null,
      phone: usersDetails?.phone || null,
      role: setRole(usersDetails?.role) || null,
      status: !actionType
        ? true
        : [1, "1"].includes(usersDetails?.status)
        ? true
        : false,
      working_as: setWorkingAs(usersDetails?.working_as),
      zip: usersDetails?.zip || null,
    };
  };

  const setParentID = (parent_id?: string | number) => {
    if (parent_id || parent_id === 0) {
      return parent_id;
    } else if (currentScreen === "users") {
      return userID;
    } else if (currentScreen === "usersInAdmin") {
      return adminID;
    } else if (
      currentScreen === "adminInSuperAdmin" ||
      (selectedRole === "admin" && currentScreen === "allUsersInSuperAdmin")
    ) {
      return 0;
    } else return null;
  };

  const setRole = (role?: string) => {
    if (role) {
      return role;
    } else if (currentScreen === "user" || currentScreen === "usersInAdmin") {
      return "customer";
    } else if (currentScreen === "adminInSuperAdmin") {
      return "admin";
    } else return null;
  };

  const setURL = () => {
    if (actionType === "Edit") {
      return currentScreen === "users"
        ? "admin/users/update"
        : "superadmin/users/update";
    } else {
      return currentScreen === "users"
        ? "admin/users/create"
        : "superadmin/users/create";
    }
  };

  const setWorkingAs = (working_as?: string) => {
    if (working_as) {
      return working_as;
    } else if (currentScreen === "users") {
      return userAuth?.userDetails?.working_as;
    } else if (currentScreen === "usersInAdmin") {
      return workingAs;
    } else return null;
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);

      const clients = await apiResponse(
        await fetchAllSuperAdminUserBasedData("company")
      );
      setClientsList(clients?.result || []);
      // Get the Admin and Location list based on screens
      if (
        clientID &&
        ["adminInSuperAdmin", "usersInAdmin"].includes(currentScreen)
      ) {
        getLocationsList(clientID);
        if (currentScreen === "usersInAdmin") {
          getAdminList(clientID);
        }
      } else if (currentScreen === "users") {
        getLocationsList(userAuth?.userDetails?.id);
      } else {
        getLocationsList(usersDetails?.company_id);
        getAdminList(usersDetails?.company_id);
      }

      // Get Subslocation list in edit mode
      if (actionType === "Edit" && usersDetails && usersDetails.locations) {
        setSubLocationList([]);
        if (usersDetails?.locations?.length) {
          usersDetails?.locations?.forEach((location: Record<string, any>) => {
            if (location && location.id) getSubLocationList(location.id, true);
          });
        }
      }
      setCompanyName(
        clients?.result?.find(
          (client: Record<string, any>) =>
            usersDetails?.company_id === client.id
        )?.name
      );
      setLoading(false);
    };
    getData();
    setSelectedRole(setRole(usersDetails?.role));
    setHasCompanyID(!!setCompanyID());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validation: Record<string, Rule[]> = {
    email: [
      { required: true, message: "Please enter the email ID" },
      { type: "email", message: "Please enter valid email ID" },
    ],
    locations: [{ required: true, message: "Please select location" }],
    sublocations: [{ required: true, message: "Please select sublocation" }],
    name: [{ required: true, message: "Please enter name" }],
    parent_id: [{ required: true, message: "Please select admin" }],
    phone: [
      { required: true, message: "Please enter phone number" },
      {
        message: "Please enter valid phone number",
        pattern: new RegExp(/^\d{10}$/),
      },
    ],
    role: [{ required: true, message: "Please select role" }],
    working_as: [{ required: true, message: "Please select user working as" }],
    password: [{ validator: validatePassword }],
  };

  return (
    <Modal
      className={classNames(commonStyles.modal_wrapper, commonStyles.wideModal)}
      closeIcon={false}
      footer={false}
      mask
      maskClosable={false}
      open={open}
      onCancel={() => onCancel()}
      title={
        <div
          className={classNames(
            commonStyles.display_flex,
            commonStyles.display_a_center,
            commonStyles.gap_3
          )}
        >
          <h5>{modalTitle}</h5>
          {actionType && (
            <StyledButton
              margin="0 0 0 auto"
              htmlType="button"
              label={actionType === "Show" ? "Edit" : "View"}
              disabled={loading}
              onClick={() => {
                setActionType(actionType === "Show" ? "Edit" : "Show");
                setModalTitle("Edit User");
              }}
            />
          )}
          {actionType === "Show" && (
            <StyledButton
              label="Close"
              $style_type="secondary"
              onClick={() => onCancel()}
            />
          )}
        </div>
      }
      style={{ top: "1rem" }}
    >
      {loading && <Loader />}
      {actionType !== "Show" ? (
        <Form
          form={form}
          initialValues={setFieldsValues()}
          layout="vertical"
          onFinish={sendAddEditUserRequest}
        >
          <div className="modal_form">
            <Row gutter={[16, 0]}>
              <Col md={12} xs={24}>
                <FormFields
                  name="company_id"
                  label="Client"
                  placeholder="Choose Client"
                  $style_type="select"
                  menuItem={clientsList}
                  valueKey="id"
                  labelKey="name"
                  disabled={
                    hasCompanyID
                    // (!(actionType === "Edit" && !usersDetails?.company_id) &&
                    //   !(currentScreen === "allUsersInSuperAdmin")) ||
                    // (currentScreen === "users" && userAuth?.userDetails?.company_id)
                  }
                  onChangeSelect={(value) => onCompanyChange(value)}
                />
              </Col>
              <Col md={12} xs={24}>
                <FormFields
                  name="name"
                  label="Name"
                  placeholder="Name"
                  $style_type="input"
                  rules={validation["name"]}
                />
              </Col>
              <Col md={12} xs={24}>
                <FormFields
                  name="phone"
                  label="Phone Number"
                  placeholder="Phone Number"
                  $style_type="input"
                  rules={validation["phone"]}
                />
              </Col>
              <Col md={12} xs={24}>
                <FormFields
                  name="email"
                  label="Email"
                  placeholder="Email"
                  $style_type="input"
                  rules={validation["email"]}
                />
              </Col>
              <Col md={12} xs={24}>
                <FormFields
                  $style_type="password"
                  name="password"
                  label="Password"
                  placeholder="Enter Password"
                  iconRender={(visible) => (visible ? <FiEye /> : <FiEyeOff />)}
                  type="password"
                  rules={validation["password"]}
                />
              </Col>
              <Col md={12} xs={24}>
                <FormFields
                  name="role"
                  label="Role"
                  placeholder="Choose Role"
                  $style_type="select"
                  labelKey="label"
                  valueKey="role"
                  menuItem={ROLES_LIST}
                  rules={validation["role"]}
                  onChangeSelect={(value) => onRoleChange(value)}
                  disabled={
                    actionType === "Show" ||
                    !["allUsersInSuperAdmin", "users"].includes(currentScreen)
                  }
                />
              </Col>

              {["allUsersInSuperAdmin", "usersInAdmin"].includes(
                currentScreen
              ) &&
                selectedRole === "customer" && (
                  <Col md={12} xs={24}>
                    <FormFields
                      name="parent_id"
                      label="Admin"
                      placeholder="Choose Admin"
                      $style_type="select"
                      menuItem={adminsList}
                      valueKey="id"
                      labelKey="name"
                      rules={validation["parent_id"]}
                      disabled={
                        actionType === "Show" ||
                        currentScreen === "usersInAdmin"
                      }
                      onChangeSelect={(value) => onAdminChange(value)}
                    />
                  </Col>
                )}

              <Col md={12} xs={24}>
                <FormFields
                  name="working_as"
                  label="User Working As"
                  placeholder="Choose User Working As"
                  $style_type="select"
                  menuItem={USER_WORKING_AS_LIST}
                  valueKey="value"
                  labelKey="label"
                  rules={validation["working_as"]}
                  disabled={
                    actionType === "Show" ||
                    (currentScreen === "usersInAdmin" &&
                      !!workingAs &&
                      workingAs !== "both")
                    // actionType === "Show" ||
                    // !["allUsersInSuperAdmin", "adminInSuperAdmin"].includes(
                    //   currentScreen
                    // ) ||
                    // selectedRole === "customer"
                  }
                />
              </Col>
              <Col md={12} xs={24}>
                <FormFields
                  name="locations"
                  label="Select Location"
                  placeholder="Choose Location"
                  $style_type="select"
                  showSearch={true}
                  menuItem={locationList}
                  valueKey="id"
                  labelKey="location_name"
                  rules={validation["locations"]}
                  selectType="multiple"
                  onChangeSelect={(value) => onLocationChange(value)}
                  hasSelectAll={true}
                />
              </Col>
              <Col md={12} xs={24}>
                <FormFields
                  name="sublocations"
                  label="Select Sublocation"
                  placeholder="Choose Sublocation"
                  $style_type="select"
                  showSearch={true}
                  menuItem={subLocationList}
                  valueKey="id"
                  labelKey="location_name"
                  selectType="multiple"
                  rules={validation["sublocations"]}
                  hasSelectAll={true}
                  onChangeSelect={(values) =>
                    form.setFieldValue("sublocations", values)
                  }
                />
              </Col>
            </Row>

            <SwitchField
              label="Moving SP"
              name="enable_sp_controller"
              defaultChecked={[1, "1"].includes(
                usersDetails?.enable_sp_controller
              )}
            />
            <SwitchField
              label="Controller Added?"
              name="enable_controller"
              defaultChecked={[1, "1"].includes(
                usersDetails?.enable_controller
              )}
            />
            <SwitchField
              label="Active"
              name="status"
              defaultChecked={
                !actionType
                  ? true
                  : [1, "1"].includes(usersDetails?.status)
                  ? true
                  : false
              }
              disabled={!actionType || actionType === "Show"}
            />
          </div>
          <div className="modal_footer">
            {actionType !== "Show" && (
              <>
                <StyledButton
                  label="Reset"
                  $style_type="ghost"
                  onClick={handleReset}
                />
                <StyledButton
                  htmlType="submit"
                  label="Submit"
                  disabled={loading}
                />
              </>
            )}
            <StyledButton
              label="Close"
              $style_type="secondary"
              onClick={() => onCancel()}
            />
          </div>
        </Form>
      ) : (
        <ViewUser
          userDetails={usersDetails}
          companyName={companyName}
          adminName={adminName}
        />
      )}
    </Modal>
  );
};

export default AddEditUsers;
