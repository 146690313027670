import { Card, Spin, Table } from "antd";
import classNames from "classnames";
import { useEffect, useState } from "react";
import {
  fetchAllResourcesList,
  userBasedResourceActions,
} from "../../../api/authorizeApi";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import StyledButton from "../../../components/StyledButton/StyledButton";
import { useSnackBar } from "../../../context/SnackBarProvider";
import useApiResponse from "../../../customHooks/useApiResponse";
import { SUPER_ADMIN_SENSORS_COLUMN } from "../../../utils/webTableConfig";
import AddEditSensors from "./AddEditSensors";
import styles from "./Sensors.module.scss";

const Sensors = () => {
  const { apiResponse } = useApiResponse();
  const [actionType, setActionType] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [selectedSensors, setSelectedSensors] = useState<Record<string, any>>(
    {}
  );
  const [sensorsList, setSensorsList] = useState([]);
  const [showAddEditSensor, setShowAddEditSensor] = useState(false);
  const { snackbar } = useSnackBar();

  const getSensorsData = async () => {
    setLoading(true);
    const sensorsData = await fetchAllResourcesList("sensor");
    const response = await apiResponse(sensorsData);
    setSensorsList(response?.result || []);
    setLoading(false);
  };

  const handleAddEditSensor = (title?: string, refresh?: boolean) => {
    if (title) {
      setModalTitle(title);
    }
    setShowAddEditSensor((prev) => !prev);
    if (refresh) {
      getSensorsData();
    }
  };

  const handleTableActions = (type: string, rowData: any) => {
    setSelectedSensors(rowData || {});
    setActionType(type);
    switch (type) {
      case "Edit":
        handleAddEditSensor("Edit Sensor");
        break;
      case "Delete":
        handleDeleteSensor(+rowData.id);
        break;
      default:
        break;
    }
  };

  const handleDeleteSensor = async (id: number) => {
    setLoading(true);
    const response = await apiResponse(
      await userBasedResourceActions("superadmin/sensor/delete", "delete", id)
    );
    snackbar(response?.success ? "success" : "error", response.message);
    if (response?.success) {
      getSensorsData();
    }
    setLoading(false);
  };

  useEffect(() => {
    getSensorsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <Card
        title="Sensors"
        extra={
          <StyledButton
            label="Add Sensor"
            onClick={() => {
              handleAddEditSensor("Create New Sensor");
              setActionType("");
              setSelectedSensors({});
            }}
          />
        }
        className={classNames(commonStyles.card_wrapper, styles.sensors_card)}
      >
        <Spin spinning={loading}>
          <Table
            dataSource={sensorsList}
            columns={SUPER_ADMIN_SENSORS_COLUMN(handleTableActions)}
            pagination={false}
            className={classNames(commonStyles.table_wrap, styles.sensor_table)}
          />
        </Spin>
      </Card>
      {showAddEditSensor && (
        <AddEditSensors
          title={modalTitle}
          type={actionType}
          open={showAddEditSensor}
          sensorDetails={selectedSensors}
          onCancel={(refresh) => handleAddEditSensor("", refresh)}
        />
      )}
    </main>
  );
};
export default Sensors;
