import { Card, Progress } from "antd";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import styles from "./SuperAdminDashboard.module.scss";
import classNames from "classnames";
import { RECENT_ORDERS_COUNTRIES } from "../../../utils/dummyData";

const SuperAdminDashboard = () => {
  return (
    <main>
      <Card
        title="Recent Orders"
        className={classNames(
          commonStyles.card_wrapper,
          styles.recent_orders_card
        )}
      >
        <div className={classNames(commonStyles.p_y_1)}>
          <div className={classNames(commonStyles.p_x_1, styles.map_wrap)}>
            <iframe
              title="Address Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d876.2791551375124!2d77.34621630000002!3d28.53621419999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce670e5cf870b%3A0x5ca92e4f8f8aab19!2sBuilding%20A%2C%20Plot%20No%205%2C%20Sector%20127%2C%20Noida%2C%20Uttar%20Pradesh%20201301!5e0!3m2!1sen!2sin!4v1690398957336!5m2!1sen!2sin"
              style={{
                border: "0",
                width: "100%",
                height: "calc(100vh - 14.54rem)",
              }}
              allowFullScreen={false}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className={commonStyles.p_x_1}>
            {RECENT_ORDERS_COUNTRIES.map((order, index) => (
              <div
                className={classNames(
                  index !== RECENT_ORDERS_COUNTRIES.length - 1 &&
                    commonStyles.m_b_1
                )}
                key={order.key}
              >
                <p
                  className={classNames(
                    commonStyles.display_flex,
                    commonStyles.display_j_spacebetween
                  )}
                >
                  <span
                    className={classNames(
                      commonStyles.display_flex,
                      commonStyles.display_a_center
                    )}
                  >
                    {order.icon}
                    {order.country}
                  </span>
                  <span className="float-end">{order.percent}%</span>
                </p>
                <Progress
                  percent={+order.percent}
                  showInfo={false}
                  className={classNames(commonStyles.recent_order_progress)}
                  strokeColor={order.color}
                />
              </div>
            ))}
          </div>
        </div>
      </Card>
    </main>
  );
};

export default SuperAdminDashboard;
