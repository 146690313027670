import { Col, Row, Table } from "antd";
import commonStyles from "../../../assets/styles/commonStyles.module.scss";
import { CLIENT_LOCATION } from "../../../utils/webTableConfig";

interface ViewCompanyProps {
  companyDetails: Record<string, any>;
}
const ViewCompany: React.FC<ViewCompanyProps> = ({ companyDetails }) => {
  return (
    <div className="modal_form">
      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <h6>Company Name</h6>
          <p>{companyDetails?.name || "-"}</p>
        </Col>
        <Col md={12} xs={24}>
          <h6>Company Email</h6>
          <p>{companyDetails?.email || "-"}</p>
        </Col>
        <Col xs={24}>
          <h6>Company Address</h6>
          <p>{companyDetails?.address || "-"}</p>
        </Col>
        <Col md={12} xs={24}>
          <h6>Company City</h6>
          <p>{companyDetails?.city || "-"}</p>
        </Col>
        <Col md={12} xs={24}>
          <h6>Company State</h6>
          <p>{companyDetails?.state || "-"}</p>
        </Col>
        <Col md={12} xs={24}>
          <h6>Company Country</h6>
          <p>{companyDetails?.country || "-"}</p>
        </Col>
        <Col md={12} xs={24}>
          <h6>Company Pincode</h6>
          <p>{companyDetails?.pincode || "-"}</p>
        </Col>
        <Col md={12} xs={24}>
          <h6>Status</h6>
          <p>
            {[1, "1"].includes(companyDetails?.status) ? "Active" : "In-Active"}
          </p>
        </Col>
        <Col md={12} xs={24}>
          <h6>Add Controller?</h6>
          <p>
            {[1, "1"].includes(companyDetails?.is_controller) ? "Yes" : "No"}
          </p>
        </Col>
        <Col xs={24}>
          <h6>Locations Details</h6>

          <Table
            dataSource={companyDetails?.locations || []}
            columns={CLIENT_LOCATION("company")}
            className={commonStyles.table_wrap}
            pagination={false}
            bordered
          />
        </Col>
      </Row>
    </div>
  );
};

export default ViewCompany;
