import { Rule } from "antd/es/form";
import moment, { unitOfTime } from "moment";

export const arrayOfValuesForKey = (list: Record<string, any>[], key: string) =>
  list.map((item: Record<string, any>) => item?.[key]);

export const arrayToCommaSeperatedString = (
  list?: (string | number)[] | null,
  hasSpaceAfterComma: boolean = true
) =>
  list
    ? list
        .filter((item: string | number) => item)
        .join(hasSpaceAfterComma ? ", " : ",")
    : "";

export const checkIfObjectValuesEmpty = (objectData: Record<string, any>) =>
  Object.values(objectData).every(
    (value) =>
      !value ||
      JSON.stringify(value) === "{}" ||
      (Array.isArray(value) && !value.length)
  );

export const colors = (color: string) => `var(--${color})`;

export const formatDate = (
  date: Date,
  format: string = "MM/DD/YYYY",
  startOf?: unitOfTime.StartOf | null,
  endOf?: unitOfTime.StartOf | null
) => {
  if (startOf) {
    return moment(date).startOf(startOf).format(format);
  } else if (endOf) {
    return moment(date).endOf(endOf).format(format);
  }
  return moment(date).format(format);
};

export const handleFormPreventDefault = (
  e: React.KeyboardEvent<HTMLFormElement>
) => {
  if (e.key === "Enter" || e.key === ",") {
    e.preventDefault();
  }
};

export const iconStylesProps = (data?: {
  color?: string;
  fontSize?: string;
  margin?: string;
  width?: string;
  cursor?: string;
}) => ({
  style: {
    color: data?.color || "inherit",
    fontSize: data?.fontSize || "1rem",
    margin: data?.margin || "0 0.5em 0 0",
    width: data?.width || "auto",
    cursor: data?.cursor || "auto",
  },
});

export const hexToRgbA = (hex: string, alpha = 1) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const validatePassword = async (rule?: Rule, value?: string) => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/; // Minimum eight characters, at least one uppercase letter, one lowercase letter, and one number
  if (value && !passwordRegex.test(value)) {
    throw new Error(
      "Password must be at least 8 characters, and include at least one uppercase letter, one lowercase letter, and one number."
    );
  }
};

export const checkTimeFormat = (inputString: string) => {
  const pattern = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
  return pattern.test(inputString);
};
