import { Card, Table } from "antd";
import classNames from "classnames";
import commonStyles from "../../../../../assets/styles/commonStyles.module.scss";
import { ADMIN_CUSTOMER_SENSORS_COLUMN } from "../../../../../utils/webTableConfig";
import styles from "../../Dashboard.module.scss";

interface SensorsSectionProps {
  sensorsList: Record<string, any>[];
}
const SensorsSection: React.FC<SensorsSectionProps> = ({ sensorsList }) => {
  return (
    <section
      className={classNames(commonStyles.m_b_1_5, styles.sensor_section)}
    >
      <Card
        title="Sensors"
        className={classNames(commonStyles.card_wrapper, styles.sensors_card)}
      >
        <Table
          dataSource={sensorsList}
          columns={ADMIN_CUSTOMER_SENSORS_COLUMN}
          pagination={false}
          className={classNames(commonStyles.table_wrap, styles.sensor_table)}
        />
      </Card>
    </section>
  );
};
export default SensorsSection;
